import _ from "lodash";
import { flow, types, getParent, Instance } from "mobx-state-tree";

import { addressBooks } from "src/apis/wallet/address-books";
import { addressBooks as addressBooksV2 } from "src/apis/v2/wallet/address-books";
import { IBalances } from "src/stores/Balances";
import {
  AddressBookDto,
  AddressBookStatus,
  Blockchain,
} from "src/__generate__/api";
import { dummyCoin } from "src/__mocks__/coin";
import { getRootStore } from "./StoreHelper";

export type WalletAddressType = "address" | "master" | "user";

export type AddressBookItem = AddressBookDto;

const DEFAULT_PAGE_SIZE = 15;

export const EMPTY_SYMBOL = "EMPTY";

const AddressBooks = types
  .model("AddressBooks", {
    data: types.optional(types.map(types.frozen<AddressBookItem>()), {}),
    page: types.optional(types.number, 0),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    totalCount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get addressBookInactiveViews() {
        return Array.from(self.data.values()).filter(
          (item) => item.status === AddressBookStatus.Inactive,
        );
      },
      get addressBookViews() {
        return Array.from(self.data.values()).filter(
          (item) => item.status === AddressBookStatus.Active,
        );
      },
      get addressBookPaginationViews() {
        return _.slice(
          this.addressBookViews,
          self.page * self.size,
          (self.page + 1) * self.size,
        );
      },
      get mapByAddressBookAndCoinId() {
        const byAddressAndWhitelistType: Record<string, AddressBookItem> = {};
        for (const item of this.addressBookViews) {
          byAddressAndWhitelistType[`${item.address}${item.coin.id ?? ""}`] =
            item;
        }
        return byAddressAndWhitelistType;
      },
      get mapByAddressBookAndCoinIdInEveryStatus() {
        const byAddressAndWhitelistType: Record<string, AddressBookItem> = {};
        for (const item of Array.from(self.data.values())) {
          byAddressAndWhitelistType[`${item.address}${item.coin.id ?? ""}`] =
            item;
        }
        return byAddressAndWhitelistType;
      },
      get existsAddressBook() {
        return this.addressBookViews.length !== 0;
      },
      existsInactiveByLabel(name: string) {
        return Boolean(
          this.addressBookInactiveViews.find((item) => item.name === name),
        );
      },
      existsActiveByLabel(name: string) {
        return Boolean(
          this.addressBookViews.find((item) => item.name === name),
        );
      },
      existsInactiveByAddress(address: string, coinId?: string, memo?: string) {
        return this.addressBookInactiveViews.some(
          (item) =>
            item.address === address &&
            item.coin.id === coinId &&
            (memo == null || memo === ""
              ? item.addressBookMemo == null || item.addressBookMemo === ""
              : item.addressBookMemo === memo),
        );
      },

      existsActiveByAddress(address: string, coinId?: string, memo?: string) {
        return this.addressBookViews.some(
          (item) =>
            item.address === address &&
            item.coin.id === coinId &&
            (memo == null || memo === ""
              ? item.addressBookMemo == null || item.addressBookMemo === ""
              : item.addressBookMemo === memo),
        );
      },
    };
  })
  .views((self) => {
    return {
      addressByAddressAndCoinId(address: string, coinId?: string) {
        const allAddress = self.mapByAddressBookAndCoinId[`${address}`];
        if (!allAddress) {
          return self.mapByAddressBookAndCoinId[`${address}${coinId ?? ""}`];
        }
        return allAddress;
      },
      addressByAddressAndCoinIdInEveryStatus(address: string, coinId?: string) {
        const allAddress =
          self.mapByAddressBookAndCoinIdInEveryStatus[`${address}`];
        if (!allAddress) {
          return self.mapByAddressBookAndCoinIdInEveryStatus[
            `${address}${coinId ?? ""}`
          ];
        }
        return allAddress;
      },
    };
  })
  .actions((self) => {
    const clear = () => {
      self.page = 0;
    };

    const fetch = flow(function* () {
      const parent = getParent<{
        id: string;

        balances: IBalances;
        blockchain: Blockchain;
      }>(self);

      let getAddressBooks;
      if (getRootStore(self).blockchains?.checkV2Chain(parent.blockchain)) {
        getAddressBooks = addressBooksV2;
      } else {
        getAddressBooks = addressBooks;
      }
      const response: RetrieveAsyncFunc<typeof getAddressBooks> =
        yield getAddressBooks({ walletId: parent.id });
      const listedCoins =
        getRootStore(self).organizationStore?.coins.coinViews ?? [];

      for (const item of response) {
        const { id, coin } = item;

        self.data.set(id, {
          ...item,
          coin: Boolean(listedCoins.find((ele) => ele.coinId === coin.id))
            ? coin
            : dummyCoin,
        });
      }
      self.totalCount = response.length;
    });

    const setPage = ({ page, size }: { page: number; size?: number }) => {
      self.page = page;
      self.size = size ?? self.size;
    };

    const initialize = flow(function* () {
      clear();
      yield fetch();
    });

    return {
      clear,
      initialize,
      setPage,
      refresh: fetch,
    };
  });

export type IAddressBooks = Instance<typeof AddressBooks>;
export default AddressBooks;
