import React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6946_14701)">
      <mask
        id="mask0_6946_14701"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="2"
        width="14"
        height="20"
      >
        <path d="M18.8193 2H5.06665V21.9237H18.8193V2Z" fill="white" />
      </mask>
      <g mask="url(#mask0_6946_14701)">
        <path
          d="M12.0974 6.98031C12.0974 6.18842 12.7455 5.54327 13.541 5.54327H16.1241V2.047H14.8325H13.541C10.8043 2.047 8.58513 4.25606 8.58513 6.98031C8.58513 7.69119 8.73718 8.36693 9.009 8.97685C6.88353 9.87119 5.47218 11.7715 5.14353 14.1762C4.7596 16.8164 5.82233 19.6644 8.1367 21.0403C10.2023 22.3352 13.4319 22.2496 15.1766 20.4533V21.6458H18.7717V8.41733H13.5425C12.747 8.41733 12.0974 7.77223 12.0974 6.98031ZM15.2564 11.9152V15.2356C15.2564 17.0702 13.7621 18.5561 11.9208 18.5561C10.0794 18.5561 8.58513 17.0686 8.58513 15.2356C8.58513 13.4026 10.0794 11.9152 11.9208 11.9152H15.2564Z"
          fill="black"
          fillOpacity={0.5}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_6946_14701">
        <rect
          width="13.8667"
          height="20"
          fill="white"
          transform="translate(5.06665 2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
