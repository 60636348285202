/* tslint:disable */
/* eslint-disable */
/**
 * Koda Server API
 * Koda Server API 명세
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptInviteRequest
 */
export interface AcceptInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof AcceptInviteRequest
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptInviteRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptInviteRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountStatus}
     * @memberof AccountDto
     */
    status: AccountStatus;
}
/**
 * 
 * @export
 * @interface AccountEmailOnlyDto
 */
export interface AccountEmailOnlyDto {
    /**
     * 
     * @type {string}
     * @memberof AccountEmailOnlyDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountEmailOnlyDto
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface AccountMeDto
 */
export interface AccountMeDto {
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountStatus}
     * @memberof AccountMeDto
     */
    status: AccountStatus;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof AccountMeDto
     */
    roles: Array<RoleDto>;
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AccountMeDto
     */
    updatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof AccountMeDto
     */
    passphraseLastModifiedDayBefore?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountMeDto
     */
    isPassphraseExpired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountMeDto
     */
    passphraseExpiredPeriodDays?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AccountStatus {
    Invited = 'INVITED',
    Active = 'ACTIVE',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface AccountWalletAuthorityDto
 */
export interface AccountWalletAuthorityDto {
    /**
     * 
     * @type {string}
     * @memberof AccountWalletAuthorityDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWalletAuthorityDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWalletAuthorityDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWalletAuthorityDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountStatus}
     * @memberof AccountWalletAuthorityDto
     */
    status: AccountStatus;
    /**
     * 
     * @type {AuthorityName}
     * @memberof AccountWalletAuthorityDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {string}
     * @memberof AccountWalletAuthorityDto
     */
    walletId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Action {
    Create = 'CREATE',
    Delete = 'DELETE'
}

/**
 * 
 * @export
 * @interface ActualOwner
 */
export interface ActualOwner {
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    koreanName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    koreanLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    koreanFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    englishName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    englishLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    englishFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    birthOfDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof ActualOwner
     */
    sex?: string;
}
/**
 * 
 * @export
 * @interface AddAccountRequest
 */
export interface AddAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    accountPassphrase: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AddressBookClientRequestDto
 */
export interface AddressBookClientRequestDto {
    /**
     * 
     * @type {CustodyRequestProcessDto}
     * @memberof AddressBookClientRequestDto
     */
    custodyRequest?: CustodyRequestProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof AddressBookClientRequestDto
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof AddressBookClientRequestDto
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof AddressBookClientRequestDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof AddressBookClientRequestDto
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof AddressBookClientRequestDto
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof AddressBookClientRequestDto
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof AddressBookClientRequestDto
     */
    requestedWallet: BaseWalletDto;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDto
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {DetailedAddressBookRequestDto}
     * @memberof AddressBookClientRequestDto
     */
    detailedAddressBookRequest: DetailedAddressBookRequestDto;
}
/**
 * 
 * @export
 * @interface AddressBookClientRequestDtoV2
 */
export interface AddressBookClientRequestDtoV2 {
    /**
     * 
     * @type {CustodyRequestV2ProcessDto}
     * @memberof AddressBookClientRequestDtoV2
     */
    custodyRequest?: CustodyRequestV2ProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof AddressBookClientRequestDtoV2
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDtoV2
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDtoV2
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof AddressBookClientRequestDtoV2
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof AddressBookClientRequestDtoV2
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof AddressBookClientRequestDtoV2
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof AddressBookClientRequestDtoV2
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof AddressBookClientRequestDtoV2
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof AddressBookClientRequestDtoV2
     */
    requestedWallet: BaseWalletV2Dto;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDtoV2
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDtoV2
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookClientRequestDtoV2
     */
    updatedAt: string;
    /**
     * 
     * @type {DetailedAddressBookRequestDto}
     * @memberof AddressBookClientRequestDtoV2
     */
    detailedAddressBookRequest: DetailedAddressBookRequestDto;
}
/**
 * 
 * @export
 * @interface AddressBookDto
 */
export interface AddressBookDto {
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverKoreanLastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverKoreanFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverEnglishLastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverEnglishFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    receiverContactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    corporationName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    beneficiaryVaspEntityId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    id: string;
    /**
     * 
     * @type {AddressBookStatus}
     * @memberof AddressBookDto
     */
    status: AddressBookStatus;
    /**
     * 
     * @type {CoinDto}
     * @memberof AddressBookDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    addressBookMemo?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBookDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AddressBookStatus {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface AddressInfo
 */
export interface AddressInfo {
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressInfo
     */
    memo?: string;
}
/**
 * 
 * @export
 * @interface AggregatedBaseTransferDto
 */
export interface AggregatedBaseTransferDto {
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    from?: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof AggregatedBaseTransferDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {Blockchain}
     * @memberof AggregatedBaseTransferDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseTransferDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface AggregatedBaseWalletDto
 */
export interface AggregatedBaseWalletDto {
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof AggregatedBaseWalletDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    status: string;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof AggregatedBaseWalletDto
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof AggregatedBaseWalletDto
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedBaseWalletDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface AggregatedCustodyRequestProcessDto
 */
export interface AggregatedCustodyRequestProcessDto {
    /**
     * 
     * @type {string}
     * @memberof AggregatedCustodyRequestProcessDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedCustodyRequestProcessDto
     */
    status: string;
}
/**
 * 
 * @export
 * @interface AggregatedDetailedTransferRequestDto
 */
export interface AggregatedDetailedTransferRequestDto {
    /**
     * 
     * @type {AggregatedBaseTransferDto}
     * @memberof AggregatedDetailedTransferRequestDto
     */
    transfer: AggregatedBaseTransferDto;
    /**
     * 
     * @type {AddressBookDto}
     * @memberof AggregatedDetailedTransferRequestDto
     */
    toAddressBook: AddressBookDto;
}
/**
 * 
 * @export
 * @interface AggregatedDetailedWalletRequestDto
 */
export interface AggregatedDetailedWalletRequestDto {
    /**
     * 
     * @type {AggregatedBaseWalletDto}
     * @memberof AggregatedDetailedWalletRequestDto
     */
    wallet: AggregatedBaseWalletDto;
}
/**
 * 
 * @export
 * @interface AmlStatusDto
 */
export interface AmlStatusDto {
    /**
     * 
     * @type {TransactionPurpose}
     * @memberof AmlStatusDto
     */
    transactionPurpose: TransactionPurpose;
    /**
     * 
     * @type {string}
     * @memberof AmlStatusDto
     */
    transactionPurposeEtc?: string;
    /**
     * 
     * @type {FundsSource}
     * @memberof AmlStatusDto
     */
    fundsSource: FundsSource;
    /**
     * 
     * @type {string}
     * @memberof AmlStatusDto
     */
    fundsSourceEtc?: string;
    /**
     * 
     * @type {Array<CustomerWalletAddress>}
     * @memberof AmlStatusDto
     */
    customerWalletAddresses: Array<CustomerWalletAddress>;
    /**
     * 
     * @type {CddEdd}
     * @memberof AmlStatusDto
     */
    cddEdd: CddEdd;
    /**
     * 
     * @type {WlfPerformanceInfo}
     * @memberof AmlStatusDto
     */
    wlfPerformanceInfo: WlfPerformanceInfo;
    /**
     * 
     * @type {OrganizationManagementStatus}
     * @memberof AmlStatusDto
     */
    status: OrganizationManagementStatus;
}
/**
 * 
 * @export
 * @interface ApprovalRequest
 */
export interface ApprovalRequest {
    /**
     * 
     * @type {string}
     * @memberof ApprovalRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalRequest
     */
    memo?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApprovalType {
    Allow = 'ALLOW',
    Deny = 'DENY'
}

/**
 * 
 * @export
 * @interface ApproveTransferRequest
 */
export interface ApproveTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    historicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    tradePrice: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    tradeCurrency: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    isExceedingThreshold: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    originatingVasp?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferRequest
     */
    payload: string;
}
/**
 * 
 * @export
 * @interface ApproveTransferResponse
 */
export interface ApproveTransferResponse {
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferResponse
     */
    result: ApproveTransferResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferResponse
     */
    reasonType?: ApproveTransferResponseReasonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferResponse
     */
    reasonMsg?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferResponse
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferResponse
     */
    beneficiaryVasp?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveTransferResponse
     */
    payload: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApproveTransferResponseResultEnum {
    Valid = 'valid',
    Invalid = 'invalid',
    Verified = 'verified',
    Denied = 'denied',
    Normal = 'normal',
    Error = 'error'
}
/**
    * @export
    * @enum {string}
    */
export enum ApproveTransferResponseReasonTypeEnum {
    NotFoundAddress = 'NOT_FOUND_ADDRESS',
    NotSupportedSymbol = 'NOT_SUPPORTED_SYMBOL',
    NotKycUser = 'NOT_KYC_USER',
    SanctionList = 'SANCTION_LIST',
    InputNameMismatched = 'INPUT_NAME_MISMATCHED',
    LackOfInformation = 'LACK_OF_INFORMATION',
    TxidAlreadyExists = 'TXID_ALREADY_EXISTS',
    TransferAlreadyFailed = 'TRANSFER_ALREADY_FAILED',
    UnknownTransferId = 'UNKNOWN_TRANSFER_ID',
    IllegalStateflow = 'ILLEGAL_STATEFLOW',
    NotFoundTxid = 'NOT_FOUND_TXID',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface AuthenticateRequest
 */
export interface AuthenticateRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    passphrase: string;
}
/**
 * 
 * @export
 * @interface AuthorityDto
 */
export interface AuthorityDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorityDto
     */
    id: string;
    /**
     * 
     * @type {AuthorityName}
     * @memberof AuthorityDto
     */
    name: AuthorityName;
    /**
     * 
     * @type {ResourceType}
     * @memberof AuthorityDto
     */
    resourceType: ResourceType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthorityName {
    OrganizationSuperAdmin = 'ORGANIZATION_SUPER_ADMIN',
    OrganizationUsersAdmin = 'ORGANIZATION_USERS_ADMIN',
    OrganizationWalletsAdmin = 'ORGANIZATION_WALLETS_ADMIN',
    OrganizationUser = 'ORGANIZATION_USER',
    WalletOwner = 'WALLET_OWNER',
    WalletManager = 'WALLET_MANAGER',
    WalletMember = 'WALLET_MEMBER',
    WalletViewer = 'WALLET_VIEWER',
    CustodyAdmin = 'CUSTODY_ADMIN',
    CustodyManager = 'CUSTODY_MANAGER',
    CustodySigner = 'CUSTODY_SIGNER',
    CustodyViewer = 'CUSTODY_VIEWER',
    CustodyAccountEmailViewer = 'CUSTODY_ACCOUNT_EMAIL_VIEWER',
    CustodyOperator = 'CUSTODY_OPERATOR'
}

/**
 * 
 * @export
 * @interface BalanceDto
 */
export interface BalanceDto {
    /**
     * 
     * @type {CoinDto}
     * @memberof BalanceDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof BalanceDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceDto
     */
    processingAmount: string;
    /**
     * 
     * @type {string}
     * @memberof BalanceDto
     */
    withdrawableAmount: string;
}
/**
 * 
 * @export
 * @interface BaseTransferDto
 */
export interface BaseTransferDto {
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    orgId: string;
    /**
     * 
     * @type {TransferStatus}
     * @memberof BaseTransferDto
     */
    status: TransferStatus;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    from?: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof BaseTransferDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {Blockchain}
     * @memberof BaseTransferDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    amount: string;
    /**
     * 
     * @type {TransferType}
     * @memberof BaseTransferDto
     */
    type: TransferType;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface BaseTransferV2Dto
 */
export interface BaseTransferV2Dto {
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    orgId: string;
    /**
     * 
     * @type {TransferV2Status}
     * @memberof BaseTransferV2Dto
     */
    status: TransferV2Status;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    from?: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof BaseTransferV2Dto
     */
    coin: CoinDto;
    /**
     * 
     * @type {Blockchain}
     * @memberof BaseTransferV2Dto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    amount: string;
    /**
     * 
     * @type {TransferType}
     * @memberof BaseTransferV2Dto
     */
    type: TransferType;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BaseTransferV2Dto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface BaseWalletDto
 */
export interface BaseWalletDto {
    /**
     * 
     * @type {string}
     * @memberof BaseWalletDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletDto
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof BaseWalletDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletDto
     */
    address?: string;
    /**
     * 
     * @type {WalletStatus}
     * @memberof BaseWalletDto
     */
    status: WalletStatus;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof BaseWalletDto
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWalletDto
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface BaseWalletV2Dto
 */
export interface BaseWalletV2Dto {
    /**
     * 
     * @type {string}
     * @memberof BaseWalletV2Dto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletV2Dto
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof BaseWalletV2Dto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletV2Dto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletV2Dto
     */
    address?: string;
    /**
     * 
     * @type {WalletStatus}
     * @memberof BaseWalletV2Dto
     */
    status: WalletStatus;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof BaseWalletV2Dto
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWalletV2Dto
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletV2Dto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWalletV2Dto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Blockchain {
    Ethereum = 'ETHEREUM',
    Klaytn = 'KLAYTN',
    Bitcoin = 'BITCOIN',
    Polygon = 'POLYGON',
    Amoy = 'AMOY',
    OverTestnet = 'OVER_TESTNET',
    Over = 'OVER',
    Bsc = 'BSC',
    WemixTestnet = 'WEMIX_TESTNET',
    XplaTestnet = 'XPLA_TESTNET',
    Xpla = 'XPLA',
    PolygonV2 = 'POLYGON_V2',
    Baobab = 'BAOBAB',
    KlaytnV2 = 'KLAYTN_V2',
    EthereumV2 = 'ETHEREUM_V2',
    Bizauto = 'BIZAUTO',
    BitcoinTestnetV2 = 'BITCOIN_TESTNET_V2',
    BitcoinV2 = 'BITCOIN_V2',
    ArbitrumOne = 'ARBITRUM_ONE'
}

/**
 * 
 * @export
 * @interface BusinessPlace
 */
export interface BusinessPlace {
    /**
     * 
     * @type {string}
     * @memberof BusinessPlace
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPlace
     */
    residentCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPlace
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPlace
     */
    faxNumber?: string;
}
/**
 * 
 * @export
 * @interface CancelTransferRequest
 */
export interface CancelTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof CancelTransferRequest
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof CancelTransferRequest
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof CancelTransferRequest
     */
    reasonType?: string;
}
/**
 * 
 * @export
 * @interface CancelTransferResponse
 */
export interface CancelTransferResponse {
    /**
     * 
     * @type {string}
     * @memberof CancelTransferResponse
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof CancelTransferResponse
     */
    result: CancelTransferResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof CancelTransferResponse
     */
    reasonType?: CancelTransferResponseReasonTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CancelTransferResponseResultEnum {
    Valid = 'valid',
    Invalid = 'invalid',
    Verified = 'verified',
    Denied = 'denied',
    Normal = 'normal',
    Error = 'error'
}
/**
    * @export
    * @enum {string}
    */
export enum CancelTransferResponseReasonTypeEnum {
    NotFoundAddress = 'NOT_FOUND_ADDRESS',
    NotSupportedSymbol = 'NOT_SUPPORTED_SYMBOL',
    NotKycUser = 'NOT_KYC_USER',
    SanctionList = 'SANCTION_LIST',
    InputNameMismatched = 'INPUT_NAME_MISMATCHED',
    LackOfInformation = 'LACK_OF_INFORMATION',
    TxidAlreadyExists = 'TXID_ALREADY_EXISTS',
    TransferAlreadyFailed = 'TRANSFER_ALREADY_FAILED',
    UnknownTransferId = 'UNKNOWN_TRANSFER_ID',
    IllegalStateflow = 'ILLEGAL_STATEFLOW',
    NotFoundTxid = 'NOT_FOUND_TXID',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface CddEdd
 */
export interface CddEdd {
    /**
     * 
     * @type {string}
     * @memberof CddEdd
     */
    initialPerformanceDate: string;
    /**
     * 
     * @type {RiskAssessment}
     * @memberof CddEdd
     */
    riskAssessment: RiskAssessment;
    /**
     * 
     * @type {string}
     * @memberof CddEdd
     */
    finalPerformanceDate: string;
    /**
     * 
     * @type {string}
     * @memberof CddEdd
     */
    rePerformanceDate: string;
}
/**
 * 
 * @export
 * @interface ChangeAccountNameRequest
 */
export interface ChangeAccountNameRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeAccountNameRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ChangeOrganizationAccountRolesRequest
 */
export interface ChangeOrganizationAccountRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeOrganizationAccountRolesRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeOrganizationAccountRolesRequest
     */
    passphrase: string;
    /**
     * 
     * @type {Array<AuthorityName>}
     * @memberof ChangeOrganizationAccountRolesRequest
     */
    deletedAuthorityNames: Array<AuthorityName>;
    /**
     * 
     * @type {Array<AuthorityName>}
     * @memberof ChangeOrganizationAccountRolesRequest
     */
    addedAuthorityNames: Array<AuthorityName>;
}
/**
 * 
 * @export
 * @interface ChangeOrganizationNameRequest
 */
export interface ChangeOrganizationNameRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeOrganizationNameRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeOrganizationNameRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeOrganizationNameRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ChangePassphraseRequest
 */
export interface ChangePassphraseRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePassphraseRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassphraseRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassphraseRequest
     */
    newPassphrase: string;
}
/**
 * 
 * @export
 * @interface ChangeWalletAccountRolesRequest
 */
export interface ChangeWalletAccountRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeWalletAccountRolesRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeWalletAccountRolesRequest
     */
    passphrase: string;
    /**
     * 
     * @type {AuthorityName}
     * @memberof ChangeWalletAccountRolesRequest
     */
    authorityName: AuthorityName;
}
/**
 * 
 * @export
 * @interface CheckAddressIsExistsRequest
 */
export interface CheckAddressIsExistsRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckAddressIsExistsRequest
     */
    address: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof CheckAddressIsExistsRequest
     */
    blockchain: Blockchain;
}
/**
 * 
 * @export
 * @interface ClientApprovalDto
 */
export interface ClientApprovalDto {
    /**
     * 
     * @type {AccountDto}
     * @memberof ClientApprovalDto
     */
    account: AccountDto;
    /**
     * 
     * @type {ApprovalType}
     * @memberof ClientApprovalDto
     */
    type: ApprovalType;
    /**
     * 
     * @type {string}
     * @memberof ClientApprovalDto
     */
    memo?: string;
    /**
     * 
     * @type {AuthorityName}
     * @memberof ClientApprovalDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {string}
     * @memberof ClientApprovalDto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface ClientContact
 */
export interface ClientContact {
    /**
     * 
     * @type {string}
     * @memberof ClientContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientContact
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientContact
     */
    note?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientRequestApprovalPolicy {
    OrganizationWalletsAdminApproval = 'ORGANIZATION_WALLETS_ADMIN_APPROVAL',
    WalletOwnerApproval = 'WALLET_OWNER_APPROVAL',
    WalletManagerApproval = 'WALLET_MANAGER_APPROVAL'
}

/**
 * 
 * @export
 * @interface ClientRequestBoxDto
 */
export interface ClientRequestBoxDto {
    /**
     * 
     * @type {AggregatedCustodyRequestProcessDto}
     * @memberof ClientRequestBoxDto
     */
    custodyRequest?: AggregatedCustodyRequestProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof ClientRequestBoxDto
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof ClientRequestBoxDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRequestBoxDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof ClientRequestBoxDto
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof ClientRequestBoxDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof ClientRequestBoxDto
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof ClientRequestBoxDto
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof ClientRequestBoxDto
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {AggregatedBaseWalletDto}
     * @memberof ClientRequestBoxDto
     */
    requestedWallet: AggregatedBaseWalletDto;
    /**
     * 
     * @type {string}
     * @memberof ClientRequestBoxDto
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRequestBoxDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ClientRequestBoxDto
     */
    updatedAt: string;
    /**
     * 
     * @type {AggregatedDetailedTransferRequestDto}
     * @memberof ClientRequestBoxDto
     */
    detailedTransferRequest?: AggregatedDetailedTransferRequestDto;
    /**
     * 
     * @type {DetailedAddressBookRequestDto}
     * @memberof ClientRequestBoxDto
     */
    detailedAddressBookRequest?: DetailedAddressBookRequestDto;
}
/**
 * 
 * @export
 * @interface ClientRequestProcessDto
 */
export interface ClientRequestProcessDto {
    /**
     * 
     * @type {string}
     * @memberof ClientRequestProcessDto
     */
    id: string;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof ClientRequestProcessDto
     */
    status: ClientRequestStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientRequestStatus {
    PendingApproval = 'PENDING_APPROVAL',
    Deleted = 'DELETED',
    Rejected = 'REJECTED',
    Approved = 'APPROVED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientRequestType {
    Transfer = 'TRANSFER',
    Wallet = 'WALLET',
    AddressBook = 'ADDRESS_BOOK'
}

/**
 * 
 * @export
 * @interface ClientSubmittedRequestDto
 */
export interface ClientSubmittedRequestDto {
    /**
     * 
     * @type {AggregatedCustodyRequestProcessDto}
     * @memberof ClientSubmittedRequestDto
     */
    custodyRequest?: AggregatedCustodyRequestProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof ClientSubmittedRequestDto
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof ClientSubmittedRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSubmittedRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof ClientSubmittedRequestDto
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof ClientSubmittedRequestDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof ClientSubmittedRequestDto
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof ClientSubmittedRequestDto
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof ClientSubmittedRequestDto
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {AggregatedBaseWalletDto}
     * @memberof ClientSubmittedRequestDto
     */
    requestedWallet: AggregatedBaseWalletDto;
    /**
     * 
     * @type {string}
     * @memberof ClientSubmittedRequestDto
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSubmittedRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSubmittedRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {AggregatedDetailedTransferRequestDto}
     * @memberof ClientSubmittedRequestDto
     */
    detailedTransferRequest?: AggregatedDetailedTransferRequestDto;
    /**
     * 
     * @type {AggregatedDetailedWalletRequestDto}
     * @memberof ClientSubmittedRequestDto
     */
    detailedWalletRequestDto?: AggregatedDetailedWalletRequestDto;
}
/**
 * 
 * @export
 * @interface CoinDto
 */
export interface CoinDto {
    /**
     * 
     * @type {string}
     * @memberof CoinDto
     */
    id: string;
    /**
     * 
     * @type {CoinType}
     * @memberof CoinDto
     */
    type: CoinType;
    /**
     * 
     * @type {string}
     * @memberof CoinDto
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof CoinDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CoinDto
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof CoinDto
     */
    decimal: number;
    /**
     * 
     * @type {Blockchain}
     * @memberof CoinDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof CoinDto
     */
    registeredAt?: string;
}
/**
 * 
 * @export
 * @interface CoinListingDTO
 */
export interface CoinListingDTO {
    /**
     * 
     * @type {string}
     * @memberof CoinListingDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CoinListingDTO
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof CoinListingDTO
     */
    requesterId: string;
    /**
     * 
     * @type {string}
     * @memberof CoinListingDTO
     */
    coinId: string;
    /**
     * 
     * @type {string}
     * @memberof CoinListingDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CoinListingDTO
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface CoinRegistrationRequestDto
 */
export interface CoinRegistrationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CoinRegistrationRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyNonSigningRequestType}
     * @memberof CoinRegistrationRequestDto
     */
    type: CustodyNonSigningRequestType;
    /**
     * 
     * @type {CustodyNonSigningRequestStatus}
     * @memberof CoinRegistrationRequestDto
     */
    status: CustodyNonSigningRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof CoinRegistrationRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CoinRegistrationRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof CoinRegistrationRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {CoinDto}
     * @memberof CoinRegistrationRequestDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {AccountDto}
     * @memberof CoinRegistrationRequestDto
     */
    requester: AccountDto;
    /**
     * 
     * @type {string}
     * @memberof CoinRegistrationRequestDto
     */
    coinId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CoinType {
    Platform = 'PLATFORM',
    Token = 'TOKEN'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CompanyType {
    GovernmentOrLocalAuthority = 'GOVERNMENT_OR_LOCAL_AUTHORITY',
    PublicOrganization = 'PUBLIC_ORGANIZATION',
    LargeCorporation = 'LARGE_CORPORATION',
    SmallMediumEnterprise = 'SMALL_MEDIUM_ENTERPRISE',
    NonprofitOrganization = 'NONPROFIT_ORGANIZATION',
    Etc = 'ETC'
}

/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    englishName?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    koreanName?: string;
}
/**
 * 
 * @export
 * @interface CreateAddressBookCreationRequest
 */
export interface CreateAddressBookCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    coinId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    receiverAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    receiverContactNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     * @deprecated
     */
    receiverName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    receiverKoreanLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    receiverKoreanFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    receiverEnglishLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    receiverEnglishFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    corporationName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    beneficiaryVaspEntityId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookCreationRequest
     */
    addressBookMemo?: string;
}
/**
 * 
 * @export
 * @interface CreateAddressBookDeletionRequest
 */
export interface CreateAddressBookDeletionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookDeletionRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookDeletionRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookDeletionRequest
     */
    addressBookId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookDeletionRequest
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressBookDeletionRequest
     */
    memo?: string;
}
/**
 * 
 * @export
 * @interface CreateCoinRegristrationRequest
 */
export interface CreateCoinRegristrationRequest {
    /**
     * 
     * @type {Blockchain}
     * @memberof CreateCoinRegristrationRequest
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof CreateCoinRegristrationRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCoinRegristrationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCoinRegristrationRequest
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCoinRegristrationRequest
     */
    decimal: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCoinRegristrationRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCoinRegristrationRequest
     */
    passphrase: string;
}
/**
 * 
 * @export
 * @interface CreateOrganizationInviteRequest
 */
export interface CreateOrganizationInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInviteRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInviteRequest
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInviteRequest
     */
    superAdminName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInviteRequest
     */
    superAdminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationInviteRequest
     */
    superAdminContactNumber: string;
}
/**
 * 
 * @export
 * @interface CreateTransferRequest
 */
export interface CreateTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    addressBookId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    coinId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransferRequest
     */
    walletId: string;
}
/**
 * 
 * @export
 * @interface CreateWalletCreationRequest
 */
export interface CreateWalletCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWalletCreationRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletCreationRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletCreationRequest
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof CreateWalletCreationRequest
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletCreationRequest
     */
    accountName?: string;
}
/**
 * 
 * @export
 * @interface CustodyApprovalDto
 */
export interface CustodyApprovalDto {
    /**
     * 
     * @type {AccountDto}
     * @memberof CustodyApprovalDto
     */
    account: AccountDto;
    /**
     * 
     * @type {ApprovalType}
     * @memberof CustodyApprovalDto
     */
    type: ApprovalType;
    /**
     * 
     * @type {string}
     * @memberof CustodyApprovalDto
     */
    memo?: string;
    /**
     * 
     * @type {AuthorityName}
     * @memberof CustodyApprovalDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {string}
     * @memberof CustodyApprovalDto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface CustodyApprovalV2Dto
 */
export interface CustodyApprovalV2Dto {
    /**
     * 
     * @type {AccountDto}
     * @memberof CustodyApprovalV2Dto
     */
    account: AccountDto;
    /**
     * 
     * @type {ApprovalType}
     * @memberof CustodyApprovalV2Dto
     */
    type: ApprovalType;
    /**
     * 
     * @type {string}
     * @memberof CustodyApprovalV2Dto
     */
    memo?: string;
    /**
     * 
     * @type {AuthorityName}
     * @memberof CustodyApprovalV2Dto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {string}
     * @memberof CustodyApprovalV2Dto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface CustodyFeeDto
 */
export interface CustodyFeeDto {
    /**
     * 
     * @type {string}
     * @memberof CustodyFeeDto
     */
    setupCostKrw?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodyFeeDto
     */
    setupCostUsd?: string;
    /**
     * 
     * @type {CustodyFeeType}
     * @memberof CustodyFeeDto
     */
    custodyFeeType: CustodyFeeType;
    /**
     * 
     * @type {string}
     * @memberof CustodyFeeDto
     */
    custodyFeeKRW?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodyFeeDto
     */
    custodyFeeUSD?: string;
    /**
     * 
     * @type {string}
     * @memberof CustodyFeeDto
     */
    custodyFeePercentage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustodyFeeDto
     */
    taxInvoiceIssuance: boolean;
    /**
     * 
     * @type {Array<ClientContact>}
     * @memberof CustodyFeeDto
     */
    clientContacts?: Array<ClientContact>;
    /**
     * 
     * @type {OrganizationManagementStatus}
     * @memberof CustodyFeeDto
     */
    status: OrganizationManagementStatus;
}
/**
 * 
 * @export
 * @interface CustodyFeeType
 */
export interface CustodyFeeType {
    /**
     * 
     * @type {MonthlyQuarterly}
     * @memberof CustodyFeeType
     */
    monthlyQuarterly: MonthlyQuarterly;
    /**
     * 
     * @type {FixedPercentage}
     * @memberof CustodyFeeType
     */
    fixedPercentage: FixedPercentage;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustodyNonSigningRequestStatus {
    Canceled = 'CANCELED',
    PendingApproval = 'PENDING_APPROVAL',
    PendingFinalApproval = 'PENDING_FINAL_APPROVAL',
    Declined = 'DECLINED',
    Processed = 'PROCESSED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustodyNonSigningRequestType {
    OrganizationManagement = 'ORGANIZATION_MANAGEMENT',
    CoinRegistration = 'COIN_REGISTRATION'
}

/**
 * 
 * @export
 * @interface CustodyRequestProcessDto
 */
export interface CustodyRequestProcessDto {
    /**
     * 
     * @type {string}
     * @memberof CustodyRequestProcessDto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestStatus}
     * @memberof CustodyRequestProcessDto
     */
    status: CustodyRequestStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustodyRequestStatus {
    Canceled = 'CANCELED',
    Requested = 'REQUESTED',
    PendingSigning = 'PENDING_SIGNING',
    PendingApproval = 'PENDING_APPROVAL',
    PendingFinalApproval = 'PENDING_FINAL_APPROVAL',
    Declined = 'DECLINED',
    Processed = 'PROCESSED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustodyRequestType {
    Withdrawal = 'WITHDRAWAL',
    Wallet = 'WALLET'
}

/**
 * 
 * @export
 * @interface CustodyRequestV2ProcessDto
 */
export interface CustodyRequestV2ProcessDto {
    /**
     * 
     * @type {string}
     * @memberof CustodyRequestV2ProcessDto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestV2Status}
     * @memberof CustodyRequestV2ProcessDto
     */
    status: CustodyRequestV2Status;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustodyRequestV2Status {
    Canceled = 'CANCELED',
    Requested = 'REQUESTED',
    PendingSigning = 'PENDING_SIGNING',
    PendingApproval = 'PENDING_APPROVAL',
    PendingFinalApproval = 'PENDING_FINAL_APPROVAL',
    Declined = 'DECLINED',
    Processed = 'PROCESSED'
}

/**
 * 
 * @export
 * @interface CustomerWalletAddress
 */
export interface CustomerWalletAddress {
    /**
     * 
     * @type {string}
     * @memberof CustomerWalletAddress
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerWalletAddress
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface DeleteCoinListingRequest
 */
export interface DeleteCoinListingRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCoinListingRequest
     */
    otpCode: string;
}
/**
 * 
 * @export
 * @interface DeleteOrganizationRequest
 */
export interface DeleteOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteOrganizationRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteOrganizationRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteOrganizationRequest
     */
    orgId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Detail {
    RegisterOrganization = 'REGISTER_ORGANIZATION',
    UpdateDetailInformation = 'UPDATE_DETAIL_INFORMATION',
    UpdateCustodyFee = 'UPDATE_CUSTODY_FEE',
    UpdateAmlStatus = 'UPDATE_AML_STATUS',
    DeleteOrganization = 'DELETE_ORGANIZATION'
}

/**
 * 
 * @export
 * @interface DetailDto
 */
export interface DetailDto {
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    orgName?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    englishOrgName?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailDto
     */
    orgCode?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    taxIdentificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    corporationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    establishmentPurpose?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    foundationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    homepage?: string;
    /**
     * 
     * @type {CompanyType}
     * @memberof DetailDto
     */
    companyType: CompanyType;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    companyTypeEtc?: string;
    /**
     * 
     * @type {ListedType}
     * @memberof DetailDto
     */
    listedType: ListedType;
    /**
     * 
     * @type {IndustryType}
     * @memberof DetailDto
     */
    industryType: IndustryType;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    industryTypeEtc?: string;
    /**
     * 
     * @type {Headquarter}
     * @memberof DetailDto
     */
    headquarter?: Headquarter;
    /**
     * 
     * @type {BusinessPlace}
     * @memberof DetailDto
     */
    businessPlace?: BusinessPlace;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDto
     */
    contactableEmail?: string;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof DetailDto
     */
    representatives?: Array<Representative>;
    /**
     * 
     * @type {Array<ActualOwner>}
     * @memberof DetailDto
     */
    actualOwners?: Array<ActualOwner>;
    /**
     * 
     * @type {OrganizationManagementStatus}
     * @memberof DetailDto
     */
    status: OrganizationManagementStatus;
}
/**
 * 
 * @export
 * @interface DetailOrganizationInviteProjection
 */
export interface DetailOrganizationInviteProjection {
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    organizationAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    organizationContactNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    superAdminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    superAdminName: string;
    /**
     * 
     * @type {SimpleAccountInfo}
     * @memberof DetailOrganizationInviteProjection
     */
    requester: SimpleAccountInfo;
    /**
     * 
     * @type {SimpleAccountInfo}
     * @memberof DetailOrganizationInviteProjection
     */
    approver?: SimpleAccountInfo;
    /**
     * 
     * @type {SimpleAccountInfo}
     * @memberof DetailOrganizationInviteProjection
     */
    canceler?: SimpleAccountInfo;
    /**
     * 
     * @type {SimpleAccountInfo}
     * @memberof DetailOrganizationInviteProjection
     */
    rejecter?: SimpleAccountInfo;
    /**
     * 
     * @type {OrganizationInvitationStatus}
     * @memberof DetailOrganizationInviteProjection
     */
    status: OrganizationInvitationStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailOrganizationInviteProjection
     */
    processedAt?: string;
}
/**
 * 
 * @export
 * @interface DetailedAddressBookRequestDto
 */
export interface DetailedAddressBookRequestDto {
    /**
     * 
     * @type {Action}
     * @memberof DetailedAddressBookRequestDto
     */
    action: Action;
    /**
     * 
     * @type {AddressBookDto}
     * @memberof DetailedAddressBookRequestDto
     */
    addressBook: AddressBookDto;
}
/**
 * 
 * @export
 * @interface DetailedOrganizationManagementCustodyRequestDto
 */
export interface DetailedOrganizationManagementCustodyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyNonSigningRequestType}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    type: CustodyNonSigningRequestType;
    /**
     * 
     * @type {CustodyNonSigningRequestStatus}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    status: CustodyNonSigningRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {OrganizationManagementUpdateDetail}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    organizationManagement: OrganizationManagementUpdateDetail;
    /**
     * 
     * @type {AccountDto}
     * @memberof DetailedOrganizationManagementCustodyRequestDto
     */
    requester: AccountDto;
}
/**
 * 
 * @export
 * @interface DetailedTransferRequestDto
 */
export interface DetailedTransferRequestDto {
    /**
     * 
     * @type {BaseTransferDto}
     * @memberof DetailedTransferRequestDto
     */
    transfer: BaseTransferDto;
    /**
     * 
     * @type {AddressBookDto}
     * @memberof DetailedTransferRequestDto
     */
    toAddressBook: AddressBookDto;
}
/**
 * 
 * @export
 * @interface DetailedTransferV2RequestDto
 */
export interface DetailedTransferV2RequestDto {
    /**
     * 
     * @type {BaseTransferV2Dto}
     * @memberof DetailedTransferV2RequestDto
     */
    transfer: BaseTransferV2Dto;
    /**
     * 
     * @type {AddressBookDto}
     * @memberof DetailedTransferV2RequestDto
     */
    toAddressBook: AddressBookDto;
}
/**
 * 
 * @export
 * @interface DetailedWalletCustodyRequestDto
 */
export interface DetailedWalletCustodyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DetailedWalletCustodyRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof DetailedWalletCustodyRequestDto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestStatus}
     * @memberof DetailedWalletCustodyRequestDto
     */
    status: CustodyRequestStatus;
    /**
     * 
     * @type {SigningDto}
     * @memberof DetailedWalletCustodyRequestDto
     */
    signing: SigningDto;
    /**
     * 
     * @type {string}
     * @memberof DetailedWalletCustodyRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedWalletCustodyRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof DetailedWalletCustodyRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof DetailedWalletCustodyRequestDto
     */
    wallet: BaseWalletDto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof DetailedWalletCustodyRequestDto
     */
    organization: OrganizationDto;
}
/**
 * 
 * @export
 * @interface DetailedWalletCustodyRequestV2Dto
 */
export interface DetailedWalletCustodyRequestV2Dto {
    /**
     * 
     * @type {string}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestV2Status}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    status: CustodyRequestV2Status;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    commitSigning: SigningV2Dto;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    confirmSigning: SigningV2Dto;
    /**
     * 
     * @type {string}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalV2Dto>}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    approvals: Array<CustodyApprovalV2Dto>;
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    wallet: BaseWalletV2Dto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof DetailedWalletCustodyRequestV2Dto
     */
    organization: OrganizationDto;
}
/**
 * 
 * @export
 * @interface DetailedWalletRequestDto
 */
export interface DetailedWalletRequestDto {
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof DetailedWalletRequestDto
     */
    wallet: BaseWalletDto;
}
/**
 * 
 * @export
 * @interface DetailedWalletRequestDtoV2
 */
export interface DetailedWalletRequestDtoV2 {
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof DetailedWalletRequestDtoV2
     */
    wallet: BaseWalletV2Dto;
}
/**
 * 
 * @export
 * @interface DetailedWithdrawalCustodyRequestDto
 */
export interface DetailedWithdrawalCustodyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestStatus}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    status: CustodyRequestStatus;
    /**
     * 
     * @type {SigningDto}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    signing: SigningDto;
    /**
     * 
     * @type {string}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {WithdrawalDto}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    withdrawal: WithdrawalDto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    organization: OrganizationDto;
    /**
     * 
     * @type {AddressBookDto}
     * @memberof DetailedWithdrawalCustodyRequestDto
     */
    toAddressBook?: AddressBookDto;
}
/**
 * 
 * @export
 * @interface DetailedWithdrawalCustodyRequestV2Dto
 */
export interface DetailedWithdrawalCustodyRequestV2Dto {
    /**
     * 
     * @type {string}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestV2Status}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    status: CustodyRequestV2Status;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    commitSigning: SigningV2Dto;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    confirmSigning: SigningV2Dto;
    /**
     * 
     * @type {string}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalV2Dto>}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    approvals: Array<CustodyApprovalV2Dto>;
    /**
     * 
     * @type {WithdrawalV2Dto}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    withdrawal: WithdrawalV2Dto;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    organization: OrganizationDto;
    /**
     * 
     * @type {AddressBookDto}
     * @memberof DetailedWithdrawalCustodyRequestV2Dto
     */
    toAddressBook?: AddressBookDto;
}
/**
 * 
 * @export
 * @interface EditableOrganizationDetail
 */
export interface EditableOrganizationDetail {
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    englishOrgName: string;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    establishmentPurpose?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    foundationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    homepage?: string;
    /**
     * 
     * @type {CompanyType}
     * @memberof EditableOrganizationDetail
     */
    companyType: CompanyType;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    companyTypeEtc?: string;
    /**
     * 
     * @type {ListedType}
     * @memberof EditableOrganizationDetail
     */
    listedType: ListedType;
    /**
     * 
     * @type {IndustryType}
     * @memberof EditableOrganizationDetail
     */
    industryType: IndustryType;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    industryTypeEtc?: string;
    /**
     * 
     * @type {Headquarter}
     * @memberof EditableOrganizationDetail
     */
    headquarter?: Headquarter;
    /**
     * 
     * @type {BusinessPlace}
     * @memberof EditableOrganizationDetail
     */
    businessPlace?: BusinessPlace;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EditableOrganizationDetail
     */
    contactableEmail?: string;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof EditableOrganizationDetail
     */
    representatives?: Array<Representative>;
    /**
     * 
     * @type {Array<ActualOwner>}
     * @memberof EditableOrganizationDetail
     */
    actualOwners?: Array<ActualOwner>;
}
/**
 * 
 * @export
 * @interface EmailTestEnvelopDto
 */
export interface EmailTestEnvelopDto {
    /**
     * 
     * @type {string}
     * @memberof EmailTestEnvelopDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTestEnvelopDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTestEnvelopDto
     */
    body?: string;
}
/**
 * 
 * @export
 * @interface ErrorBody
 */
export interface ErrorBody {
    /**
     * 
     * @type {string}
     * @memberof ErrorBody
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorBody
     */
    code?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ErrorBody
     */
    data?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {ErrorBody}
     * @memberof ErrorResponse
     */
    error?: ErrorBody;
}
/**
 * 
 * @export
 * @interface EstimatedFeeDto
 */
export interface EstimatedFeeDto {
    /**
     * 
     * @type {string}
     * @memberof EstimatedFeeDto
     */
    estimatedFee: string;
}
/**
 * 
 * @export
 * @interface FilterHistoryResponse
 */
export interface FilterHistoryResponse {
    /**
     * 
     * @type {Array<WLFHistory>}
     * @memberof FilterHistoryResponse
     */
    result: Array<WLFHistory>;
    /**
     * 
     * @type {number}
     * @memberof FilterHistoryResponse
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FixedPercentage {
    Fixed = 'FIXED',
    Rate = 'RATE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FundsSource {
    BusinessIncome = 'BUSINESS_INCOME',
    OwnCapital = 'OWN_CAPITAL',
    RealEstateIncome = 'REAL_ESTATE_INCOME',
    FinancialIncome = 'FINANCIAL_INCOME',
    Etc = 'ETC'
}

/**
 * 
 * @export
 * @interface GetAdminActiveWalletResponse
 */
export interface GetAdminActiveWalletResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof GetAdminActiveWalletResponse
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    address?: string;
    /**
     * 
     * @type {WalletStatus}
     * @memberof GetAdminActiveWalletResponse
     */
    status: WalletStatus;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof GetAdminActiveWalletResponse
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetAdminActiveWalletResponse
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletResponse
     */
    finalApprovedAt: string;
}
/**
 * 
 * @export
 * @interface GetAdminActiveWalletV2Response
 */
export interface GetAdminActiveWalletV2Response {
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof GetAdminActiveWalletV2Response
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    address?: string;
    /**
     * 
     * @type {WalletStatus}
     * @memberof GetAdminActiveWalletV2Response
     */
    status: WalletStatus;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof GetAdminActiveWalletV2Response
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetAdminActiveWalletV2Response
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletV2Response
     */
    finalApprovedAt: string;
}
/**
 * 
 * @export
 * @interface GetAdminActiveWalletsRequestBody
 */
export interface GetAdminActiveWalletsRequestBody {
    /**
     * 
     * @type {Array<GetAdminActiveWalletsSortRequestParams>}
     * @memberof GetAdminActiveWalletsRequestBody
     */
    sorts?: Array<GetAdminActiveWalletsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetAdminActiveWalletsSortRequestParams
 */
export interface GetAdminActiveWalletsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletsSortRequestParams
     */
    sortField?: GetAdminActiveWalletsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletsSortRequestParams
     */
    direction?: GetAdminActiveWalletsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetAdminActiveWalletsSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAdminActiveWalletsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetAdminActiveWalletsV2RequestBody
 */
export interface GetAdminActiveWalletsV2RequestBody {
    /**
     * 
     * @type {Array<GetAdminActiveWalletsV2SortRequestParams>}
     * @memberof GetAdminActiveWalletsV2RequestBody
     */
    sorts?: Array<GetAdminActiveWalletsV2SortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetAdminActiveWalletsV2SortRequestParams
 */
export interface GetAdminActiveWalletsV2SortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletsV2SortRequestParams
     */
    sortField?: GetAdminActiveWalletsV2SortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAdminActiveWalletsV2SortRequestParams
     */
    direction?: GetAdminActiveWalletsV2SortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetAdminActiveWalletsV2SortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAdminActiveWalletsV2SortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetAdminTransfersRequestBody
 */
export interface GetAdminTransfersRequestBody {
    /**
     * 
     * @type {Array<GetAdminTransfersSortRequestParams>}
     * @memberof GetAdminTransfersRequestBody
     */
    sorts?: Array<GetAdminTransfersSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetAdminTransfersSortRequestParams
 */
export interface GetAdminTransfersSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetAdminTransfersSortRequestParams
     */
    sortField?: GetAdminTransfersSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTransfersSortRequestParams
     */
    direction?: GetAdminTransfersSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetAdminTransfersSortRequestParamsSortFieldEnum {
    UpdatedAt = 'UPDATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAdminTransfersSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetAdminTransfersV2RequestBody
 */
export interface GetAdminTransfersV2RequestBody {
    /**
     * 
     * @type {Array<GetAdminTransfersV2SortRequestParams>}
     * @memberof GetAdminTransfersV2RequestBody
     */
    sorts?: Array<GetAdminTransfersV2SortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetAdminTransfersV2SortRequestParams
 */
export interface GetAdminTransfersV2SortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetAdminTransfersV2SortRequestParams
     */
    sortField?: GetAdminTransfersV2SortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAdminTransfersV2SortRequestParams
     */
    direction?: GetAdminTransfersV2SortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetAdminTransfersV2SortRequestParamsSortFieldEnum {
    UpdatedAt = 'UPDATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAdminTransfersV2SortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetCoinRegistrationRequestsRequestBody
 */
export interface GetCoinRegistrationRequestsRequestBody {
    /**
     * 
     * @type {Array<GetCoinRegistrationRequestsSortRequestParams>}
     * @memberof GetCoinRegistrationRequestsRequestBody
     */
    sorts?: Array<GetCoinRegistrationRequestsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetCoinRegistrationRequestsSortRequestParams
 */
export interface GetCoinRegistrationRequestsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetCoinRegistrationRequestsSortRequestParams
     */
    sortField?: GetCoinRegistrationRequestsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCoinRegistrationRequestsSortRequestParams
     */
    direction?: GetCoinRegistrationRequestsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetCoinRegistrationRequestsSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT',
    Blockchain = 'BLOCKCHAIN',
    Name = 'NAME',
    Address = 'ADDRESS'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCoinRegistrationRequestsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetOrganizationAmlStatusRequestBody
 */
export interface GetOrganizationAmlStatusRequestBody {
    /**
     * 
     * @type {Array<GetOrganizationAmlStatusSortRequestParams>}
     * @memberof GetOrganizationAmlStatusRequestBody
     */
    sorts?: Array<GetOrganizationAmlStatusSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetOrganizationAmlStatusResponse
 */
export interface GetOrganizationAmlStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusResponse
     */
    name: string;
    /**
     * 
     * @type {RiskAssessment}
     * @memberof GetOrganizationAmlStatusResponse
     */
    riskAssessment: RiskAssessment;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusResponse
     */
    performanceDate: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusResponse
     */
    rePerformanceDueDate: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusResponse
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface GetOrganizationAmlStatusSortRequestParams
 */
export interface GetOrganizationAmlStatusSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusSortRequestParams
     */
    sortField?: GetOrganizationAmlStatusSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationAmlStatusSortRequestParams
     */
    direction?: GetOrganizationAmlStatusSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationAmlStatusSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT',
    RePerformanceDate = 'RE_PERFORMANCE_DATE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationAmlStatusSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetOrganizationCreationTargetResponse
 */
export interface GetOrganizationCreationTargetResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationCreationTargetResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationCreationTargetResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationCreationTargetResponse
     */
    taxIdentificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationCreationTargetResponse
     */
    address: string;
}
/**
 * 
 * @export
 * @interface GetOrganizationDetailInfoResponse
 */
export interface GetOrganizationDetailInfoResponse {
    /**
     * 
     * @type {DetailDto}
     * @memberof GetOrganizationDetailInfoResponse
     */
    detail: DetailDto;
    /**
     * 
     * @type {CustodyFeeDto}
     * @memberof GetOrganizationDetailInfoResponse
     */
    custodyFee: CustodyFeeDto;
    /**
     * 
     * @type {AmlStatusDto}
     * @memberof GetOrganizationDetailInfoResponse
     */
    amlStatus: AmlStatusDto;
}
/**
 * 
 * @export
 * @interface GetOrganizationInfoResponse
 */
export interface GetOrganizationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    representativeFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    representativeLastName: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    representativeNationality: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    email: string;
    /**
     * 
     * @type {OrganizationManagementDetailStatus}
     * @memberof GetOrganizationInfoResponse
     */
    status: OrganizationManagementDetailStatus;
    /**
     * 
     * @type {CustodyNonSigningRequestStatus}
     * @memberof GetOrganizationInfoResponse
     */
    requestStatus: CustodyNonSigningRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    signUpAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInfoResponse
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface GetOrganizationInvitationsRequestBody
 */
export interface GetOrganizationInvitationsRequestBody {
    /**
     * 
     * @type {Array<GetOrganizationInvitationsSortRequestParams>}
     * @memberof GetOrganizationInvitationsRequestBody
     */
    sorts?: Array<GetOrganizationInvitationsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetOrganizationInvitationsSortRequestParams
 */
export interface GetOrganizationInvitationsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInvitationsSortRequestParams
     */
    sortField?: GetOrganizationInvitationsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationInvitationsSortRequestParams
     */
    direction?: GetOrganizationInvitationsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationInvitationsSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT',
    ProcessedAt = 'PROCESSED_AT',
    OrganizationName = 'ORGANIZATION_NAME'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationInvitationsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetOrganizationManagementRequestsRequestBody
 */
export interface GetOrganizationManagementRequestsRequestBody {
    /**
     * 
     * @type {Array<GetOrganizationManagementRequestsSortRequestParams>}
     * @memberof GetOrganizationManagementRequestsRequestBody
     */
    sorts?: Array<GetOrganizationManagementRequestsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetOrganizationManagementRequestsSortRequestParams
 */
export interface GetOrganizationManagementRequestsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationManagementRequestsSortRequestParams
     */
    sortField?: GetOrganizationManagementRequestsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationManagementRequestsSortRequestParams
     */
    direction?: GetOrganizationManagementRequestsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationManagementRequestsSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationManagementRequestsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetOrganizationUpdateInfoHistoriesRequestBody
 */
export interface GetOrganizationUpdateInfoHistoriesRequestBody {
    /**
     * 
     * @type {Array<GetOrganizationUpdateInfoHistoriesSortRequestParams>}
     * @memberof GetOrganizationUpdateInfoHistoriesRequestBody
     */
    sorts?: Array<GetOrganizationUpdateInfoHistoriesSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetOrganizationUpdateInfoHistoriesSortRequestParams
 */
export interface GetOrganizationUpdateInfoHistoriesSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoriesSortRequestParams
     */
    sortField?: GetOrganizationUpdateInfoHistoriesSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoriesSortRequestParams
     */
    direction?: GetOrganizationUpdateInfoHistoriesSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationUpdateInfoHistoriesSortRequestParamsSortFieldEnum {
    UpdatedAt = 'UPDATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationUpdateInfoHistoriesSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetOrganizationUpdateInfoHistoryResponse
 */
export interface GetOrganizationUpdateInfoHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    name: string;
    /**
     * 
     * @type {Detail}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    detail: Detail;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    requesterName: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    approverName: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    finalApproverName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    infoUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationUpdateInfoHistoryResponse
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface GetOrganizationsRequestBody
 */
export interface GetOrganizationsRequestBody {
    /**
     * 
     * @type {Array<GetOrganizationsSortRequestParams>}
     * @memberof GetOrganizationsRequestBody
     */
    sorts?: Array<GetOrganizationsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetOrganizationsSortRequestParams
 */
export interface GetOrganizationsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationsSortRequestParams
     */
    sortField?: GetOrganizationsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationsSortRequestParams
     */
    direction?: GetOrganizationsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationsSortRequestParamsSortFieldEnum {
    SignupAt = 'SIGNUP_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetOrganizationsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetTxStatusRequest
 */
export interface GetTxStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetTxStatusRequest
     */
    transferId: string;
}
/**
 * 
 * @export
 * @interface GetTxStatusResponse
 */
export interface GetTxStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTxStatusResponse
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof GetTxStatusResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof GetTxStatusResponse
     */
    txid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTxStatusResponse
     */
    vout?: string;
}
/**
 * 
 * @export
 * @interface GetVaspListResponse
 */
export interface GetVaspListResponse {
    /**
     * 
     * @type {Array<Vasp>}
     * @memberof GetVaspListResponse
     */
    vasps: Array<Vasp>;
}
/**
 * 
 * @export
 * @interface GetWalletCustodyRequestsRequestBody
 */
export interface GetWalletCustodyRequestsRequestBody {
    /**
     * 
     * @type {Array<GetWalletCustodyRequestsSortRequestParams>}
     * @memberof GetWalletCustodyRequestsRequestBody
     */
    sorts?: Array<GetWalletCustodyRequestsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetWalletCustodyRequestsSortRequestParams
 */
export interface GetWalletCustodyRequestsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetWalletCustodyRequestsSortRequestParams
     */
    sortField?: GetWalletCustodyRequestsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWalletCustodyRequestsSortRequestParams
     */
    direction?: GetWalletCustodyRequestsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWalletCustodyRequestsSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWalletCustodyRequestsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetWalletCustodyRequestsV2RequestBody
 */
export interface GetWalletCustodyRequestsV2RequestBody {
    /**
     * 
     * @type {Array<GetWalletCustodyRequestsV2SortRequestParams>}
     * @memberof GetWalletCustodyRequestsV2RequestBody
     */
    sorts?: Array<GetWalletCustodyRequestsV2SortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetWalletCustodyRequestsV2SortRequestParams
 */
export interface GetWalletCustodyRequestsV2SortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetWalletCustodyRequestsV2SortRequestParams
     */
    sortField?: GetWalletCustodyRequestsV2SortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWalletCustodyRequestsV2SortRequestParams
     */
    direction?: GetWalletCustodyRequestsV2SortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWalletCustodyRequestsV2SortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWalletCustodyRequestsV2SortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetWithdrawalCustodyRequestsRequestBody
 */
export interface GetWithdrawalCustodyRequestsRequestBody {
    /**
     * 
     * @type {Array<GetWithdrawalCustodyRequestsSortRequestParams>}
     * @memberof GetWithdrawalCustodyRequestsRequestBody
     */
    sorts?: Array<GetWithdrawalCustodyRequestsSortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetWithdrawalCustodyRequestsSortRequestParams
 */
export interface GetWithdrawalCustodyRequestsSortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawalCustodyRequestsSortRequestParams
     */
    sortField?: GetWithdrawalCustodyRequestsSortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawalCustodyRequestsSortRequestParams
     */
    direction?: GetWithdrawalCustodyRequestsSortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWithdrawalCustodyRequestsSortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWithdrawalCustodyRequestsSortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface GetWithdrawalCustodyRequestsV2RequestBody
 */
export interface GetWithdrawalCustodyRequestsV2RequestBody {
    /**
     * 
     * @type {Array<GetWithdrawalCustodyRequestsV2SortRequestParams>}
     * @memberof GetWithdrawalCustodyRequestsV2RequestBody
     */
    sorts?: Array<GetWithdrawalCustodyRequestsV2SortRequestParams>;
}
/**
 * 
 * @export
 * @interface GetWithdrawalCustodyRequestsV2SortRequestParams
 */
export interface GetWithdrawalCustodyRequestsV2SortRequestParams {
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawalCustodyRequestsV2SortRequestParams
     */
    sortField?: GetWithdrawalCustodyRequestsV2SortRequestParamsSortFieldEnum;
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawalCustodyRequestsV2SortRequestParams
     */
    direction?: GetWithdrawalCustodyRequestsV2SortRequestParamsDirectionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GetWithdrawalCustodyRequestsV2SortRequestParamsSortFieldEnum {
    CreatedAt = 'CREATED_AT'
}
/**
    * @export
    * @enum {string}
    */
export enum GetWithdrawalCustodyRequestsV2SortRequestParamsDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface HandOverRoleRequest
 */
export interface HandOverRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof HandOverRoleRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof HandOverRoleRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof HandOverRoleRequest
     */
    to: string;
    /**
     * 
     * @type {AuthorityName}
     * @memberof HandOverRoleRequest
     */
    authorityName: AuthorityName;
    /**
     * 
     * @type {AuthorityName}
     * @memberof HandOverRoleRequest
     */
    fallback: AuthorityName;
}
/**
 * 
 * @export
 * @interface Headquarter
 */
export interface Headquarter {
    /**
     * 
     * @type {string}
     * @memberof Headquarter
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Headquarter
     */
    townName?: string;
    /**
     * 
     * @type {string}
     * @memberof Headquarter
     */
    residentCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof Headquarter
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Headquarter
     */
    faxNumber?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum IndustryType {
    CasinoBusiness = 'CASINO_BUSINESS',
    SmallAmountRemittanceBusiness = 'SMALL_AMOUNT_REMITTANCE_BUSINESS',
    CurrencyExchangeBusiness = 'CURRENCY_EXCHANGE_BUSINESS',
    LoanBusiness = 'LOAN_BUSINESS',
    PreciousMetalDealer = 'PRECIOUS_METAL_DEALER',
    CryptoAssetBusiness = 'CRYPTO_ASSET_BUSINESS',
    CryptoAssetRelatedBusiness = 'CRYPTO_ASSET_RELATED_BUSINESS',
    Etc = 'ETC'
}

/**
 * 
 * @export
 * @interface InviteAccountRequest
 */
export interface InviteAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteAccountRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof InviteAccountRequest
     */
    passphrase: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteAccountRequest
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @interface InviteWalletAccountRequest
 */
export interface InviteWalletAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteWalletAccountRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof InviteWalletAccountRequest
     */
    passphrase: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteWalletAccountRequest
     */
    accountIds: Array<string>;
}
/**
 * 
 * @export
 * @interface ListCoinRequest
 */
export interface ListCoinRequest {
    /**
     * 
     * @type {string}
     * @memberof ListCoinRequest
     */
    coinId: string;
    /**
     * 
     * @type {string}
     * @memberof ListCoinRequest
     */
    otpCode: string;
}
/**
 * 
 * @export
 * @interface ListedCoinProjection
 */
export interface ListedCoinProjection {
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    id: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof ListedCoinProjection
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    coinSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    coinName: string;
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    coinId: string;
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    requesterEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    requesterName?: string;
    /**
     * 
     * @type {string}
     * @memberof ListedCoinProjection
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ListedType {
    Kosdaq = 'KOSDAQ',
    Kospi = 'KOSPI',
    None = 'NONE'
}

/**
 * 
 * @export
 * @interface LogDto
 */
export interface LogDto {
    /**
     * 
     * @type {SigningLogType}
     * @memberof LogDto
     */
    type: SigningLogType;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface LoginIpDto
 */
export interface LoginIpDto {
    /**
     * 
     * @type {string}
     * @memberof LoginIpDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginIpDto
     */
    address: string;
    /**
     * 
     * @type {LoginIpStatus}
     * @memberof LoginIpDto
     */
    status: LoginIpStatus;
    /**
     * 
     * @type {string}
     * @memberof LoginIpDto
     */
    lastRequestedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoginIpDto
     */
    lastVerifiedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoginIpStatus {
    Requested = 'REQUESTED',
    Verified = 'VERIFIED'
}

/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    otpCode?: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    orgId: string;
    /**
     * 
     * @type {AccountStatus}
     * @memberof LoginResponse
     */
    status: AccountStatus;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof LoginResponse
     */
    roles: Array<RoleDto>;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {OtpDto}
     * @memberof LoginResponse
     */
    otp: OtpDto;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    updatedAt: string;
    /**
     * 
     * @type {LoginStatus}
     * @memberof LoginResponse
     */
    loginStatus: LoginStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoginStatus {
    InvalidPassphrase = 'INVALID_PASSPHRASE',
    NeedOtp = 'NEED_OTP',
    NeedIpVerification = 'NEED_IP_VERIFICATION',
    Success = 'SUCCESS'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MonthlyQuarterly {
    Month = 'MONTH',
    Quarter = 'QUARTER'
}

/**
 * 
 * @export
 * @interface MultiUpdateDetail
 */
export interface MultiUpdateDetail {
    /**
     * 
     * @type {string}
     * @memberof MultiUpdateDetail
     */
    title: string;
    /**
     * 
     * @type {Array<Array<Record>>}
     * @memberof MultiUpdateDetail
     */
    before: Array<Array<Record>>;
    /**
     * 
     * @type {Array<Array<Record>>}
     * @memberof MultiUpdateDetail
     */
    after: Array<Array<Record>>;
}
/**
 * 
 * @export
 * @interface OnchainBalanceSnapshotDto
 */
export interface OnchainBalanceSnapshotDto {
    /**
     * 
     * @type {string}
     * @memberof OnchainBalanceSnapshotDto
     */
    orgName: string;
    /**
     * 
     * @type {SimpleWalletInfoDTO}
     * @memberof OnchainBalanceSnapshotDto
     */
    wallet: SimpleWalletInfoDTO;
    /**
     * 
     * @type {CoinDto}
     * @memberof OnchainBalanceSnapshotDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof OnchainBalanceSnapshotDto
     */
    offchainBalance?: string;
    /**
     * 
     * @type {string}
     * @memberof OnchainBalanceSnapshotDto
     */
    onchainBalance?: string;
}
/**
 * 
 * @export
 * @interface OrganizationAccountDto
 */
export interface OrganizationAccountDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountStatus}
     * @memberof OrganizationAccountDto
     */
    status: AccountStatus;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof OrganizationAccountDto
     */
    roles: Array<RoleDto>;
    /**
     * 
     * @type {Array<LoginIpDto>}
     * @memberof OrganizationAccountDto
     */
    loginIps: Array<LoginIpDto>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface OrganizationAmlStatusDto
 */
export interface OrganizationAmlStatusDto {
    /**
     * 
     * @type {TransactionPurpose}
     * @memberof OrganizationAmlStatusDto
     */
    transactionPurpose: TransactionPurpose;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAmlStatusDto
     */
    transactionPurposeEtc?: string;
    /**
     * 
     * @type {FundsSource}
     * @memberof OrganizationAmlStatusDto
     */
    fundsSource: FundsSource;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAmlStatusDto
     */
    fundsSourceEtc?: string;
    /**
     * 
     * @type {Array<CustomerWalletAddress>}
     * @memberof OrganizationAmlStatusDto
     */
    customerWalletAddresses: Array<CustomerWalletAddress>;
    /**
     * 
     * @type {CddEdd}
     * @memberof OrganizationAmlStatusDto
     */
    cddEdd: CddEdd;
    /**
     * 
     * @type {WlfPerformanceInfo}
     * @memberof OrganizationAmlStatusDto
     */
    wlfPerformanceInfo: WlfPerformanceInfo;
}
/**
 * 
 * @export
 * @interface OrganizationCustodyFeeDto
 */
export interface OrganizationCustodyFeeDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustodyFeeDto
     */
    setupCostKrw?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustodyFeeDto
     */
    setupCostUsd?: string;
    /**
     * 
     * @type {CustodyFeeType}
     * @memberof OrganizationCustodyFeeDto
     */
    custodyFeeType: CustodyFeeType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustodyFeeDto
     */
    custodyFeeKRW?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustodyFeeDto
     */
    custodyFeeUSD?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCustodyFeeDto
     */
    custodyFeePercentage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationCustodyFeeDto
     */
    taxInvoiceIssuance: boolean;
    /**
     * 
     * @type {Array<ClientContact>}
     * @memberof OrganizationCustodyFeeDto
     */
    clientContacts?: Array<ClientContact>;
}
/**
 * 
 * @export
 * @interface OrganizationDetailDto
 */
export interface OrganizationDetailDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    orgName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    englishOrgName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDetailDto
     */
    orgCode?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    taxIdentificationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    corporationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    establishmentPurpose?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    foundationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    homepage?: string;
    /**
     * 
     * @type {CompanyType}
     * @memberof OrganizationDetailDto
     */
    companyType: CompanyType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    companyTypeEtc?: string;
    /**
     * 
     * @type {ListedType}
     * @memberof OrganizationDetailDto
     */
    listedType: ListedType;
    /**
     * 
     * @type {IndustryType}
     * @memberof OrganizationDetailDto
     */
    industryType: IndustryType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    industryTypeEtc?: string;
    /**
     * 
     * @type {Headquarter}
     * @memberof OrganizationDetailDto
     */
    headquarter?: Headquarter;
    /**
     * 
     * @type {BusinessPlace}
     * @memberof OrganizationDetailDto
     */
    businessPlace?: BusinessPlace;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDetailDto
     */
    contactableEmail?: string;
    /**
     * 
     * @type {Array<Representative>}
     * @memberof OrganizationDetailDto
     */
    representatives?: Array<Representative>;
    /**
     * 
     * @type {Array<ActualOwner>}
     * @memberof OrganizationDetailDto
     */
    actualOwners?: Array<ActualOwner>;
}
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    contactNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDto
     */
    initialized: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationInvitationStatus {
    Canceled = 'CANCELED',
    WaitingApprove = 'WAITING_APPROVE',
    WaitingUserProcess = 'WAITING_USER_PROCESS',
    Processed = 'PROCESSED',
    Rejected = 'REJECTED'
}

/**
 * 
 * @export
 * @interface OrganizationManagement
 */
export interface OrganizationManagement {
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagement
     */
    orgName: string;
    /**
     * 
     * @type {Detail}
     * @memberof OrganizationManagement
     */
    detail: Detail;
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagement
     */
    requestedAt: string;
}
/**
 * 
 * @export
 * @interface OrganizationManagementCustodyRequestDto
 */
export interface OrganizationManagementCustodyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyNonSigningRequestType}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    type: CustodyNonSigningRequestType;
    /**
     * 
     * @type {CustodyNonSigningRequestStatus}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    status: CustodyNonSigningRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {OrganizationManagement}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    organizationManagement: OrganizationManagement;
    /**
     * 
     * @type {AccountDto}
     * @memberof OrganizationManagementCustodyRequestDto
     */
    requester: AccountDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationManagementDetailStatus {
    RegistrationInProgress = 'REGISTRATION_IN_PROGRESS',
    DetailUpdateInProgress = 'DETAIL_UPDATE_IN_PROGRESS',
    CustodyFeeUpdateInProgress = 'CUSTODY_FEE_UPDATE_IN_PROGRESS',
    AmlStatusUpdateInProgress = 'AML_STATUS_UPDATE_IN_PROGRESS',
    DeleteInProgress = 'DELETE_IN_PROGRESS',
    DeleteEnable = 'DELETE_ENABLE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationManagementStatus {
    None = 'NONE',
    UpdateInProgress = 'UPDATE_IN_PROGRESS',
    DeleteInProgress = 'DELETE_IN_PROGRESS'
}

/**
 * 
 * @export
 * @interface OrganizationManagementUpdateDetail
 */
export interface OrganizationManagementUpdateDetail {
    /**
     * 
     * @type {string}
     * @memberof OrganizationManagementUpdateDetail
     */
    orgName: string;
    /**
     * 
     * @type {UpdateDetailStatus}
     * @memberof OrganizationManagementUpdateDetail
     */
    status: UpdateDetailStatus;
    /**
     * 
     * @type {Detail}
     * @memberof OrganizationManagementUpdateDetail
     */
    detail: Detail;
    /**
     * 
     * @type {Array<SingleUpdateDetail>}
     * @memberof OrganizationManagementUpdateDetail
     */
    singleUpdateDetails: Array<SingleUpdateDetail>;
    /**
     * 
     * @type {Array<MultiUpdateDetail>}
     * @memberof OrganizationManagementUpdateDetail
     */
    multiUpdateDetails: Array<MultiUpdateDetail>;
}
/**
 * 
 * @export
 * @interface OtpDto
 */
export interface OtpDto {
    /**
     * 
     * @type {string}
     * @memberof OtpDto
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OtpDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface OtpOnlyAuthenticateRequest
 */
export interface OtpOnlyAuthenticateRequest {
    /**
     * 
     * @type {string}
     * @memberof OtpOnlyAuthenticateRequest
     */
    otpCode: string;
}
/**
 * 
 * @export
 * @interface PaginationClientRequestBoxDto
 */
export interface PaginationClientRequestBoxDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationClientRequestBoxDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<ClientRequestBoxDto>}
     * @memberof PaginationClientRequestBoxDto
     */
    results: Array<ClientRequestBoxDto>;
}
/**
 * 
 * @export
 * @interface PaginationClientSubmittedRequestDto
 */
export interface PaginationClientSubmittedRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationClientSubmittedRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<ClientSubmittedRequestDto>}
     * @memberof PaginationClientSubmittedRequestDto
     */
    results: Array<ClientSubmittedRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationCoinRegistrationRequestDto
 */
export interface PaginationCoinRegistrationRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationCoinRegistrationRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<CoinRegistrationRequestDto>}
     * @memberof PaginationCoinRegistrationRequestDto
     */
    results: Array<CoinRegistrationRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationGetAdminActiveWalletResponse
 */
export interface PaginationGetAdminActiveWalletResponse {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationGetAdminActiveWalletResponse
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<GetAdminActiveWalletResponse>}
     * @memberof PaginationGetAdminActiveWalletResponse
     */
    results: Array<GetAdminActiveWalletResponse>;
}
/**
 * 
 * @export
 * @interface PaginationGetAdminActiveWalletV2Response
 */
export interface PaginationGetAdminActiveWalletV2Response {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationGetAdminActiveWalletV2Response
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<GetAdminActiveWalletV2Response>}
     * @memberof PaginationGetAdminActiveWalletV2Response
     */
    results: Array<GetAdminActiveWalletV2Response>;
}
/**
 * 
 * @export
 * @interface PaginationGetOrganizationAmlStatusResponse
 */
export interface PaginationGetOrganizationAmlStatusResponse {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationGetOrganizationAmlStatusResponse
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<GetOrganizationAmlStatusResponse>}
     * @memberof PaginationGetOrganizationAmlStatusResponse
     */
    results: Array<GetOrganizationAmlStatusResponse>;
}
/**
 * 
 * @export
 * @interface PaginationGetOrganizationInfoResponse
 */
export interface PaginationGetOrganizationInfoResponse {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationGetOrganizationInfoResponse
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<GetOrganizationInfoResponse>}
     * @memberof PaginationGetOrganizationInfoResponse
     */
    results: Array<GetOrganizationInfoResponse>;
}
/**
 * 
 * @export
 * @interface PaginationGetOrganizationUpdateInfoHistoryResponse
 */
export interface PaginationGetOrganizationUpdateInfoHistoryResponse {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationGetOrganizationUpdateInfoHistoryResponse
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<GetOrganizationUpdateInfoHistoryResponse>}
     * @memberof PaginationGetOrganizationUpdateInfoHistoryResponse
     */
    results: Array<GetOrganizationUpdateInfoHistoryResponse>;
}
/**
 * 
 * @export
 * @interface PaginationMeta
 */
export interface PaginationMeta {
    /**
     * 
     * @type {string}
     * @memberof PaginationMeta
     */
    nextUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationMeta
     */
    previousUrl: string;
    /**
     * 
     * @type {number}
     * @memberof PaginationMeta
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface PaginationOnchainBalanceSnapshotDto
 */
export interface PaginationOnchainBalanceSnapshotDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationOnchainBalanceSnapshotDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<OnchainBalanceSnapshotDto>}
     * @memberof PaginationOnchainBalanceSnapshotDto
     */
    results: Array<OnchainBalanceSnapshotDto>;
}
/**
 * 
 * @export
 * @interface PaginationOrganizationDto
 */
export interface PaginationOrganizationDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationOrganizationDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<OrganizationDto>}
     * @memberof PaginationOrganizationDto
     */
    results: Array<OrganizationDto>;
}
/**
 * 
 * @export
 * @interface PaginationOrganizationManagementCustodyRequestDto
 */
export interface PaginationOrganizationManagementCustodyRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationOrganizationManagementCustodyRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<OrganizationManagementCustodyRequestDto>}
     * @memberof PaginationOrganizationManagementCustodyRequestDto
     */
    results: Array<OrganizationManagementCustodyRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationSimpleOrganizationInvitationProjection
 */
export interface PaginationSimpleOrganizationInvitationProjection {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationSimpleOrganizationInvitationProjection
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<SimpleOrganizationInvitationProjection>}
     * @memberof PaginationSimpleOrganizationInvitationProjection
     */
    results: Array<SimpleOrganizationInvitationProjection>;
}
/**
 * 
 * @export
 * @interface PaginationTransferDto
 */
export interface PaginationTransferDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationTransferDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<TransferDto>}
     * @memberof PaginationTransferDto
     */
    results: Array<TransferDto>;
}
/**
 * 
 * @export
 * @interface PaginationTransferV2Dto
 */
export interface PaginationTransferV2Dto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationTransferV2Dto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<TransferV2Dto>}
     * @memberof PaginationTransferV2Dto
     */
    results: Array<TransferV2Dto>;
}
/**
 * 
 * @export
 * @interface PaginationTransferV2WithRequestDto
 */
export interface PaginationTransferV2WithRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationTransferV2WithRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<TransferV2WithRequestDto>}
     * @memberof PaginationTransferV2WithRequestDto
     */
    results: Array<TransferV2WithRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationTransferWithRequestDto
 */
export interface PaginationTransferWithRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationTransferWithRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<TransferWithRequestDto>}
     * @memberof PaginationTransferWithRequestDto
     */
    results: Array<TransferWithRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationWalletCustodyRequestDto
 */
export interface PaginationWalletCustodyRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationWalletCustodyRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<WalletCustodyRequestDto>}
     * @memberof PaginationWalletCustodyRequestDto
     */
    results: Array<WalletCustodyRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationWalletCustodyRequestV2Dto
 */
export interface PaginationWalletCustodyRequestV2Dto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationWalletCustodyRequestV2Dto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<WalletCustodyRequestV2Dto>}
     * @memberof PaginationWalletCustodyRequestV2Dto
     */
    results: Array<WalletCustodyRequestV2Dto>;
}
/**
 * 
 * @export
 * @interface PaginationWalletTotalBalance
 */
export interface PaginationWalletTotalBalance {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationWalletTotalBalance
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<WalletTotalBalance>}
     * @memberof PaginationWalletTotalBalance
     */
    results: Array<WalletTotalBalance>;
}
/**
 * 
 * @export
 * @interface PaginationWithdrawalCustodyRequestDto
 */
export interface PaginationWithdrawalCustodyRequestDto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationWithdrawalCustodyRequestDto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<WithdrawalCustodyRequestDto>}
     * @memberof PaginationWithdrawalCustodyRequestDto
     */
    results: Array<WithdrawalCustodyRequestDto>;
}
/**
 * 
 * @export
 * @interface PaginationWithdrawalCustodyRequestV2Dto
 */
export interface PaginationWithdrawalCustodyRequestV2Dto {
    /**
     * 
     * @type {PaginationMeta}
     * @memberof PaginationWithdrawalCustodyRequestV2Dto
     */
    pagination: PaginationMeta;
    /**
     * 
     * @type {Array<WithdrawalCustodyRequestV2Dto>}
     * @memberof PaginationWithdrawalCustodyRequestV2Dto
     */
    results: Array<WithdrawalCustodyRequestV2Dto>;
}
/**
 * 
 * @export
 * @interface PassphraseRecoverRequest
 */
export interface PassphraseRecoverRequest {
    /**
     * 
     * @type {string}
     * @memberof PassphraseRecoverRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PassphraseRecoverRequest
     */
    otpCode: string;
}
/**
 * 
 * @export
 * @interface PassphraseResetRequest
 */
export interface PassphraseResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PassphraseResetRequest
     */
    newPassphrase: string;
    /**
     * 
     * @type {string}
     * @memberof PassphraseResetRequest
     */
    token: string;
}
/**
 * 
 * @export
 * @interface ProcessOrganizationInviteRequest
 */
export interface ProcessOrganizationInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProcessOrganizationInviteRequest
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessOrganizationInviteRequest
     */
    superAdminName: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessOrganizationInviteRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessOrganizationInviteRequest
     */
    token: string;
}
/**
 * 
 * @export
 * @interface PublicOrganizationInvitationInfoProjection
 */
export interface PublicOrganizationInvitationInfoProjection {
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    superAdminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    superAdminName: string;
    /**
     * 
     * @type {OrganizationInvitationStatus}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    status: OrganizationInvitationStatus;
    /**
     * 
     * @type {string}
     * @memberof PublicOrganizationInvitationInfoProjection
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface QrCodeDto
 */
export interface QrCodeDto {
    /**
     * 
     * @type {string}
     * @memberof QrCodeDto
     */
    qrCodeData: string;
}
/**
 * 
 * @export
 * @interface Record
 */
export interface Record {
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Record
     */
    value: string;
}
/**
 * 
 * @export
 * @interface RegisterOrganizationRequest
 */
export interface RegisterOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterOrganizationRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterOrganizationRequest
     */
    passphrase: string;
    /**
     * 
     * @type {OrganizationDetailDto}
     * @memberof RegisterOrganizationRequest
     */
    detail: OrganizationDetailDto;
    /**
     * 
     * @type {OrganizationCustodyFeeDto}
     * @memberof RegisterOrganizationRequest
     */
    custodyFee: OrganizationCustodyFeeDto;
    /**
     * 
     * @type {OrganizationAmlStatusDto}
     * @memberof RegisterOrganizationRequest
     */
    amlStatus: OrganizationAmlStatusDto;
}
/**
 * 
 * @export
 * @interface Representative
 */
export interface Representative {
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    koreanName?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    koreanLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    koreanFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    englishName?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    englishLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    englishFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    birthOfDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    sex?: string;
}
/**
 * 
 * @export
 * @interface ResourceDto
 */
export interface ResourceDto {
    /**
     * 
     * @type {string}
     * @memberof ResourceDto
     */
    id: string;
    /**
     * 
     * @type {ResourceType}
     * @memberof ResourceDto
     */
    type: ResourceType;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceType {
    Organization = 'ORGANIZATION',
    Wallet = 'WALLET'
}

/**
 * 
 * @export
 * @interface RetrieveVaspTransferDataRequest
 */
export interface RetrieveVaspTransferDataRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataRequest
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataRequest
     */
    txid: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataRequest
     */
    vout?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataRequest
     */
    payload: string;
}
/**
 * 
 * @export
 * @interface RetrieveVaspTransferDataResponse
 */
export interface RetrieveVaspTransferDataResponse {
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    result: RetrieveVaspTransferDataResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    reasonType?: RetrieveVaspTransferDataResponseReasonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    reasonMsg?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    historicalCost?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    tradePrice?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    tradeCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    isExceedingThreshold?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveVaspTransferDataResponse
     */
    payload: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RetrieveVaspTransferDataResponseResultEnum {
    Valid = 'valid',
    Invalid = 'invalid',
    Verified = 'verified',
    Denied = 'denied',
    Normal = 'normal',
    Error = 'error'
}
/**
    * @export
    * @enum {string}
    */
export enum RetrieveVaspTransferDataResponseReasonTypeEnum {
    NotFoundAddress = 'NOT_FOUND_ADDRESS',
    NotSupportedSymbol = 'NOT_SUPPORTED_SYMBOL',
    NotKycUser = 'NOT_KYC_USER',
    SanctionList = 'SANCTION_LIST',
    InputNameMismatched = 'INPUT_NAME_MISMATCHED',
    LackOfInformation = 'LACK_OF_INFORMATION',
    TxidAlreadyExists = 'TXID_ALREADY_EXISTS',
    TransferAlreadyFailed = 'TRANSFER_ALREADY_FAILED',
    UnknownTransferId = 'UNKNOWN_TRANSFER_ID',
    IllegalStateflow = 'ILLEGAL_STATEFLOW',
    NotFoundTxid = 'NOT_FOUND_TXID',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RiskAssessment {
    High = 'HIGH',
    Medium = 'MEDIUM',
    Low = 'LOW'
}

/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    id: string;
    /**
     * 
     * @type {ResourceDto}
     * @memberof RoleDto
     */
    resource: ResourceDto;
    /**
     * 
     * @type {AuthorityDto}
     * @memberof RoleDto
     */
    authority: AuthorityDto;
}
/**
 * 
 * @export
 * @interface SigningDto
 */
export interface SigningDto {
    /**
     * 
     * @type {AccountDto}
     * @memberof SigningDto
     */
    account: AccountDto;
    /**
     * 
     * @type {Array<LogDto>}
     * @memberof SigningDto
     */
    logs: Array<LogDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SigningLogType {
    QrCreation = 'QR_CREATION',
    QrRecreation = 'QR_RECREATION',
    ColdWalletSignature = 'COLD_WALLET_SIGNATURE',
    Confirmation = 'CONFIRMATION'
}

/**
 * 
 * @export
 * @interface SigningV2Dto
 */
export interface SigningV2Dto {
    /**
     * 
     * @type {AccountDto}
     * @memberof SigningV2Dto
     */
    account: AccountDto;
    /**
     * 
     * @type {Array<LogDto>}
     * @memberof SigningV2Dto
     */
    logs: Array<LogDto>;
}
/**
 * 
 * @export
 * @interface SimpleAccountInfo
 */
export interface SimpleAccountInfo {
    /**
     * 
     * @type {string}
     * @memberof SimpleAccountInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleAccountInfo
     */
    email: string;
}
/**
 * 
 * @export
 * @interface SimpleBalanceDto
 */
export interface SimpleBalanceDto {
    /**
     * 
     * @type {CoinDto}
     * @memberof SimpleBalanceDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof SimpleBalanceDto
     */
    oneDayBeforeAmount: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleBalanceDto
     */
    amount: string;
}
/**
 * 
 * @export
 * @interface SimpleOrganizationInvitationDTO
 */
export interface SimpleOrganizationInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationDTO
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationDTO
     */
    organizationName: string;
    /**
     * 
     * @type {OrganizationInvitationStatus}
     * @memberof SimpleOrganizationInvitationDTO
     */
    status: OrganizationInvitationStatus;
}
/**
 * 
 * @export
 * @interface SimpleOrganizationInvitationProjection
 */
export interface SimpleOrganizationInvitationProjection {
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationProjection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationProjection
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationProjection
     */
    processedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationProjection
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationProjection
     */
    organizationAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrganizationInvitationProjection
     */
    organizationContactNumber: string;
    /**
     * 
     * @type {OrganizationInvitationStatus}
     * @memberof SimpleOrganizationInvitationProjection
     */
    status: OrganizationInvitationStatus;
}
/**
 * 
 * @export
 * @interface SimpleWalletInfoDTO
 */
export interface SimpleWalletInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof SimpleWalletInfoDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleWalletInfoDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleWalletInfoDTO
     */
    address: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof SimpleWalletInfoDTO
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof SimpleWalletInfoDTO
     */
    orgId: string;
}
/**
 * 
 * @export
 * @interface SingleUpdateDetail
 */
export interface SingleUpdateDetail {
    /**
     * 
     * @type {string}
     * @memberof SingleUpdateDetail
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SingleUpdateDetail
     */
    before?: string;
    /**
     * 
     * @type {string}
     * @memberof SingleUpdateDetail
     */
    after?: string;
}
/**
 * 
 * @export
 * @interface SubmitTransferRequest
 */
export interface SubmitTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitTransferRequest
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitTransferRequest
     */
    txid: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitTransferRequest
     */
    vout?: string;
}
/**
 * 
 * @export
 * @interface SubmitTransferResponse
 */
export interface SubmitTransferResponse {
    /**
     * 
     * @type {string}
     * @memberof SubmitTransferResponse
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitTransferResponse
     */
    result: SubmitTransferResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmitTransferResponse
     */
    reasonType?: SubmitTransferResponseReasonTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SubmitTransferResponseResultEnum {
    Valid = 'valid',
    Invalid = 'invalid',
    Verified = 'verified',
    Denied = 'denied',
    Normal = 'normal',
    Error = 'error'
}
/**
    * @export
    * @enum {string}
    */
export enum SubmitTransferResponseReasonTypeEnum {
    NotFoundAddress = 'NOT_FOUND_ADDRESS',
    NotSupportedSymbol = 'NOT_SUPPORTED_SYMBOL',
    NotKycUser = 'NOT_KYC_USER',
    SanctionList = 'SANCTION_LIST',
    InputNameMismatched = 'INPUT_NAME_MISMATCHED',
    LackOfInformation = 'LACK_OF_INFORMATION',
    TxidAlreadyExists = 'TXID_ALREADY_EXISTS',
    TransferAlreadyFailed = 'TRANSFER_ALREADY_FAILED',
    UnknownTransferId = 'UNKNOWN_TRANSFER_ID',
    IllegalStateflow = 'ILLEGAL_STATEFLOW',
    NotFoundTxid = 'NOT_FOUND_TXID',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubmittedClientRequestType {
    Transfer = 'TRANSFER',
    Wallet = 'WALLET'
}

/**
 * 
 * @export
 * @interface SupportedBlockchainDto
 */
export interface SupportedBlockchainDto {
    /**
     * 
     * @type {Blockchain}
     * @memberof SupportedBlockchainDto
     */
    blockchain?: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof SupportedBlockchainDto
     */
    symbol?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedBlockchainDto
     */
    chainId?: string;
    /**
     * 
     * @type {number}
     * @memberof SupportedBlockchainDto
     */
    version?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedBlockchainDto
     */
    isTestnet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedBlockchainDto
     */
    isDeprecated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupportedBlockchainDto
     */
    minWithdrawableAmount?: string;
}
/**
 * 
 * @export
 * @interface TokenInfoDto
 */
export interface TokenInfoDto {
    /**
     * 
     * @type {string}
     * @memberof TokenInfoDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TokenInfoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TokenInfoDto
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof TokenInfoDto
     */
    decimal: number;
    /**
     * 
     * @type {Blockchain}
     * @memberof TokenInfoDto
     */
    blockchain: Blockchain;
}
/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    otpCode: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionPurpose {
    Business = 'BUSINESS',
    Investment = 'INVESTMENT',
    Savings = 'SAVINGS',
    TradePayment = 'TRADE_PAYMENT',
    Etc = 'ETC'
}

/**
 * 
 * @export
 * @interface TransferClientRequestDto
 */
export interface TransferClientRequestDto {
    /**
     * 
     * @type {CustodyRequestProcessDto}
     * @memberof TransferClientRequestDto
     */
    custodyRequest?: CustodyRequestProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof TransferClientRequestDto
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof TransferClientRequestDto
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof TransferClientRequestDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof TransferClientRequestDto
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof TransferClientRequestDto
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof TransferClientRequestDto
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof TransferClientRequestDto
     */
    requestedWallet: BaseWalletDto;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDto
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {DetailedTransferRequestDto}
     * @memberof TransferClientRequestDto
     */
    detailedTransferRequest: DetailedTransferRequestDto;
}
/**
 * 
 * @export
 * @interface TransferClientRequestDtoV2
 */
export interface TransferClientRequestDtoV2 {
    /**
     * 
     * @type {CustodyRequestV2ProcessDto}
     * @memberof TransferClientRequestDtoV2
     */
    custodyRequest?: CustodyRequestV2ProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof TransferClientRequestDtoV2
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDtoV2
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDtoV2
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof TransferClientRequestDtoV2
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof TransferClientRequestDtoV2
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof TransferClientRequestDtoV2
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof TransferClientRequestDtoV2
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof TransferClientRequestDtoV2
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof TransferClientRequestDtoV2
     */
    requestedWallet: BaseWalletV2Dto;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDtoV2
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDtoV2
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransferClientRequestDtoV2
     */
    updatedAt: string;
    /**
     * 
     * @type {DetailedTransferV2RequestDto}
     * @memberof TransferClientRequestDtoV2
     */
    detailedTransferRequest: DetailedTransferV2RequestDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransferDetail {
    WithdrawalProcessing = 'WITHDRAWAL_PROCESSING',
    WithdrawalRequested = 'WITHDRAWAL_REQUESTED',
    WithdrawalPending = 'WITHDRAWAL_PENDING',
    WithdrawalFailed = 'WITHDRAWAL_FAILED',
    WithdrawalConfirmed = 'WITHDRAWAL_CONFIRMED',
    DepositConfirmed = 'DEPOSIT_CONFIRMED'
}

/**
 * 
 * @export
 * @interface TransferDto
 */
export interface TransferDto {
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    id: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof TransferDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {CoinDto}
     * @memberof TransferDto
     */
    platformCoin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    walletName: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    walletAddress: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    hash: string;
    /**
     * 
     * @type {AddressInfo}
     * @memberof TransferDto
     */
    from?: AddressInfo;
    /**
     * 
     * @type {AddressInfo}
     * @memberof TransferDto
     */
    to: AddressInfo;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    originBalance: string;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    fee: string;
    /**
     * 
     * @type {TransferType}
     * @memberof TransferDto
     */
    type: TransferType;
    /**
     * 
     * @type {string}
     * @memberof TransferDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransferStatus {
    Processing = 'PROCESSING',
    Requested = 'REQUESTED',
    Pending = 'PENDING',
    Failed = 'FAILED',
    Reverted = 'REVERTED',
    InternalReverted = 'INTERNAL_REVERTED',
    Replaced = 'REPLACED',
    Mined = 'MINED',
    Confirmed = 'CONFIRMED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransferType {
    Withdrawal = 'WITHDRAWAL',
    Deposit = 'DEPOSIT'
}

/**
 * 
 * @export
 * @interface TransferV2Dto
 */
export interface TransferV2Dto {
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    id: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof TransferV2Dto
     */
    coin: CoinDto;
    /**
     * 
     * @type {CoinDto}
     * @memberof TransferV2Dto
     */
    platformCoin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    walletName: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    walletAddress: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    hash: string;
    /**
     * 
     * @type {AddressInfo}
     * @memberof TransferV2Dto
     */
    from?: AddressInfo;
    /**
     * 
     * @type {AddressInfo}
     * @memberof TransferV2Dto
     */
    to: AddressInfo;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    originBalance: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    fee: string;
    /**
     * 
     * @type {TransferType}
     * @memberof TransferV2Dto
     */
    type: TransferType;
    /**
     * 
     * @type {string}
     * @memberof TransferV2Dto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransferV2Status {
    Processing = 'PROCESSING',
    Requested = 'REQUESTED',
    Pending = 'PENDING',
    SendFailed = 'SEND_FAILED',
    TxFailed = 'TX_FAILED',
    Confirmed = 'CONFIRMED'
}

/**
 * 
 * @export
 * @interface TransferV2WithRequestDto
 */
export interface TransferV2WithRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {TransferV2Status}
     * @memberof TransferV2WithRequestDto
     */
    status: TransferV2Status;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    from?: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof TransferV2WithRequestDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {Blockchain}
     * @memberof TransferV2WithRequestDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    amount: string;
    /**
     * 
     * @type {TransferType}
     * @memberof TransferV2WithRequestDto
     */
    type: TransferType;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransferV2WithRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {ClientRequestProcessDto}
     * @memberof TransferV2WithRequestDto
     */
    clientRequest: ClientRequestProcessDto;
    /**
     * 
     * @type {CustodyRequestV2ProcessDto}
     * @memberof TransferV2WithRequestDto
     */
    custodyRequest?: CustodyRequestV2ProcessDto;
}
/**
 * 
 * @export
 * @interface TransferWithRequestDto
 */
export interface TransferWithRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {TransferStatus}
     * @memberof TransferWithRequestDto
     */
    status: TransferStatus;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    from?: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof TransferWithRequestDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {Blockchain}
     * @memberof TransferWithRequestDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    amount: string;
    /**
     * 
     * @type {TransferType}
     * @memberof TransferWithRequestDto
     */
    type: TransferType;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof TransferWithRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {ClientRequestProcessDto}
     * @memberof TransferWithRequestDto
     */
    clientRequest: ClientRequestProcessDto;
    /**
     * 
     * @type {CustodyRequestProcessDto}
     * @memberof TransferWithRequestDto
     */
    custodyRequest?: CustodyRequestProcessDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UpdateDetailStatus {
    Update = 'UPDATE',
    Create = 'CREATE',
    Delete = 'DELETE'
}

/**
 * 
 * @export
 * @interface UpdateOrganizationAmlStatusRequest
 */
export interface UpdateOrganizationAmlStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationAmlStatusRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationAmlStatusRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationAmlStatusRequest
     */
    orgId: string;
    /**
     * 
     * @type {OrganizationAmlStatusDto}
     * @memberof UpdateOrganizationAmlStatusRequest
     */
    amlStatus: OrganizationAmlStatusDto;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationCustodyFeeRequest
 */
export interface UpdateOrganizationCustodyFeeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCustodyFeeRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCustodyFeeRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationCustodyFeeRequest
     */
    orgId: string;
    /**
     * 
     * @type {OrganizationCustodyFeeDto}
     * @memberof UpdateOrganizationCustodyFeeRequest
     */
    custodyFee: OrganizationCustodyFeeDto;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationDetailRequest
 */
export interface UpdateOrganizationDetailRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDetailRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDetailRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDetailRequest
     */
    orgId: string;
    /**
     * 
     * @type {EditableOrganizationDetail}
     * @memberof UpdateOrganizationDetailRequest
     */
    detail: EditableOrganizationDetail;
}
/**
 * 
 * @export
 * @interface UpdateWalletApprovalPolicyRequest
 */
export interface UpdateWalletApprovalPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWalletApprovalPolicyRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWalletApprovalPolicyRequest
     */
    passphrase: string;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof UpdateWalletApprovalPolicyRequest
     */
    policy: ClientRequestApprovalPolicy;
}
/**
 * 
 * @export
 * @interface UpdateWalletNameRequest
 */
export interface UpdateWalletNameRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWalletNameRequest
     */
    otpCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWalletNameRequest
     */
    passphrase: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWalletNameRequest
     */
    newName: string;
}
/**
 * 
 * @export
 * @interface UpsertIndexerRequest
 */
export interface UpsertIndexerRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertIndexerRequest
     */
    batchSize?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertIndexerRequest
     */
    syncedBlockNumber?: string;
}
/**
 * 
 * @export
 * @interface ValidateAddressRequest
 */
export interface ValidateAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateAddressRequest
     */
    address: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof ValidateAddressRequest
     */
    blockchain: Blockchain;
}
/**
 * 
 * @export
 * @interface Vasp
 */
export interface Vasp {
    /**
     * 
     * @type {string}
     * @memberof Vasp
     */
    vaspName?: string;
    /**
     * 
     * @type {string}
     * @memberof Vasp
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof Vasp
     */
    koreanName?: string;
    /**
     * 
     * @type {string}
     * @memberof Vasp
     */
    englishName?: string;
}
/**
 * 
 * @export
 * @interface VaspCodeErrorResponse
 */
export interface VaspCodeErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof VaspCodeErrorResponse
     */
    errorType?: VaspCodeErrorResponseErrorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VaspCodeErrorResponse
     */
    errorMsg?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VaspCodeErrorResponseErrorTypeEnum {
    UnknownTransferId = 'UNKNOWN_TRANSFER_ID',
    VaspBackendInternalError = 'VASP_BACKEND_INTERNAL_ERROR',
    MissingRequiredMsgField = 'MISSING_REQUIRED_MSG_FIELD',
    MissingHeaderField = 'MISSING_HEADER_FIELD',
    InvalidMsgFieldValue = 'INVALID_MSG_FIELD_VALUE',
    DuplicatedTransferId = 'DUPLICATED_TRANSFER_ID',
    InvalidHeaderSignature = 'INVALID_HEADER_SIGNATURE',
    InvalidEncryptedBody = 'INVALID_ENCRYPTED_BODY',
    UnacceptableRequest = 'UNACCEPTABLE_REQUEST'
}

/**
 * 
 * @export
 * @interface VerifyAddressRequest
 */
export interface VerifyAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyAddressRequest
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAddressRequest
     */
    payload: string;
}
/**
 * 
 * @export
 * @interface VerifyAddressResponse
 */
export interface VerifyAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyAddressResponse
     */
    result: VerifyAddressResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyAddressResponse
     */
    reasonType?: VerifyAddressResponseReasonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyAddressResponse
     */
    reasonMsg?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyAddressResponse
     */
    beneficiaryVaspEntityId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum VerifyAddressResponseResultEnum {
    Valid = 'valid',
    Invalid = 'invalid',
    Verified = 'verified',
    Denied = 'denied',
    Normal = 'normal',
    Error = 'error'
}
/**
    * @export
    * @enum {string}
    */
export enum VerifyAddressResponseReasonTypeEnum {
    NotFoundAddress = 'NOT_FOUND_ADDRESS',
    NotSupportedSymbol = 'NOT_SUPPORTED_SYMBOL',
    NotKycUser = 'NOT_KYC_USER',
    SanctionList = 'SANCTION_LIST',
    InputNameMismatched = 'INPUT_NAME_MISMATCHED',
    LackOfInformation = 'LACK_OF_INFORMATION',
    TxidAlreadyExists = 'TXID_ALREADY_EXISTS',
    TransferAlreadyFailed = 'TRANSFER_ALREADY_FAILED',
    UnknownTransferId = 'UNKNOWN_TRANSFER_ID',
    IllegalStateflow = 'ILLEGAL_STATEFLOW',
    NotFoundTxid = 'NOT_FOUND_TXID',
    Unknown = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface VerifyCorporationNumberRequest
 */
export interface VerifyCorporationNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyCorporationNumberRequest
     */
    corporationNumber: string;
}
/**
 * 
 * @export
 * @interface VerifyEmailDto
 */
export interface VerifyEmailDto {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyEmailDto
     */
    isExists: boolean;
}
/**
 * 
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface VerifyInviteRequest
 */
export interface VerifyInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyInviteRequest
     */
    identifier: string;
}
/**
 * 
 * @export
 * @interface VerifyInviteResponse
 */
export interface VerifyInviteResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyInviteResponse
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyInviteResponse
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyInviteResponse
     */
    email: string;
}
/**
 * 
 * @export
 * @interface VerifyLoginIpRequest
 */
export interface VerifyLoginIpRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyLoginIpRequest
     */
    identifier: string;
}
/**
 * 
 * @export
 * @interface VerifyOrganizationInviteRequest
 */
export interface VerifyOrganizationInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyOrganizationInviteRequest
     */
    token: string;
}
/**
 * 
 * @export
 * @interface VerifyOrganizationNameRequest
 */
export interface VerifyOrganizationNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyOrganizationNameRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface VerifyPassphraseRequest
 */
export interface VerifyPassphraseRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyPassphraseRequest
     */
    passphrase: string;
}
/**
 * 
 * @export
 * @interface VerifyResetTokenRequest
 */
export interface VerifyResetTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyResetTokenRequest
     */
    token: string;
}
/**
 * 
 * @export
 * @interface VerifyTaxIdentificationNumberRequest
 */
export interface VerifyTaxIdentificationNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyTaxIdentificationNumberRequest
     */
    taxIdentificationNumber: string;
}
/**
 * 
 * @export
 * @interface VerifyTxRequest
 */
export interface VerifyTxRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyTxRequest
     */
    txid: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyTxRequest
     */
    vout?: string;
}
/**
 * 
 * @export
 * @interface VerifyTxResponse
 */
export interface VerifyTxResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyTxResponse
     */
    result: VerifyTxResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VerifyTxResponseResultEnum {
    Valid = 'valid',
    Invalid = 'invalid',
    Verified = 'verified',
    Denied = 'denied',
    Normal = 'normal',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface WLFEntity
 */
export interface WLFEntity {
    /**
     * 
     * @type {string}
     * @memberof WLFEntity
     */
    primaryName: string;
    /**
     * 
     * @type {string}
     * @memberof WLFEntity
     */
    type: WLFEntityTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WLFEntityTypeEnum {
    Org = 'Org',
    Human = 'Human'
}

/**
 * 
 * @export
 * @interface WLFHistory
 */
export interface WLFHistory {
    /**
     * 
     * @type {string}
     * @memberof WLFHistory
     */
    createdAt: string;
    /**
     * 
     * @type {Array<WLFEntity>}
     * @memberof WLFHistory
     */
    filteredEntities: Array<WLFEntity>;
}
/**
 * 
 * @export
 * @interface WalletApprovalPolicyDto
 */
export interface WalletApprovalPolicyDto {
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof WalletApprovalPolicyDto
     */
    transfer: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof WalletApprovalPolicyDto
     */
    addressBookCreation: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof WalletApprovalPolicyDto
     */
    addressBookDeletion: ClientRequestApprovalPolicy;
}
/**
 * 
 * @export
 * @interface WalletClientRequestDto
 */
export interface WalletClientRequestDto {
    /**
     * 
     * @type {CustodyRequestProcessDto}
     * @memberof WalletClientRequestDto
     */
    custodyRequest?: CustodyRequestProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof WalletClientRequestDto
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof WalletClientRequestDto
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof WalletClientRequestDto
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof WalletClientRequestDto
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof WalletClientRequestDto
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof WalletClientRequestDto
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof WalletClientRequestDto
     */
    requestedWallet: BaseWalletDto;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDto
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {DetailedWalletRequestDto}
     * @memberof WalletClientRequestDto
     */
    detailedWalletRequestDto: DetailedWalletRequestDto;
}
/**
 * 
 * @export
 * @interface WalletClientRequestDtoV2
 */
export interface WalletClientRequestDtoV2 {
    /**
     * 
     * @type {CustodyRequestV2ProcessDto}
     * @memberof WalletClientRequestDtoV2
     */
    custodyRequest?: CustodyRequestV2ProcessDto;
    /**
     * 
     * @type {ClientRequestType}
     * @memberof WalletClientRequestDtoV2
     */
    type: ClientRequestType;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDtoV2
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDtoV2
     */
    orgId: string;
    /**
     * 
     * @type {AccountDto}
     * @memberof WalletClientRequestDtoV2
     */
    requester: AccountDto;
    /**
     * 
     * @type {AuthorityName}
     * @memberof WalletClientRequestDtoV2
     */
    authority: AuthorityName;
    /**
     * 
     * @type {Array<ClientApprovalDto>}
     * @memberof WalletClientRequestDtoV2
     */
    approvals: Array<ClientApprovalDto>;
    /**
     * 
     * @type {ClientRequestStatus}
     * @memberof WalletClientRequestDtoV2
     */
    status: ClientRequestStatus;
    /**
     * 
     * @type {ClientRequestApprovalPolicy}
     * @memberof WalletClientRequestDtoV2
     */
    approvalPolicy: ClientRequestApprovalPolicy;
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof WalletClientRequestDtoV2
     */
    requestedWallet: BaseWalletV2Dto;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDtoV2
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDtoV2
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WalletClientRequestDtoV2
     */
    updatedAt: string;
    /**
     * 
     * @type {DetailedWalletRequestDtoV2}
     * @memberof WalletClientRequestDtoV2
     */
    detailedWalletRequestDto: DetailedWalletRequestDtoV2;
}
/**
 * 
 * @export
 * @interface WalletCustodyRequestDto
 */
export interface WalletCustodyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof WalletCustodyRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof WalletCustodyRequestDto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestStatus}
     * @memberof WalletCustodyRequestDto
     */
    status: CustodyRequestStatus;
    /**
     * 
     * @type {SigningDto}
     * @memberof WalletCustodyRequestDto
     */
    signing: SigningDto;
    /**
     * 
     * @type {string}
     * @memberof WalletCustodyRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WalletCustodyRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof WalletCustodyRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof WalletCustodyRequestDto
     */
    wallet: BaseWalletDto;
}
/**
 * 
 * @export
 * @interface WalletCustodyRequestV2Dto
 */
export interface WalletCustodyRequestV2Dto {
    /**
     * 
     * @type {string}
     * @memberof WalletCustodyRequestV2Dto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof WalletCustodyRequestV2Dto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestV2Status}
     * @memberof WalletCustodyRequestV2Dto
     */
    status: CustodyRequestV2Status;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof WalletCustodyRequestV2Dto
     */
    commitSigning: SigningV2Dto;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof WalletCustodyRequestV2Dto
     */
    confirmSigning: SigningV2Dto;
    /**
     * 
     * @type {string}
     * @memberof WalletCustodyRequestV2Dto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WalletCustodyRequestV2Dto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalV2Dto>}
     * @memberof WalletCustodyRequestV2Dto
     */
    approvals: Array<CustodyApprovalV2Dto>;
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof WalletCustodyRequestV2Dto
     */
    wallet: BaseWalletV2Dto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WalletStatus {
    Processing = 'PROCESSING',
    Active = 'ACTIVE',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface WalletTotalBalance
 */
export interface WalletTotalBalance {
    /**
     * 
     * @type {SimpleWalletInfoDTO}
     * @memberof WalletTotalBalance
     */
    walletInfo: SimpleWalletInfoDTO;
    /**
     * 
     * @type {Array<SimpleBalanceDto>}
     * @memberof WalletTotalBalance
     */
    balances: Array<SimpleBalanceDto>;
}
/**
 * 
 * @export
 * @interface WalletV2WithRequestDto
 */
export interface WalletV2WithRequestDto {
    /**
     * 
     * @type {string}
     * @memberof WalletV2WithRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WalletV2WithRequestDto
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof WalletV2WithRequestDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof WalletV2WithRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof WalletV2WithRequestDto
     */
    address?: string;
    /**
     * 
     * @type {WalletStatus}
     * @memberof WalletV2WithRequestDto
     */
    status: WalletStatus;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof WalletV2WithRequestDto
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof WalletV2WithRequestDto
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof WalletV2WithRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WalletV2WithRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {CustodyRequestV2ProcessDto}
     * @memberof WalletV2WithRequestDto
     */
    custodyRequest: CustodyRequestV2ProcessDto;
    /**
     * 
     * @type {ClientRequestProcessDto}
     * @memberof WalletV2WithRequestDto
     */
    clientRequest: ClientRequestProcessDto;
}
/**
 * 
 * @export
 * @interface WalletWithRequestDto
 */
export interface WalletWithRequestDto {
    /**
     * 
     * @type {string}
     * @memberof WalletWithRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WalletWithRequestDto
     */
    name: string;
    /**
     * 
     * @type {Blockchain}
     * @memberof WalletWithRequestDto
     */
    blockchain: Blockchain;
    /**
     * 
     * @type {string}
     * @memberof WalletWithRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof WalletWithRequestDto
     */
    address?: string;
    /**
     * 
     * @type {WalletStatus}
     * @memberof WalletWithRequestDto
     */
    status: WalletStatus;
    /**
     * 
     * @type {WalletApprovalPolicyDto}
     * @memberof WalletWithRequestDto
     */
    approvalPolicy: WalletApprovalPolicyDto;
    /**
     * 
     * @type {boolean}
     * @memberof WalletWithRequestDto
     */
    initialized: boolean;
    /**
     * 
     * @type {string}
     * @memberof WalletWithRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WalletWithRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {CustodyRequestProcessDto}
     * @memberof WalletWithRequestDto
     */
    custodyRequest: CustodyRequestProcessDto;
    /**
     * 
     * @type {ClientRequestProcessDto}
     * @memberof WalletWithRequestDto
     */
    clientRequest: ClientRequestProcessDto;
}
/**
 * 
 * @export
 * @interface WithdrawalCustodyRequestDto
 */
export interface WithdrawalCustodyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalCustodyRequestDto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof WithdrawalCustodyRequestDto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestStatus}
     * @memberof WithdrawalCustodyRequestDto
     */
    status: CustodyRequestStatus;
    /**
     * 
     * @type {SigningDto}
     * @memberof WithdrawalCustodyRequestDto
     */
    signing: SigningDto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalCustodyRequestDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalCustodyRequestDto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalDto>}
     * @memberof WithdrawalCustodyRequestDto
     */
    approvals: Array<CustodyApprovalDto>;
    /**
     * 
     * @type {WithdrawalDto}
     * @memberof WithdrawalCustodyRequestDto
     */
    withdrawal: WithdrawalDto;
}
/**
 * 
 * @export
 * @interface WithdrawalCustodyRequestV2Dto
 */
export interface WithdrawalCustodyRequestV2Dto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    id: string;
    /**
     * 
     * @type {CustodyRequestType}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    type: CustodyRequestType;
    /**
     * 
     * @type {CustodyRequestV2Status}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    status: CustodyRequestV2Status;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    commitSigning: SigningV2Dto;
    /**
     * 
     * @type {SigningV2Dto}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    confirmSigning: SigningV2Dto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<CustodyApprovalV2Dto>}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    approvals: Array<CustodyApprovalV2Dto>;
    /**
     * 
     * @type {WithdrawalV2Dto}
     * @memberof WithdrawalCustodyRequestV2Dto
     */
    withdrawal: WithdrawalV2Dto;
}
/**
 * 
 * @export
 * @interface WithdrawalDto
 */
export interface WithdrawalDto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    transferId: string;
    /**
     * 
     * @type {BaseWalletDto}
     * @memberof WithdrawalDto
     */
    wallet: BaseWalletDto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    amount: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof WithdrawalDto
     */
    coin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    hash?: string;
    /**
     * 
     * @type {TransferStatus}
     * @memberof WithdrawalDto
     */
    status: TransferStatus;
}
/**
 * 
 * @export
 * @interface WithdrawalV2Dto
 */
export interface WithdrawalV2Dto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalV2Dto
     */
    transferId: string;
    /**
     * 
     * @type {BaseWalletV2Dto}
     * @memberof WithdrawalV2Dto
     */
    wallet: BaseWalletV2Dto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalV2Dto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalV2Dto
     */
    amount: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof WithdrawalV2Dto
     */
    coin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalV2Dto
     */
    feeAmount: string;
    /**
     * 
     * @type {CoinDto}
     * @memberof WithdrawalV2Dto
     */
    feeCoin: CoinDto;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalV2Dto
     */
    hash?: string;
    /**
     * 
     * @type {TransferV2Status}
     * @memberof WithdrawalV2Dto
     */
    status: TransferV2Status;
}
/**
 * 
 * @export
 * @interface WlfPerformanceInfo
 */
export interface WlfPerformanceInfo {
    /**
     * 
     * @type {string}
     * @memberof WlfPerformanceInfo
     */
    recentPerformanceDate: string;
}

/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {AcceptInviteRequest} acceptInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite: async (accountId: string, acceptInviteRequest: AcceptInviteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling acceptInvite.');
            }
            // verify required parameter 'acceptInviteRequest' is not null or undefined
            if (acceptInviteRequest === null || acceptInviteRequest === undefined) {
                throw new RequiredError('acceptInviteRequest','Required parameter acceptInviteRequest was null or undefined when calling acceptInvite.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/accept-invite`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof acceptInviteRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(acceptInviteRequest !== undefined ? acceptInviteRequest : {})
                : (acceptInviteRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {ChangeAccountNameRequest} changeAccountNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeName: async (accountId: string, changeAccountNameRequest: ChangeAccountNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changeName.');
            }
            // verify required parameter 'changeAccountNameRequest' is not null or undefined
            if (changeAccountNameRequest === null || changeAccountNameRequest === undefined) {
                throw new RequiredError('changeAccountNameRequest','Required parameter changeAccountNameRequest was null or undefined when calling changeName.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/name`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changeAccountNameRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changeAccountNameRequest !== undefined ? changeAccountNameRequest : {})
                : (changeAccountNameRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {ChangePassphraseRequest} changePassphraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassphrase: async (accountId: string, changePassphraseRequest: ChangePassphraseRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changePassphrase.');
            }
            // verify required parameter 'changePassphraseRequest' is not null or undefined
            if (changePassphraseRequest === null || changePassphraseRequest === undefined) {
                throw new RequiredError('changePassphraseRequest','Required parameter changePassphraseRequest was null or undefined when calling changePassphrase.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/passphrase`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changePassphraseRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changePassphraseRequest !== undefined ? changePassphraseRequest : {})
                : (changePassphraseRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmailAddress: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            if (loginRequest === null || loginRequest === undefined) {
                throw new RequiredError('loginRequest','Required parameter loginRequest was null or undefined when calling login.');
            }
            const localVarPath = `/api/v1/accounts/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginRequest !== undefined ? loginRequest : {})
                : (loginRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PassphraseRecoverRequest} passphraseRecoverRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPassphrase: async (passphraseRecoverRequest: PassphraseRecoverRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passphraseRecoverRequest' is not null or undefined
            if (passphraseRecoverRequest === null || passphraseRecoverRequest === undefined) {
                throw new RequiredError('passphraseRecoverRequest','Required parameter passphraseRecoverRequest was null or undefined when calling recoverPassphrase.');
            }
            const localVarPath = `/api/v1/accounts/passphrase/recover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passphraseRecoverRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passphraseRecoverRequest !== undefined ? passphraseRecoverRequest : {})
                : (passphraseRecoverRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (accountId: string, tokenRequest: TokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling refresh.');
            }
            // verify required parameter 'tokenRequest' is not null or undefined
            if (tokenRequest === null || tokenRequest === undefined) {
                throw new RequiredError('tokenRequest','Required parameter tokenRequest was null or undefined when calling refresh.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/token`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tokenRequest !== undefined ? tokenRequest : {})
                : (tokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {PassphraseResetRequest} passphraseResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassphraseWithResetToken: async (accountId: string, passphraseResetRequest: PassphraseResetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling resetPassphraseWithResetToken.');
            }
            // verify required parameter 'passphraseResetRequest' is not null or undefined
            if (passphraseResetRequest === null || passphraseResetRequest === undefined) {
                throw new RequiredError('passphraseResetRequest','Required parameter passphraseResetRequest was null or undefined when calling resetPassphraseWithResetToken.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/passphrase/reset`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passphraseResetRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passphraseResetRequest !== undefined ? passphraseResetRequest : {})
                : (passphraseResetRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling verifyEmail.');
            }
            const localVarPath = `/api/v1/accounts/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyInviteRequest} verifyInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyInvite: async (accountId: string, verifyInviteRequest: VerifyInviteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling verifyInvite.');
            }
            // verify required parameter 'verifyInviteRequest' is not null or undefined
            if (verifyInviteRequest === null || verifyInviteRequest === undefined) {
                throw new RequiredError('verifyInviteRequest','Required parameter verifyInviteRequest was null or undefined when calling verifyInvite.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/verify-invite`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyInviteRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyInviteRequest !== undefined ? verifyInviteRequest : {})
                : (verifyInviteRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} loginIpId 
         * @param {VerifyLoginIpRequest} verifyLoginIpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyLoginIp: async (accountId: string, loginIpId: string, verifyLoginIpRequest: VerifyLoginIpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling verifyLoginIp.');
            }
            // verify required parameter 'loginIpId' is not null or undefined
            if (loginIpId === null || loginIpId === undefined) {
                throw new RequiredError('loginIpId','Required parameter loginIpId was null or undefined when calling verifyLoginIp.');
            }
            // verify required parameter 'verifyLoginIpRequest' is not null or undefined
            if (verifyLoginIpRequest === null || verifyLoginIpRequest === undefined) {
                throw new RequiredError('verifyLoginIpRequest','Required parameter verifyLoginIpRequest was null or undefined when calling verifyLoginIp.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/login-ips/{loginIpId}/verify`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"loginIpId"}}`, encodeURIComponent(String(loginIpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyLoginIpRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyLoginIpRequest !== undefined ? verifyLoginIpRequest : {})
                : (verifyLoginIpRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyPassphraseRequest} verifyPassphraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPassphrase: async (accountId: string, verifyPassphraseRequest: VerifyPassphraseRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling verifyPassphrase.');
            }
            // verify required parameter 'verifyPassphraseRequest' is not null or undefined
            if (verifyPassphraseRequest === null || verifyPassphraseRequest === undefined) {
                throw new RequiredError('verifyPassphraseRequest','Required parameter verifyPassphraseRequest was null or undefined when calling verifyPassphrase.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/passphrase/verify`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyPassphraseRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyPassphraseRequest !== undefined ? verifyPassphraseRequest : {})
                : (verifyPassphraseRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyResetTokenRequest} verifyResetTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyResetToken: async (accountId: string, verifyResetTokenRequest: VerifyResetTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling verifyResetToken.');
            }
            // verify required parameter 'verifyResetTokenRequest' is not null or undefined
            if (verifyResetTokenRequest === null || verifyResetTokenRequest === undefined) {
                throw new RequiredError('verifyResetTokenRequest','Required parameter verifyResetTokenRequest was null or undefined when calling verifyResetToken.');
            }
            const localVarPath = `/api/v1/accounts/{accountId}/token/verify`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyResetTokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyResetTokenRequest !== undefined ? verifyResetTokenRequest : {})
                : (verifyResetTokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {AcceptInviteRequest} acceptInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvite(accountId: string, acceptInviteRequest: AcceptInviteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).acceptInvite(accountId, acceptInviteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {ChangeAccountNameRequest} changeAccountNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeName(accountId: string, changeAccountNameRequest: ChangeAccountNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).changeName(accountId, changeAccountNameRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {ChangePassphraseRequest} changePassphraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassphrase(accountId: string, changePassphraseRequest: ChangePassphraseRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).changePassphrase(accountId, changePassphraseRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmailAddress(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountEmailOnlyDto>>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).getAllEmailAddress(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMeDto>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).getMe1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).login(loginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PassphraseRecoverRequest} passphraseRecoverRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverPassphrase(passphraseRecoverRequest: PassphraseRecoverRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).recoverPassphrase(passphraseRecoverRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(accountId: string, tokenRequest: TokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMeDto>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).refresh(accountId, tokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {PassphraseResetRequest} passphraseResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassphraseWithResetToken(accountId: string, passphraseResetRequest: PassphraseResetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).resetPassphraseWithResetToken(accountId, passphraseResetRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyEmailDto>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).verifyEmail(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyInviteRequest} verifyInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyInvite(accountId: string, verifyInviteRequest: VerifyInviteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyInviteResponse>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).verifyInvite(accountId, verifyInviteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} loginIpId 
         * @param {VerifyLoginIpRequest} verifyLoginIpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyLoginIp(accountId: string, loginIpId: string, verifyLoginIpRequest: VerifyLoginIpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).verifyLoginIp(accountId, loginIpId, verifyLoginIpRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyPassphraseRequest} verifyPassphraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPassphrase(accountId: string, verifyPassphraseRequest: VerifyPassphraseRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).verifyPassphrase(accountId, verifyPassphraseRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyResetTokenRequest} verifyResetTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyResetToken(accountId: string, verifyResetTokenRequest: VerifyResetTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AccountControllerApiAxiosParamCreator(configuration).verifyResetToken(accountId, verifyResetTokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {AcceptInviteRequest} acceptInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvite(accountId: string, acceptInviteRequest: AcceptInviteRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).acceptInvite(accountId, acceptInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {ChangeAccountNameRequest} changeAccountNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeName(accountId: string, changeAccountNameRequest: ChangeAccountNameRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).changeName(accountId, changeAccountNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {ChangePassphraseRequest} changePassphraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassphrase(accountId: string, changePassphraseRequest: ChangePassphraseRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).changePassphrase(accountId, changePassphraseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmailAddress(options?: any): AxiosPromise<Array<AccountEmailOnlyDto>> {
            return AccountControllerApiFp(configuration).getAllEmailAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe1(options?: any): AxiosPromise<AccountMeDto> {
            return AccountControllerApiFp(configuration).getMe1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginRequest} loginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse> {
            return AccountControllerApiFp(configuration).login(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PassphraseRecoverRequest} passphraseRecoverRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPassphrase(passphraseRecoverRequest: PassphraseRecoverRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).recoverPassphrase(passphraseRecoverRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(accountId: string, tokenRequest: TokenRequest, options?: any): AxiosPromise<AccountMeDto> {
            return AccountControllerApiFp(configuration).refresh(accountId, tokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {PassphraseResetRequest} passphraseResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassphraseWithResetToken(accountId: string, passphraseResetRequest: PassphraseResetRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).resetPassphraseWithResetToken(accountId, passphraseResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(email: string, options?: any): AxiosPromise<VerifyEmailDto> {
            return AccountControllerApiFp(configuration).verifyEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyInviteRequest} verifyInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyInvite(accountId: string, verifyInviteRequest: VerifyInviteRequest, options?: any): AxiosPromise<VerifyInviteResponse> {
            return AccountControllerApiFp(configuration).verifyInvite(accountId, verifyInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} loginIpId 
         * @param {VerifyLoginIpRequest} verifyLoginIpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyLoginIp(accountId: string, loginIpId: string, verifyLoginIpRequest: VerifyLoginIpRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).verifyLoginIp(accountId, loginIpId, verifyLoginIpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyPassphraseRequest} verifyPassphraseRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPassphrase(accountId: string, verifyPassphraseRequest: VerifyPassphraseRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).verifyPassphrase(accountId, verifyPassphraseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {VerifyResetTokenRequest} verifyResetTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyResetToken(accountId: string, verifyResetTokenRequest: VerifyResetTokenRequest, options?: any): AxiosPromise<void> {
            return AccountControllerApiFp(configuration).verifyResetToken(accountId, verifyResetTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} accountId 
     * @param {AcceptInviteRequest} acceptInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public acceptInvite(accountId: string, acceptInviteRequest: AcceptInviteRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).acceptInvite(accountId, acceptInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {ChangeAccountNameRequest} changeAccountNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public changeName(accountId: string, changeAccountNameRequest: ChangeAccountNameRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).changeName(accountId, changeAccountNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {ChangePassphraseRequest} changePassphraseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public changePassphrase(accountId: string, changePassphraseRequest: ChangePassphraseRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).changePassphrase(accountId, changePassphraseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public getAllEmailAddress(options?: any) {
        return AccountControllerApiFp(this.configuration).getAllEmailAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public getMe1(options?: any) {
        return AccountControllerApiFp(this.configuration).getMe1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginRequest} loginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public login(loginRequest: LoginRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PassphraseRecoverRequest} passphraseRecoverRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public recoverPassphrase(passphraseRecoverRequest: PassphraseRecoverRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).recoverPassphrase(passphraseRecoverRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {TokenRequest} tokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public refresh(accountId: string, tokenRequest: TokenRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).refresh(accountId, tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {PassphraseResetRequest} passphraseResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public resetPassphraseWithResetToken(accountId: string, passphraseResetRequest: PassphraseResetRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).resetPassphraseWithResetToken(accountId, passphraseResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public verifyEmail(email: string, options?: any) {
        return AccountControllerApiFp(this.configuration).verifyEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {VerifyInviteRequest} verifyInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public verifyInvite(accountId: string, verifyInviteRequest: VerifyInviteRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).verifyInvite(accountId, verifyInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {string} loginIpId 
     * @param {VerifyLoginIpRequest} verifyLoginIpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public verifyLoginIp(accountId: string, loginIpId: string, verifyLoginIpRequest: VerifyLoginIpRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).verifyLoginIp(accountId, loginIpId, verifyLoginIpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {VerifyPassphraseRequest} verifyPassphraseRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public verifyPassphrase(accountId: string, verifyPassphraseRequest: VerifyPassphraseRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).verifyPassphrase(accountId, verifyPassphraseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accountId 
     * @param {VerifyResetTokenRequest} verifyResetTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public verifyResetToken(accountId: string, verifyResetTokenRequest: VerifyResetTokenRequest, options?: any) {
        return AccountControllerApiFp(this.configuration).verifyResetToken(accountId, verifyResetTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrganizationControllerApi - axios parameter creator
 * @export
 */
export const AdminOrganizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} csvFile 
         * @param {string} dateTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterWatchList: async (csvFile: any, dateTime: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'csvFile' is not null or undefined
            if (csvFile === null || csvFile === undefined) {
                throw new RequiredError('csvFile','Required parameter csvFile was null or undefined when calling filterWatchList.');
            }
            // verify required parameter 'dateTime' is not null or undefined
            if (dateTime === null || dateTime === undefined) {
                throw new RequiredError('dateTime','Required parameter dateTime was null or undefined when calling filterWatchList.');
            }
            const localVarPath = `/api/v1/admin/organizations/wlf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
            if (dateTime !== undefined) { 
                localVarFormParams.append('dateTime', dateTime as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrganizationAmlStatusRequestBody} getOrganizationAmlStatusRequestBody 
         * @param {string} [orgId] 
         * @param {string} [performanceDateFrom] 
         * @param {string} [performanceDateTo] 
         * @param {string} [rePerformanceDateFrom] 
         * @param {string} [rePerformanceDateTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAmlStatuses: async (getOrganizationAmlStatusRequestBody: GetOrganizationAmlStatusRequestBody, orgId?: string, performanceDateFrom?: string, performanceDateTo?: string, rePerformanceDateFrom?: string, rePerformanceDateTo?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOrganizationAmlStatusRequestBody' is not null or undefined
            if (getOrganizationAmlStatusRequestBody === null || getOrganizationAmlStatusRequestBody === undefined) {
                throw new RequiredError('getOrganizationAmlStatusRequestBody','Required parameter getOrganizationAmlStatusRequestBody was null or undefined when calling getOrganizationAmlStatuses.');
            }
            const localVarPath = `/api/v1/admin/organizations/aml-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (performanceDateFrom !== undefined) {
                localVarQueryParameter['performanceDateFrom'] = (performanceDateFrom as any instanceof Date) ?
                    (performanceDateFrom as any).toISOString().substr(0,10) :
                    performanceDateFrom;
            }

            if (performanceDateTo !== undefined) {
                localVarQueryParameter['performanceDateTo'] = (performanceDateTo as any instanceof Date) ?
                    (performanceDateTo as any).toISOString().substr(0,10) :
                    performanceDateTo;
            }

            if (rePerformanceDateFrom !== undefined) {
                localVarQueryParameter['rePerformanceDateFrom'] = (rePerformanceDateFrom as any instanceof Date) ?
                    (rePerformanceDateFrom as any).toISOString().substr(0,10) :
                    rePerformanceDateFrom;
            }

            if (rePerformanceDateTo !== undefined) {
                localVarQueryParameter['rePerformanceDateTo'] = (rePerformanceDateTo as any instanceof Date) ?
                    (rePerformanceDateTo as any).toISOString().substr(0,10) :
                    rePerformanceDateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getOrganizationAmlStatusRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getOrganizationAmlStatusRequestBody !== undefined ? getOrganizationAmlStatusRequestBody : {})
                : (getOrganizationAmlStatusRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationCreationTargets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/organizations/creation-target`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDetailInfo: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrganizationDetailInfo.');
            }
            const localVarPath = `/api/v1/admin/organizations/{orgId}/detail-info`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrganizationUpdateInfoHistoriesRequestBody} getOrganizationUpdateInfoHistoriesRequestBody 
         * @param {string} [orgId] 
         * @param {string} [changeDateFrom] 
         * @param {string} [changeDateTo] 
         * @param {string} [detail] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUpdateInfoHistories: async (getOrganizationUpdateInfoHistoriesRequestBody: GetOrganizationUpdateInfoHistoriesRequestBody, orgId?: string, changeDateFrom?: string, changeDateTo?: string, detail?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOrganizationUpdateInfoHistoriesRequestBody' is not null or undefined
            if (getOrganizationUpdateInfoHistoriesRequestBody === null || getOrganizationUpdateInfoHistoriesRequestBody === undefined) {
                throw new RequiredError('getOrganizationUpdateInfoHistoriesRequestBody','Required parameter getOrganizationUpdateInfoHistoriesRequestBody was null or undefined when calling getOrganizationUpdateInfoHistories.');
            }
            const localVarPath = `/api/v1/admin/organizations/update-info-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (changeDateFrom !== undefined) {
                localVarQueryParameter['changeDateFrom'] = (changeDateFrom as any instanceof Date) ?
                    (changeDateFrom as any).toISOString().substr(0,10) :
                    changeDateFrom;
            }

            if (changeDateTo !== undefined) {
                localVarQueryParameter['changeDateTo'] = (changeDateTo as any instanceof Date) ?
                    (changeDateTo as any).toISOString().substr(0,10) :
                    changeDateTo;
            }

            if (detail !== undefined) {
                localVarQueryParameter['detail'] = detail;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getOrganizationUpdateInfoHistoriesRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getOrganizationUpdateInfoHistoriesRequestBody !== undefined ? getOrganizationUpdateInfoHistoriesRequestBody : {})
                : (getOrganizationUpdateInfoHistoriesRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrganizationsRequestBody} getOrganizationsRequestBody 
         * @param {string} [orgId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (getOrganizationsRequestBody: GetOrganizationsRequestBody, orgId?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOrganizationsRequestBody' is not null or undefined
            if (getOrganizationsRequestBody === null || getOrganizationsRequestBody === undefined) {
                throw new RequiredError('getOrganizationsRequestBody','Required parameter getOrganizationsRequestBody was null or undefined when calling getOrganizations.');
            }
            const localVarPath = `/api/v1/admin/organizations/infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getOrganizationsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getOrganizationsRequestBody !== undefined ? getOrganizationsRequestBody : {})
                : (getOrganizationsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [organizationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations1: async (page?: number, size?: number, sort?: Array<string>, organizationName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchListFilterHistories: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/organizations/wlf-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyCorporationNumberRequest} verifyCorporationNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCorporationNumber: async (verifyCorporationNumberRequest: VerifyCorporationNumberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyCorporationNumberRequest' is not null or undefined
            if (verifyCorporationNumberRequest === null || verifyCorporationNumberRequest === undefined) {
                throw new RequiredError('verifyCorporationNumberRequest','Required parameter verifyCorporationNumberRequest was null or undefined when calling verifyCorporationNumber.');
            }
            const localVarPath = `/api/v1/admin/organizations/verify-corporation-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyCorporationNumberRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyCorporationNumberRequest !== undefined ? verifyCorporationNumberRequest : {})
                : (verifyCorporationNumberRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTaxIdentificationNumberRequest} verifyTaxIdentificationNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTaxIdentificationNumber: async (verifyTaxIdentificationNumberRequest: VerifyTaxIdentificationNumberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTaxIdentificationNumberRequest' is not null or undefined
            if (verifyTaxIdentificationNumberRequest === null || verifyTaxIdentificationNumberRequest === undefined) {
                throw new RequiredError('verifyTaxIdentificationNumberRequest','Required parameter verifyTaxIdentificationNumberRequest was null or undefined when calling verifyTaxIdentificationNumber.');
            }
            const localVarPath = `/api/v1/admin/organizations/verify-tax-identification-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyTaxIdentificationNumberRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyTaxIdentificationNumberRequest !== undefined ? verifyTaxIdentificationNumberRequest : {})
                : (verifyTaxIdentificationNumberRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrganizationControllerApi - functional programming interface
 * @export
 */
export const AdminOrganizationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} csvFile 
         * @param {string} dateTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterWatchList(csvFile: any, dateTime: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WLFHistory>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).filterWatchList(csvFile, dateTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetOrganizationAmlStatusRequestBody} getOrganizationAmlStatusRequestBody 
         * @param {string} [orgId] 
         * @param {string} [performanceDateFrom] 
         * @param {string} [performanceDateTo] 
         * @param {string} [rePerformanceDateFrom] 
         * @param {string} [rePerformanceDateTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationAmlStatuses(getOrganizationAmlStatusRequestBody: GetOrganizationAmlStatusRequestBody, orgId?: string, performanceDateFrom?: string, performanceDateTo?: string, rePerformanceDateFrom?: string, rePerformanceDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationGetOrganizationAmlStatusResponse>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getOrganizationAmlStatuses(getOrganizationAmlStatusRequestBody, orgId, performanceDateFrom, performanceDateTo, rePerformanceDateFrom, rePerformanceDateTo, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationCreationTargets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetOrganizationCreationTargetResponse>>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getOrganizationCreationTargets(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationDetailInfo(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationDetailInfoResponse>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getOrganizationDetailInfo(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetOrganizationUpdateInfoHistoriesRequestBody} getOrganizationUpdateInfoHistoriesRequestBody 
         * @param {string} [orgId] 
         * @param {string} [changeDateFrom] 
         * @param {string} [changeDateTo] 
         * @param {string} [detail] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUpdateInfoHistories(getOrganizationUpdateInfoHistoriesRequestBody: GetOrganizationUpdateInfoHistoriesRequestBody, orgId?: string, changeDateFrom?: string, changeDateTo?: string, detail?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationGetOrganizationUpdateInfoHistoryResponse>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getOrganizationUpdateInfoHistories(getOrganizationUpdateInfoHistoriesRequestBody, orgId, changeDateFrom, changeDateTo, detail, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetOrganizationsRequestBody} getOrganizationsRequestBody 
         * @param {string} [orgId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(getOrganizationsRequestBody: GetOrganizationsRequestBody, orgId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationGetOrganizationInfoResponse>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getOrganizations(getOrganizationsRequestBody, orgId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [organizationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations1(page?: number, size?: number, sort?: Array<string>, organizationName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationOrganizationDto>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getOrganizations1(page, size, sort, organizationName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWatchListFilterHistories(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterHistoryResponse>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).getWatchListFilterHistories(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifyCorporationNumberRequest} verifyCorporationNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyCorporationNumber(verifyCorporationNumberRequest: VerifyCorporationNumberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).verifyCorporationNumber(verifyCorporationNumberRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifyTaxIdentificationNumberRequest} verifyTaxIdentificationNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTaxIdentificationNumber(verifyTaxIdentificationNumberRequest: VerifyTaxIdentificationNumberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminOrganizationControllerApiAxiosParamCreator(configuration).verifyTaxIdentificationNumber(verifyTaxIdentificationNumberRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminOrganizationControllerApi - factory interface
 * @export
 */
export const AdminOrganizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {any} csvFile 
         * @param {string} dateTime 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterWatchList(csvFile: any, dateTime: string, options?: any): AxiosPromise<WLFHistory> {
            return AdminOrganizationControllerApiFp(configuration).filterWatchList(csvFile, dateTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOrganizationAmlStatusRequestBody} getOrganizationAmlStatusRequestBody 
         * @param {string} [orgId] 
         * @param {string} [performanceDateFrom] 
         * @param {string} [performanceDateTo] 
         * @param {string} [rePerformanceDateFrom] 
         * @param {string} [rePerformanceDateTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAmlStatuses(getOrganizationAmlStatusRequestBody: GetOrganizationAmlStatusRequestBody, orgId?: string, performanceDateFrom?: string, performanceDateTo?: string, rePerformanceDateFrom?: string, rePerformanceDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationGetOrganizationAmlStatusResponse> {
            return AdminOrganizationControllerApiFp(configuration).getOrganizationAmlStatuses(getOrganizationAmlStatusRequestBody, orgId, performanceDateFrom, performanceDateTo, rePerformanceDateFrom, rePerformanceDateTo, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationCreationTargets(options?: any): AxiosPromise<Array<GetOrganizationCreationTargetResponse>> {
            return AdminOrganizationControllerApiFp(configuration).getOrganizationCreationTargets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDetailInfo(orgId: string, options?: any): AxiosPromise<GetOrganizationDetailInfoResponse> {
            return AdminOrganizationControllerApiFp(configuration).getOrganizationDetailInfo(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOrganizationUpdateInfoHistoriesRequestBody} getOrganizationUpdateInfoHistoriesRequestBody 
         * @param {string} [orgId] 
         * @param {string} [changeDateFrom] 
         * @param {string} [changeDateTo] 
         * @param {string} [detail] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUpdateInfoHistories(getOrganizationUpdateInfoHistoriesRequestBody: GetOrganizationUpdateInfoHistoriesRequestBody, orgId?: string, changeDateFrom?: string, changeDateTo?: string, detail?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationGetOrganizationUpdateInfoHistoryResponse> {
            return AdminOrganizationControllerApiFp(configuration).getOrganizationUpdateInfoHistories(getOrganizationUpdateInfoHistoriesRequestBody, orgId, changeDateFrom, changeDateTo, detail, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOrganizationsRequestBody} getOrganizationsRequestBody 
         * @param {string} [orgId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(getOrganizationsRequestBody: GetOrganizationsRequestBody, orgId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationGetOrganizationInfoResponse> {
            return AdminOrganizationControllerApiFp(configuration).getOrganizations(getOrganizationsRequestBody, orgId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [organizationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations1(page?: number, size?: number, sort?: Array<string>, organizationName?: string, options?: any): AxiosPromise<PaginationOrganizationDto> {
            return AdminOrganizationControllerApiFp(configuration).getOrganizations1(page, size, sort, organizationName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchListFilterHistories(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<FilterHistoryResponse> {
            return AdminOrganizationControllerApiFp(configuration).getWatchListFilterHistories(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyCorporationNumberRequest} verifyCorporationNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCorporationNumber(verifyCorporationNumberRequest: VerifyCorporationNumberRequest, options?: any): AxiosPromise<void> {
            return AdminOrganizationControllerApiFp(configuration).verifyCorporationNumber(verifyCorporationNumberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTaxIdentificationNumberRequest} verifyTaxIdentificationNumberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTaxIdentificationNumber(verifyTaxIdentificationNumberRequest: VerifyTaxIdentificationNumberRequest, options?: any): AxiosPromise<void> {
            return AdminOrganizationControllerApiFp(configuration).verifyTaxIdentificationNumber(verifyTaxIdentificationNumberRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminOrganizationControllerApi - object-oriented interface
 * @export
 * @class AdminOrganizationControllerApi
 * @extends {BaseAPI}
 */
export class AdminOrganizationControllerApi extends BaseAPI {
    /**
     * 
     * @param {any} csvFile 
     * @param {string} dateTime 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public filterWatchList(csvFile: any, dateTime: string, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).filterWatchList(csvFile, dateTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOrganizationAmlStatusRequestBody} getOrganizationAmlStatusRequestBody 
     * @param {string} [orgId] 
     * @param {string} [performanceDateFrom] 
     * @param {string} [performanceDateTo] 
     * @param {string} [rePerformanceDateFrom] 
     * @param {string} [rePerformanceDateTo] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getOrganizationAmlStatuses(getOrganizationAmlStatusRequestBody: GetOrganizationAmlStatusRequestBody, orgId?: string, performanceDateFrom?: string, performanceDateTo?: string, rePerformanceDateFrom?: string, rePerformanceDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getOrganizationAmlStatuses(getOrganizationAmlStatusRequestBody, orgId, performanceDateFrom, performanceDateTo, rePerformanceDateFrom, rePerformanceDateTo, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getOrganizationCreationTargets(options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getOrganizationCreationTargets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getOrganizationDetailInfo(orgId: string, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getOrganizationDetailInfo(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOrganizationUpdateInfoHistoriesRequestBody} getOrganizationUpdateInfoHistoriesRequestBody 
     * @param {string} [orgId] 
     * @param {string} [changeDateFrom] 
     * @param {string} [changeDateTo] 
     * @param {string} [detail] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getOrganizationUpdateInfoHistories(getOrganizationUpdateInfoHistoriesRequestBody: GetOrganizationUpdateInfoHistoriesRequestBody, orgId?: string, changeDateFrom?: string, changeDateTo?: string, detail?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getOrganizationUpdateInfoHistories(getOrganizationUpdateInfoHistoriesRequestBody, orgId, changeDateFrom, changeDateTo, detail, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOrganizationsRequestBody} getOrganizationsRequestBody 
     * @param {string} [orgId] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getOrganizations(getOrganizationsRequestBody: GetOrganizationsRequestBody, orgId?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getOrganizations(getOrganizationsRequestBody, orgId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [organizationName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getOrganizations1(page?: number, size?: number, sort?: Array<string>, organizationName?: string, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getOrganizations1(page, size, sort, organizationName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public getWatchListFilterHistories(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).getWatchListFilterHistories(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyCorporationNumberRequest} verifyCorporationNumberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public verifyCorporationNumber(verifyCorporationNumberRequest: VerifyCorporationNumberRequest, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).verifyCorporationNumber(verifyCorporationNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTaxIdentificationNumberRequest} verifyTaxIdentificationNumberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrganizationControllerApi
     */
    public verifyTaxIdentificationNumber(verifyTaxIdentificationNumberRequest: VerifyTaxIdentificationNumberRequest, options?: any) {
        return AdminOrganizationControllerApiFp(this.configuration).verifyTaxIdentificationNumber(verifyTaxIdentificationNumberRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTransferControllerApi - axios parameter creator
 * @export
 */
export const AdminTransferControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminTransfersRequestBody} getAdminTransfersRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers1: async (getAdminTransfersRequestBody: GetAdminTransfersRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAdminTransfersRequestBody' is not null or undefined
            if (getAdminTransfersRequestBody === null || getAdminTransfersRequestBody === undefined) {
                throw new RequiredError('getAdminTransfersRequestBody','Required parameter getAdminTransfersRequestBody was null or undefined when calling getTransfers1.');
            }
            const localVarPath = `/api/v1/admin/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (coinId !== undefined) {
                localVarQueryParameter['coinId'] = coinId;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getAdminTransfersRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getAdminTransfersRequestBody !== undefined ? getAdminTransfersRequestBody : {})
                : (getAdminTransfersRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {boolean} [isHomeTax] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfersCsv1: async (orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/transfers/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (coinId !== undefined) {
                localVarQueryParameter['coinId'] = coinId;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (isHomeTax !== undefined) {
                localVarQueryParameter['isHomeTax'] = isHomeTax;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTransferControllerApi - functional programming interface
 * @export
 */
export const AdminTransferControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminTransfersRequestBody} getAdminTransfersRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfers1(getAdminTransfersRequestBody: GetAdminTransfersRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationTransferDto>> {
            const localVarAxiosArgs = await AdminTransferControllerApiAxiosParamCreator(configuration).getTransfers1(getAdminTransfersRequestBody, page, size, sort, orgId, coinId, walletId, startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {boolean} [isHomeTax] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfersCsv1(orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminTransferControllerApiAxiosParamCreator(configuration).getTransfersCsv1(orgId, coinId, walletId, isHomeTax, startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminTransferControllerApi - factory interface
 * @export
 */
export const AdminTransferControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {GetAdminTransfersRequestBody} getAdminTransfersRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers1(getAdminTransfersRequestBody: GetAdminTransfersRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PaginationTransferDto> {
            return AdminTransferControllerApiFp(configuration).getTransfers1(getAdminTransfersRequestBody, page, size, sort, orgId, coinId, walletId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {boolean} [isHomeTax] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfersCsv1(orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return AdminTransferControllerApiFp(configuration).getTransfersCsv1(orgId, coinId, walletId, isHomeTax, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTransferControllerApi - object-oriented interface
 * @export
 * @class AdminTransferControllerApi
 * @extends {BaseAPI}
 */
export class AdminTransferControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetAdminTransfersRequestBody} getAdminTransfersRequestBody 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [orgId] 
     * @param {string} [coinId] 
     * @param {string} [walletId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransferControllerApi
     */
    public getTransfers1(getAdminTransfersRequestBody: GetAdminTransfersRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options?: any) {
        return AdminTransferControllerApiFp(this.configuration).getTransfers1(getAdminTransfersRequestBody, page, size, sort, orgId, coinId, walletId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orgId] 
     * @param {string} [coinId] 
     * @param {string} [walletId] 
     * @param {boolean} [isHomeTax] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransferControllerApi
     */
    public getTransfersCsv1(orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options?: any) {
        return AdminTransferControllerApiFp(this.configuration).getTransfersCsv1(orgId, coinId, walletId, isHomeTax, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTransferV2ControllerApi - axios parameter creator
 * @export
 */
export const AdminTransferV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminTransfersV2RequestBody} getAdminTransfersV2RequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers: async (getAdminTransfersV2RequestBody: GetAdminTransfersV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAdminTransfersV2RequestBody' is not null or undefined
            if (getAdminTransfersV2RequestBody === null || getAdminTransfersV2RequestBody === undefined) {
                throw new RequiredError('getAdminTransfersV2RequestBody','Required parameter getAdminTransfersV2RequestBody was null or undefined when calling getTransfers.');
            }
            const localVarPath = `/api/v2/admin/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (coinId !== undefined) {
                localVarQueryParameter['coinId'] = coinId;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getAdminTransfersV2RequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getAdminTransfersV2RequestBody !== undefined ? getAdminTransfersV2RequestBody : {})
                : (getAdminTransfersV2RequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {boolean} [isHomeTax] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfersCsv: async (orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/admin/transfers/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (coinId !== undefined) {
                localVarQueryParameter['coinId'] = coinId;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (isHomeTax !== undefined) {
                localVarQueryParameter['isHomeTax'] = isHomeTax;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTransferV2ControllerApi - functional programming interface
 * @export
 */
export const AdminTransferV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminTransfersV2RequestBody} getAdminTransfersV2RequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfers(getAdminTransfersV2RequestBody: GetAdminTransfersV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationTransferV2Dto>> {
            const localVarAxiosArgs = await AdminTransferV2ControllerApiAxiosParamCreator(configuration).getTransfers(getAdminTransfersV2RequestBody, page, size, sort, orgId, coinId, walletId, startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {boolean} [isHomeTax] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfersCsv(orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminTransferV2ControllerApiAxiosParamCreator(configuration).getTransfersCsv(orgId, coinId, walletId, isHomeTax, startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminTransferV2ControllerApi - factory interface
 * @export
 */
export const AdminTransferV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {GetAdminTransfersV2RequestBody} getAdminTransfersV2RequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers(getAdminTransfersV2RequestBody: GetAdminTransfersV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PaginationTransferV2Dto> {
            return AdminTransferV2ControllerApiFp(configuration).getTransfers(getAdminTransfersV2RequestBody, page, size, sort, orgId, coinId, walletId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orgId] 
         * @param {string} [coinId] 
         * @param {string} [walletId] 
         * @param {boolean} [isHomeTax] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfersCsv(orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return AdminTransferV2ControllerApiFp(configuration).getTransfersCsv(orgId, coinId, walletId, isHomeTax, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTransferV2ControllerApi - object-oriented interface
 * @export
 * @class AdminTransferV2ControllerApi
 * @extends {BaseAPI}
 */
export class AdminTransferV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetAdminTransfersV2RequestBody} getAdminTransfersV2RequestBody 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [orgId] 
     * @param {string} [coinId] 
     * @param {string} [walletId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransferV2ControllerApi
     */
    public getTransfers(getAdminTransfersV2RequestBody: GetAdminTransfersV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, coinId?: string, walletId?: string, startDate?: string, endDate?: string, options?: any) {
        return AdminTransferV2ControllerApiFp(this.configuration).getTransfers(getAdminTransfersV2RequestBody, page, size, sort, orgId, coinId, walletId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orgId] 
     * @param {string} [coinId] 
     * @param {string} [walletId] 
     * @param {boolean} [isHomeTax] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTransferV2ControllerApi
     */
    public getTransfersCsv(orgId?: string, coinId?: string, walletId?: string, isHomeTax?: boolean, startDate?: string, endDate?: string, options?: any) {
        return AdminTransferV2ControllerApiFp(this.configuration).getTransfersCsv(orgId, coinId, walletId, isHomeTax, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminWalletControllerApi - axios parameter creator
 * @export
 */
export const AdminWalletControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminActiveWalletsRequestBody} getAdminActiveWalletsRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [walletName] 
         * @param {string} [walletAddress] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveWallets1: async (getAdminActiveWalletsRequestBody: GetAdminActiveWalletsRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAdminActiveWalletsRequestBody' is not null or undefined
            if (getAdminActiveWalletsRequestBody === null || getAdminActiveWalletsRequestBody === undefined) {
                throw new RequiredError('getAdminActiveWalletsRequestBody','Required parameter getAdminActiveWalletsRequestBody was null or undefined when calling getActiveWallets1.');
            }
            const localVarPath = `/api/v1/admin/wallets/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (walletName !== undefined) {
                localVarQueryParameter['walletName'] = walletName;
            }

            if (walletAddress !== undefined) {
                localVarQueryParameter['walletAddress'] = walletAddress;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getAdminActiveWalletsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getAdminActiveWalletsRequestBody !== undefined ? getAdminActiveWalletsRequestBody : {})
                : (getAdminActiveWalletsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminWalletControllerApi - functional programming interface
 * @export
 */
export const AdminWalletControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminActiveWalletsRequestBody} getAdminActiveWalletsRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [walletName] 
         * @param {string} [walletAddress] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveWallets1(getAdminActiveWalletsRequestBody: GetAdminActiveWalletsRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationGetAdminActiveWalletResponse>> {
            const localVarAxiosArgs = await AdminWalletControllerApiAxiosParamCreator(configuration).getActiveWallets1(getAdminActiveWalletsRequestBody, page, size, sort, orgId, walletName, walletAddress, startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminWalletControllerApi - factory interface
 * @export
 */
export const AdminWalletControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {GetAdminActiveWalletsRequestBody} getAdminActiveWalletsRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [walletName] 
         * @param {string} [walletAddress] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveWallets1(getAdminActiveWalletsRequestBody: GetAdminActiveWalletsRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PaginationGetAdminActiveWalletResponse> {
            return AdminWalletControllerApiFp(configuration).getActiveWallets1(getAdminActiveWalletsRequestBody, page, size, sort, orgId, walletName, walletAddress, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminWalletControllerApi - object-oriented interface
 * @export
 * @class AdminWalletControllerApi
 * @extends {BaseAPI}
 */
export class AdminWalletControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetAdminActiveWalletsRequestBody} getAdminActiveWalletsRequestBody 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [orgId] 
     * @param {string} [walletName] 
     * @param {string} [walletAddress] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWalletControllerApi
     */
    public getActiveWallets1(getAdminActiveWalletsRequestBody: GetAdminActiveWalletsRequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options?: any) {
        return AdminWalletControllerApiFp(this.configuration).getActiveWallets1(getAdminActiveWalletsRequestBody, page, size, sort, orgId, walletName, walletAddress, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminWalletV2ControllerApi - axios parameter creator
 * @export
 */
export const AdminWalletV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminActiveWalletsV2RequestBody} getAdminActiveWalletsV2RequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [walletName] 
         * @param {string} [walletAddress] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveWallets: async (getAdminActiveWalletsV2RequestBody: GetAdminActiveWalletsV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAdminActiveWalletsV2RequestBody' is not null or undefined
            if (getAdminActiveWalletsV2RequestBody === null || getAdminActiveWalletsV2RequestBody === undefined) {
                throw new RequiredError('getAdminActiveWalletsV2RequestBody','Required parameter getAdminActiveWalletsV2RequestBody was null or undefined when calling getActiveWallets.');
            }
            const localVarPath = `/api/v2/admin/wallets/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (walletName !== undefined) {
                localVarQueryParameter['walletName'] = walletName;
            }

            if (walletAddress !== undefined) {
                localVarQueryParameter['walletAddress'] = walletAddress;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getAdminActiveWalletsV2RequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getAdminActiveWalletsV2RequestBody !== undefined ? getAdminActiveWalletsV2RequestBody : {})
                : (getAdminActiveWalletsV2RequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminWalletV2ControllerApi - functional programming interface
 * @export
 */
export const AdminWalletV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetAdminActiveWalletsV2RequestBody} getAdminActiveWalletsV2RequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [walletName] 
         * @param {string} [walletAddress] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveWallets(getAdminActiveWalletsV2RequestBody: GetAdminActiveWalletsV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationGetAdminActiveWalletV2Response>> {
            const localVarAxiosArgs = await AdminWalletV2ControllerApiAxiosParamCreator(configuration).getActiveWallets(getAdminActiveWalletsV2RequestBody, page, size, sort, orgId, walletName, walletAddress, startDate, endDate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminWalletV2ControllerApi - factory interface
 * @export
 */
export const AdminWalletV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {GetAdminActiveWalletsV2RequestBody} getAdminActiveWalletsV2RequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [orgId] 
         * @param {string} [walletName] 
         * @param {string} [walletAddress] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveWallets(getAdminActiveWalletsV2RequestBody: GetAdminActiveWalletsV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<PaginationGetAdminActiveWalletV2Response> {
            return AdminWalletV2ControllerApiFp(configuration).getActiveWallets(getAdminActiveWalletsV2RequestBody, page, size, sort, orgId, walletName, walletAddress, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminWalletV2ControllerApi - object-oriented interface
 * @export
 * @class AdminWalletV2ControllerApi
 * @extends {BaseAPI}
 */
export class AdminWalletV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetAdminActiveWalletsV2RequestBody} getAdminActiveWalletsV2RequestBody 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [orgId] 
     * @param {string} [walletName] 
     * @param {string} [walletAddress] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminWalletV2ControllerApi
     */
    public getActiveWallets(getAdminActiveWalletsV2RequestBody: GetAdminActiveWalletsV2RequestBody, page?: number, size?: number, sort?: Array<string>, orgId?: string, walletName?: string, walletAddress?: string, startDate?: string, endDate?: string, options?: any) {
        return AdminWalletV2ControllerApiFp(this.configuration).getActiveWallets(getAdminActiveWalletsV2RequestBody, page, size, sort, orgId, walletName, walletAddress, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlockchainControllerApi - axios parameter creator
 * @export
 */
export const BlockchainControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedBlockchains: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/blockchain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlockchainControllerApi - functional programming interface
 * @export
 */
export const BlockchainControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupportedBlockchains(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupportedBlockchainDto>>> {
            const localVarAxiosArgs = await BlockchainControllerApiAxiosParamCreator(configuration).getSupportedBlockchains(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BlockchainControllerApi - factory interface
 * @export
 */
export const BlockchainControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedBlockchains(options?: any): AxiosPromise<Array<SupportedBlockchainDto>> {
            return BlockchainControllerApiFp(configuration).getSupportedBlockchains(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlockchainControllerApi - object-oriented interface
 * @export
 * @class BlockchainControllerApi
 * @extends {BaseAPI}
 */
export class BlockchainControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockchainControllerApi
     */
    public getSupportedBlockchains(options?: any) {
        return BlockchainControllerApiFp(this.configuration).getSupportedBlockchains(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientRequestControllerApi - axios parameter creator
 * @export
 */
export const ClientRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookCreateRequest1: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveAddressBookCreateRequest1.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling approveAddressBookCreateRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-requests/{requestId}/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookDeleteRequest1: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveAddressBookDeleteRequest1.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling approveAddressBookDeleteRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-delete-requests/{requestId}/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTransferRequest1: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveTransferRequest1.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling approveTransferRequest1.');
            }
            const localVarPath = `/api/v1/client/transfer-requests/{requestId}/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransferRequest1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling cancelTransferRequest1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling cancelTransferRequest1.');
            }
            const localVarPath = `/api/v1/client/transfer-requests/{requestId}/cancel`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWalletRequest1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling cancelWalletRequest1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling cancelWalletRequest1.');
            }
            const localVarPath = `/api/v1/client/wallet-requests/{requestId}/cancel`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookCreation1: async (createAddressBookCreationRequest: CreateAddressBookCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAddressBookCreationRequest' is not null or undefined
            if (createAddressBookCreationRequest === null || createAddressBookCreationRequest === undefined) {
                throw new RequiredError('createAddressBookCreationRequest','Required parameter createAddressBookCreationRequest was null or undefined when calling createAddressBookCreation1.');
            }
            const localVarPath = `/api/v1/client/address-book-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAddressBookCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAddressBookCreationRequest !== undefined ? createAddressBookCreationRequest : {})
                : (createAddressBookCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookDeletion1: async (createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAddressBookDeletionRequest' is not null or undefined
            if (createAddressBookDeletionRequest === null || createAddressBookDeletionRequest === undefined) {
                throw new RequiredError('createAddressBookDeletionRequest','Required parameter createAddressBookDeletionRequest was null or undefined when calling createAddressBookDeletion1.');
            }
            const localVarPath = `/api/v1/client/address-book-delete-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAddressBookDeletionRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAddressBookDeletionRequest !== undefined ? createAddressBookDeletionRequest : {})
                : (createAddressBookDeletionRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTransferRequest} createTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransfer1: async (createTransferRequest: CreateTransferRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransferRequest' is not null or undefined
            if (createTransferRequest === null || createTransferRequest === undefined) {
                throw new RequiredError('createTransferRequest','Required parameter createTransferRequest was null or undefined when calling createTransfer1.');
            }
            const localVarPath = `/api/v1/client/transfer-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createTransferRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createTransferRequest !== undefined ? createTransferRequest : {})
                : (createTransferRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWalletCreationRequest} createWalletCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletCreation1: async (createWalletCreationRequest: CreateWalletCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWalletCreationRequest' is not null or undefined
            if (createWalletCreationRequest === null || createWalletCreationRequest === undefined) {
                throw new RequiredError('createWalletCreationRequest','Required parameter createWalletCreationRequest was null or undefined when calling createWalletCreation1.');
            }
            const localVarPath = `/api/v1/client/wallet-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createWalletCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createWalletCreationRequest !== undefined ? createWalletCreationRequest : {})
                : (createWalletCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookCreateRequest1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling deleteAddressBookCreateRequest1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteAddressBookCreateRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookDeleteRequest1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling deleteAddressBookDeleteRequest1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteAddressBookDeleteRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-delete-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferRequest1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling deleteTransferRequest1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteTransferRequest1.');
            }
            const localVarPath = `/api/v1/client/transfer-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBookRequest1: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getAddressBookRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {ClientRequestStatus} [status] 
         * @param {ClientRequestType} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxRequests: async (walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client/requests/inbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {ClientRequestStatus} [status] 
         * @param {ClientRequestType} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboxRequests: async (walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client/requests/outbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {SubmittedClientRequestType} [type] 
         * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
         * @param {Array<TransferStatus>} [transferStatuses] 
         * @param {Array<WalletStatus>} [walletStatuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmittedRequests: async (walletId?: string, type?: SubmittedClientRequestType, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, walletStatuses?: Array<WalletStatus>, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client/requests/submitted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (custodyRequestStatuses) {
                localVarQueryParameter['custodyRequestStatuses'] = custodyRequestStatuses;
            }

            if (transferStatuses) {
                localVarQueryParameter['transferStatuses'] = transferStatuses;
            }

            if (walletStatuses) {
                localVarQueryParameter['walletStatuses'] = walletStatuses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferRequest1: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getTransferRequest1.');
            }
            const localVarPath = `/api/v1/client/transfer-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaspList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client/vasp-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCreationRequest1: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getWalletCreationRequest1.');
            }
            const localVarPath = `/api/v1/client/wallet-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookCreateRequest1: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling rejectAddressBookCreateRequest1.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling rejectAddressBookCreateRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-requests/{requestId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookDeleteRequest1: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling rejectAddressBookDeleteRequest1.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling rejectAddressBookDeleteRequest1.');
            }
            const localVarPath = `/api/v1/client/address-book-delete-requests/{requestId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTransferRequest1: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling rejectTransferRequest1.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling rejectTransferRequest1.');
            }
            const localVarPath = `/api/v1/client/transfer-requests/{requestId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientRequestControllerApi - functional programming interface
 * @export
 */
export const ClientRequestControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAddressBookCreateRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).approveAddressBookCreateRequest1(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAddressBookDeleteRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).approveAddressBookDeleteRequest1(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTransferRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).approveTransferRequest1(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTransferRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).cancelTransferRequest1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelWalletRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).cancelWalletRequest1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddressBookCreation1(createAddressBookCreationRequest: CreateAddressBookCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBookClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).createAddressBookCreation1(createAddressBookCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddressBookDeletion1(createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBookClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).createAddressBookDeletion1(createAddressBookDeletionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateTransferRequest} createTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransfer1(createTransferRequest: CreateTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).createTransfer1(createTransferRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateWalletCreationRequest} createWalletCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWalletCreation1(createWalletCreationRequest: CreateWalletCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).createWalletCreation1(createWalletCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddressBookCreateRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).deleteAddressBookCreateRequest1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddressBookDeleteRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).deleteAddressBookDeleteRequest1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransferRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).deleteTransferRequest1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressBookRequest1(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBookClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getAddressBookRequest1(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {ClientRequestStatus} [status] 
         * @param {ClientRequestType} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxRequests(walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationClientRequestBoxDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getInboxRequests(walletId, status, type, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {ClientRequestStatus} [status] 
         * @param {ClientRequestType} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboxRequests(walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationClientRequestBoxDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getOutboxRequests(walletId, status, type, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {SubmittedClientRequestType} [type] 
         * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
         * @param {Array<TransferStatus>} [transferStatuses] 
         * @param {Array<WalletStatus>} [walletStatuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubmittedRequests(walletId?: string, type?: SubmittedClientRequestType, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, walletStatuses?: Array<WalletStatus>, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationClientSubmittedRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getSubmittedRequests(walletId, type, custodyRequestStatuses, transferStatuses, walletStatuses, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransferRequest1(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getTransferRequest1(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVaspList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVaspListResponse>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getVaspList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCreationRequest1(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletClientRequestDto>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).getWalletCreationRequest1(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAddressBookCreateRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).rejectAddressBookCreateRequest1(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAddressBookDeleteRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).rejectAddressBookDeleteRequest1(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectTransferRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestControllerApiAxiosParamCreator(configuration).rejectTransferRequest1(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientRequestControllerApi - factory interface
 * @export
 */
export const ClientRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookCreateRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).approveAddressBookCreateRequest1(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookDeleteRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).approveAddressBookDeleteRequest1(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTransferRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).approveTransferRequest1(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransferRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).cancelTransferRequest1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWalletRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).cancelWalletRequest1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookCreation1(createAddressBookCreationRequest: CreateAddressBookCreationRequest, options?: any): AxiosPromise<AddressBookClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).createAddressBookCreation1(createAddressBookCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookDeletion1(createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options?: any): AxiosPromise<AddressBookClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).createAddressBookDeletion1(createAddressBookDeletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTransferRequest} createTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransfer1(createTransferRequest: CreateTransferRequest, options?: any): AxiosPromise<TransferClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).createTransfer1(createTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWalletCreationRequest} createWalletCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletCreation1(createWalletCreationRequest: CreateWalletCreationRequest, options?: any): AxiosPromise<WalletClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).createWalletCreation1(createWalletCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookCreateRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).deleteAddressBookCreateRequest1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookDeleteRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).deleteAddressBookDeleteRequest1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).deleteTransferRequest1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBookRequest1(requestId: string, options?: any): AxiosPromise<AddressBookClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).getAddressBookRequest1(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {ClientRequestStatus} [status] 
         * @param {ClientRequestType} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxRequests(walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationClientRequestBoxDto> {
            return ClientRequestControllerApiFp(configuration).getInboxRequests(walletId, status, type, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {ClientRequestStatus} [status] 
         * @param {ClientRequestType} [type] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboxRequests(walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationClientRequestBoxDto> {
            return ClientRequestControllerApiFp(configuration).getOutboxRequests(walletId, status, type, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [walletId] 
         * @param {SubmittedClientRequestType} [type] 
         * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
         * @param {Array<TransferStatus>} [transferStatuses] 
         * @param {Array<WalletStatus>} [walletStatuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubmittedRequests(walletId?: string, type?: SubmittedClientRequestType, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, walletStatuses?: Array<WalletStatus>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationClientSubmittedRequestDto> {
            return ClientRequestControllerApiFp(configuration).getSubmittedRequests(walletId, type, custodyRequestStatuses, transferStatuses, walletStatuses, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferRequest1(requestId: string, options?: any): AxiosPromise<TransferClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).getTransferRequest1(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVaspList(options?: any): AxiosPromise<GetVaspListResponse> {
            return ClientRequestControllerApiFp(configuration).getVaspList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCreationRequest1(requestId: string, options?: any): AxiosPromise<WalletClientRequestDto> {
            return ClientRequestControllerApiFp(configuration).getWalletCreationRequest1(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookCreateRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).rejectAddressBookCreateRequest1(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookDeleteRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).rejectAddressBookDeleteRequest1(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTransferRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestControllerApiFp(configuration).rejectTransferRequest1(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientRequestControllerApi - object-oriented interface
 * @export
 * @class ClientRequestControllerApi
 * @extends {BaseAPI}
 */
export class ClientRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public approveAddressBookCreateRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).approveAddressBookCreateRequest1(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public approveAddressBookDeleteRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).approveAddressBookDeleteRequest1(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public approveTransferRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).approveTransferRequest1(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public cancelTransferRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).cancelTransferRequest1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public cancelWalletRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).cancelWalletRequest1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public createAddressBookCreation1(createAddressBookCreationRequest: CreateAddressBookCreationRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).createAddressBookCreation1(createAddressBookCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public createAddressBookDeletion1(createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).createAddressBookDeletion1(createAddressBookDeletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTransferRequest} createTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public createTransfer1(createTransferRequest: CreateTransferRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).createTransfer1(createTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWalletCreationRequest} createWalletCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public createWalletCreation1(createWalletCreationRequest: CreateWalletCreationRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).createWalletCreation1(createWalletCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public deleteAddressBookCreateRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).deleteAddressBookCreateRequest1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public deleteAddressBookDeleteRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).deleteAddressBookDeleteRequest1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public deleteTransferRequest1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).deleteTransferRequest1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getAddressBookRequest1(requestId: string, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getAddressBookRequest1(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [walletId] 
     * @param {ClientRequestStatus} [status] 
     * @param {ClientRequestType} [type] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getInboxRequests(walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getInboxRequests(walletId, status, type, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [walletId] 
     * @param {ClientRequestStatus} [status] 
     * @param {ClientRequestType} [type] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getOutboxRequests(walletId?: string, status?: ClientRequestStatus, type?: ClientRequestType, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getOutboxRequests(walletId, status, type, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [walletId] 
     * @param {SubmittedClientRequestType} [type] 
     * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
     * @param {Array<TransferStatus>} [transferStatuses] 
     * @param {Array<WalletStatus>} [walletStatuses] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getSubmittedRequests(walletId?: string, type?: SubmittedClientRequestType, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, walletStatuses?: Array<WalletStatus>, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getSubmittedRequests(walletId, type, custodyRequestStatuses, transferStatuses, walletStatuses, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getTransferRequest1(requestId: string, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getTransferRequest1(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getVaspList(options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getVaspList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public getWalletCreationRequest1(requestId: string, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).getWalletCreationRequest1(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public rejectAddressBookCreateRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).rejectAddressBookCreateRequest1(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public rejectAddressBookDeleteRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).rejectAddressBookDeleteRequest1(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestControllerApi
     */
    public rejectTransferRequest1(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestControllerApiFp(this.configuration).rejectTransferRequest1(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientRequestV2ControllerApi - axios parameter creator
 * @export
 */
export const ClientRequestV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookCreateRequest: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveAddressBookCreateRequest.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling approveAddressBookCreateRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-requests/{requestId}/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookDeleteRequest: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveAddressBookDeleteRequest.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling approveAddressBookDeleteRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-delete-requests/{requestId}/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTransferRequest: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveTransferRequest.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling approveTransferRequest.');
            }
            const localVarPath = `/api/v2/client/transfer-requests/{requestId}/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransferRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling cancelTransferRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling cancelTransferRequest.');
            }
            const localVarPath = `/api/v2/client/transfer-requests/{requestId}/cancel`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWalletRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling cancelWalletRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling cancelWalletRequest.');
            }
            const localVarPath = `/api/v2/client/wallet-requests/{requestId}/cancel`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookCreation: async (createAddressBookCreationRequest: CreateAddressBookCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAddressBookCreationRequest' is not null or undefined
            if (createAddressBookCreationRequest === null || createAddressBookCreationRequest === undefined) {
                throw new RequiredError('createAddressBookCreationRequest','Required parameter createAddressBookCreationRequest was null or undefined when calling createAddressBookCreation.');
            }
            const localVarPath = `/api/v2/client/address-book-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAddressBookCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAddressBookCreationRequest !== undefined ? createAddressBookCreationRequest : {})
                : (createAddressBookCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookDeletion: async (createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAddressBookDeletionRequest' is not null or undefined
            if (createAddressBookDeletionRequest === null || createAddressBookDeletionRequest === undefined) {
                throw new RequiredError('createAddressBookDeletionRequest','Required parameter createAddressBookDeletionRequest was null or undefined when calling createAddressBookDeletion.');
            }
            const localVarPath = `/api/v2/client/address-book-delete-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAddressBookDeletionRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAddressBookDeletionRequest !== undefined ? createAddressBookDeletionRequest : {})
                : (createAddressBookDeletionRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTransferRequest} createTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransfer: async (createTransferRequest: CreateTransferRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTransferRequest' is not null or undefined
            if (createTransferRequest === null || createTransferRequest === undefined) {
                throw new RequiredError('createTransferRequest','Required parameter createTransferRequest was null or undefined when calling createTransfer.');
            }
            const localVarPath = `/api/v2/client/transfer-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createTransferRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createTransferRequest !== undefined ? createTransferRequest : {})
                : (createTransferRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWalletCreationRequest} createWalletCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletCreation: async (createWalletCreationRequest: CreateWalletCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWalletCreationRequest' is not null or undefined
            if (createWalletCreationRequest === null || createWalletCreationRequest === undefined) {
                throw new RequiredError('createWalletCreationRequest','Required parameter createWalletCreationRequest was null or undefined when calling createWalletCreation.');
            }
            const localVarPath = `/api/v2/client/wallet-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createWalletCreationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createWalletCreationRequest !== undefined ? createWalletCreationRequest : {})
                : (createWalletCreationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookCreateRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling deleteAddressBookCreateRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteAddressBookCreateRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookDeleteRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling deleteAddressBookDeleteRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteAddressBookDeleteRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-delete-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling deleteTransferRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteTransferRequest.');
            }
            const localVarPath = `/api/v2/client/transfer-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBookRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getAddressBookRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getTransferRequest.');
            }
            const localVarPath = `/api/v2/client/transfer-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCreationRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getWalletCreationRequest.');
            }
            const localVarPath = `/api/v2/client/wallet-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookCreateRequest: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling rejectAddressBookCreateRequest.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling rejectAddressBookCreateRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-requests/{requestId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookDeleteRequest: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling rejectAddressBookDeleteRequest.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling rejectAddressBookDeleteRequest.');
            }
            const localVarPath = `/api/v2/client/address-book-delete-requests/{requestId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTransferRequest: async (requestId: string, approvalRequest: ApprovalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling rejectTransferRequest.');
            }
            // verify required parameter 'approvalRequest' is not null or undefined
            if (approvalRequest === null || approvalRequest === undefined) {
                throw new RequiredError('approvalRequest','Required parameter approvalRequest was null or undefined when calling rejectTransferRequest.');
            }
            const localVarPath = `/api/v2/client/transfer-requests/{requestId}/reject`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approvalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approvalRequest !== undefined ? approvalRequest : {})
                : (approvalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientRequestV2ControllerApi - functional programming interface
 * @export
 */
export const ClientRequestV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAddressBookCreateRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).approveAddressBookCreateRequest(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveAddressBookDeleteRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).approveAddressBookDeleteRequest(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTransferRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).approveTransferRequest(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTransferRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).cancelTransferRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelWalletRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).cancelWalletRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddressBookCreation(createAddressBookCreationRequest: CreateAddressBookCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBookClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).createAddressBookCreation(createAddressBookCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAddressBookDeletion(createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBookClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).createAddressBookDeletion(createAddressBookDeletionRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateTransferRequest} createTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransfer(createTransferRequest: CreateTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).createTransfer(createTransferRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateWalletCreationRequest} createWalletCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWalletCreation(createWalletCreationRequest: CreateWalletCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).createWalletCreation(createWalletCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddressBookCreateRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).deleteAddressBookCreateRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddressBookDeleteRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).deleteAddressBookDeleteRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransferRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).deleteTransferRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressBookRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressBookClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).getAddressBookRequest(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransferRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).getTransferRequest(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCreationRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletClientRequestDtoV2>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).getWalletCreationRequest(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAddressBookCreateRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).rejectAddressBookCreateRequest(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAddressBookDeleteRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).rejectAddressBookDeleteRequest(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectTransferRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientRequestV2ControllerApiAxiosParamCreator(configuration).rejectTransferRequest(requestId, approvalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientRequestV2ControllerApi - factory interface
 * @export
 */
export const ClientRequestV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookCreateRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).approveAddressBookCreateRequest(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAddressBookDeleteRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).approveAddressBookDeleteRequest(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTransferRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).approveTransferRequest(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransferRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).cancelTransferRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWalletRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).cancelWalletRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookCreation(createAddressBookCreationRequest: CreateAddressBookCreationRequest, options?: any): AxiosPromise<AddressBookClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).createAddressBookCreation(createAddressBookCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAddressBookDeletion(createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options?: any): AxiosPromise<AddressBookClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).createAddressBookDeletion(createAddressBookDeletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTransferRequest} createTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransfer(createTransferRequest: CreateTransferRequest, options?: any): AxiosPromise<TransferClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).createTransfer(createTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWalletCreationRequest} createWalletCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletCreation(createWalletCreationRequest: CreateWalletCreationRequest, options?: any): AxiosPromise<WalletClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).createWalletCreation(createWalletCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookCreateRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).deleteAddressBookCreateRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressBookDeleteRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).deleteAddressBookDeleteRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransferRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).deleteTransferRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBookRequest(requestId: string, options?: any): AxiosPromise<AddressBookClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).getAddressBookRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransferRequest(requestId: string, options?: any): AxiosPromise<TransferClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).getTransferRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCreationRequest(requestId: string, options?: any): AxiosPromise<WalletClientRequestDtoV2> {
            return ClientRequestV2ControllerApiFp(configuration).getWalletCreationRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookCreateRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).rejectAddressBookCreateRequest(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAddressBookDeleteRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).rejectAddressBookDeleteRequest(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {ApprovalRequest} approvalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectTransferRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any): AxiosPromise<void> {
            return ClientRequestV2ControllerApiFp(configuration).rejectTransferRequest(requestId, approvalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientRequestV2ControllerApi - object-oriented interface
 * @export
 * @class ClientRequestV2ControllerApi
 * @extends {BaseAPI}
 */
export class ClientRequestV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public approveAddressBookCreateRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).approveAddressBookCreateRequest(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public approveAddressBookDeleteRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).approveAddressBookDeleteRequest(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public approveTransferRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).approveTransferRequest(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public cancelTransferRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).cancelTransferRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public cancelWalletRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).cancelWalletRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAddressBookCreationRequest} createAddressBookCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public createAddressBookCreation(createAddressBookCreationRequest: CreateAddressBookCreationRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).createAddressBookCreation(createAddressBookCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAddressBookDeletionRequest} createAddressBookDeletionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public createAddressBookDeletion(createAddressBookDeletionRequest: CreateAddressBookDeletionRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).createAddressBookDeletion(createAddressBookDeletionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTransferRequest} createTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public createTransfer(createTransferRequest: CreateTransferRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).createTransfer(createTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWalletCreationRequest} createWalletCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public createWalletCreation(createWalletCreationRequest: CreateWalletCreationRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).createWalletCreation(createWalletCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public deleteAddressBookCreateRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).deleteAddressBookCreateRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public deleteAddressBookDeleteRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).deleteAddressBookDeleteRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public deleteTransferRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).deleteTransferRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public getAddressBookRequest(requestId: string, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).getAddressBookRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public getTransferRequest(requestId: string, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).getTransferRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public getWalletCreationRequest(requestId: string, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).getWalletCreationRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public rejectAddressBookCreateRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).rejectAddressBookCreateRequest(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public rejectAddressBookDeleteRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).rejectAddressBookDeleteRequest(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {ApprovalRequest} approvalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientRequestV2ControllerApi
     */
    public rejectTransferRequest(requestId: string, approvalRequest: ApprovalRequest, options?: any) {
        return ClientRequestV2ControllerApiFp(this.configuration).rejectTransferRequest(requestId, approvalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientTransferControllerApi - axios parameter creator
 * @export
 */
export const ClientTransferControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfer1: async (transferId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferId' is not null or undefined
            if (transferId === null || transferId === undefined) {
                throw new RequiredError('transferId','Required parameter transferId was null or undefined when calling getTransfer1.');
            }
            const localVarPath = `/api/v1/client/transfers/{transferId}`
                .replace(`{${"transferId"}}`, encodeURIComponent(String(transferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [coinIds] 
         * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
         * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
         * @param {Array<TransferStatus>} [transferStatuses] 
         * @param {Array<TransferDetail>} [transferDetails] 
         * @param {string} [hash] 
         * @param {string} [address] 
         * @param {string} [walletId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers3: async (coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (coinIds) {
                localVarQueryParameter['coinIds'] = coinIds;
            }

            if (clientRequestStatuses) {
                localVarQueryParameter['clientRequestStatuses'] = clientRequestStatuses;
            }

            if (custodyRequestStatuses) {
                localVarQueryParameter['custodyRequestStatuses'] = custodyRequestStatuses;
            }

            if (transferStatuses) {
                localVarQueryParameter['transferStatuses'] = transferStatuses;
            }

            if (transferDetails) {
                localVarQueryParameter['transferDetails'] = transferDetails;
            }

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientTransferControllerApi - functional programming interface
 * @export
 */
export const ClientTransferControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfer1(transferId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferWithRequestDto>> {
            const localVarAxiosArgs = await ClientTransferControllerApiAxiosParamCreator(configuration).getTransfer1(transferId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [coinIds] 
         * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
         * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
         * @param {Array<TransferStatus>} [transferStatuses] 
         * @param {Array<TransferDetail>} [transferDetails] 
         * @param {string} [hash] 
         * @param {string} [address] 
         * @param {string} [walletId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfers3(coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationTransferWithRequestDto>> {
            const localVarAxiosArgs = await ClientTransferControllerApiAxiosParamCreator(configuration).getTransfers3(coinIds, clientRequestStatuses, custodyRequestStatuses, transferStatuses, transferDetails, hash, address, walletId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientTransferControllerApi - factory interface
 * @export
 */
export const ClientTransferControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfer1(transferId: string, options?: any): AxiosPromise<TransferWithRequestDto> {
            return ClientTransferControllerApiFp(configuration).getTransfer1(transferId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [coinIds] 
         * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
         * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
         * @param {Array<TransferStatus>} [transferStatuses] 
         * @param {Array<TransferDetail>} [transferDetails] 
         * @param {string} [hash] 
         * @param {string} [address] 
         * @param {string} [walletId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers3(coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationTransferWithRequestDto> {
            return ClientTransferControllerApiFp(configuration).getTransfers3(coinIds, clientRequestStatuses, custodyRequestStatuses, transferStatuses, transferDetails, hash, address, walletId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientTransferControllerApi - object-oriented interface
 * @export
 * @class ClientTransferControllerApi
 * @extends {BaseAPI}
 */
export class ClientTransferControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} transferId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientTransferControllerApi
     */
    public getTransfer1(transferId: string, options?: any) {
        return ClientTransferControllerApiFp(this.configuration).getTransfer1(transferId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [coinIds] 
     * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
     * @param {Array<CustodyRequestStatus>} [custodyRequestStatuses] 
     * @param {Array<TransferStatus>} [transferStatuses] 
     * @param {Array<TransferDetail>} [transferDetails] 
     * @param {string} [hash] 
     * @param {string} [address] 
     * @param {string} [walletId] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientTransferControllerApi
     */
    public getTransfers3(coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestStatus>, transferStatuses?: Array<TransferStatus>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ClientTransferControllerApiFp(this.configuration).getTransfers3(coinIds, clientRequestStatuses, custodyRequestStatuses, transferStatuses, transferDetails, hash, address, walletId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientTransferV2ControllerApi - axios parameter creator
 * @export
 */
export const ClientTransferV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfer: async (transferId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferId' is not null or undefined
            if (transferId === null || transferId === undefined) {
                throw new RequiredError('transferId','Required parameter transferId was null or undefined when calling getTransfer.');
            }
            const localVarPath = `/api/v2/client/transfers/{transferId}`
                .replace(`{${"transferId"}}`, encodeURIComponent(String(transferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [coinIds] 
         * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
         * @param {Array<CustodyRequestV2Status>} [custodyRequestStatuses] 
         * @param {Array<TransferV2Status>} [transferStatuses] 
         * @param {Array<TransferDetail>} [transferDetails] 
         * @param {string} [hash] 
         * @param {string} [address] 
         * @param {string} [walletId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers2: async (coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestV2Status>, transferStatuses?: Array<TransferV2Status>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/client/transfers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (coinIds) {
                localVarQueryParameter['coinIds'] = coinIds;
            }

            if (clientRequestStatuses) {
                localVarQueryParameter['clientRequestStatuses'] = clientRequestStatuses;
            }

            if (custodyRequestStatuses) {
                localVarQueryParameter['custodyRequestStatuses'] = custodyRequestStatuses;
            }

            if (transferStatuses) {
                localVarQueryParameter['transferStatuses'] = transferStatuses;
            }

            if (transferDetails) {
                localVarQueryParameter['transferDetails'] = transferDetails;
            }

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (walletId !== undefined) {
                localVarQueryParameter['walletId'] = walletId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientTransferV2ControllerApi - functional programming interface
 * @export
 */
export const ClientTransferV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfer(transferId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferV2WithRequestDto>> {
            const localVarAxiosArgs = await ClientTransferV2ControllerApiAxiosParamCreator(configuration).getTransfer(transferId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [coinIds] 
         * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
         * @param {Array<CustodyRequestV2Status>} [custodyRequestStatuses] 
         * @param {Array<TransferV2Status>} [transferStatuses] 
         * @param {Array<TransferDetail>} [transferDetails] 
         * @param {string} [hash] 
         * @param {string} [address] 
         * @param {string} [walletId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransfers2(coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestV2Status>, transferStatuses?: Array<TransferV2Status>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationTransferV2WithRequestDto>> {
            const localVarAxiosArgs = await ClientTransferV2ControllerApiAxiosParamCreator(configuration).getTransfers2(coinIds, clientRequestStatuses, custodyRequestStatuses, transferStatuses, transferDetails, hash, address, walletId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientTransferV2ControllerApi - factory interface
 * @export
 */
export const ClientTransferV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfer(transferId: string, options?: any): AxiosPromise<TransferV2WithRequestDto> {
            return ClientTransferV2ControllerApiFp(configuration).getTransfer(transferId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [coinIds] 
         * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
         * @param {Array<CustodyRequestV2Status>} [custodyRequestStatuses] 
         * @param {Array<TransferV2Status>} [transferStatuses] 
         * @param {Array<TransferDetail>} [transferDetails] 
         * @param {string} [hash] 
         * @param {string} [address] 
         * @param {string} [walletId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransfers2(coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestV2Status>, transferStatuses?: Array<TransferV2Status>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationTransferV2WithRequestDto> {
            return ClientTransferV2ControllerApiFp(configuration).getTransfers2(coinIds, clientRequestStatuses, custodyRequestStatuses, transferStatuses, transferDetails, hash, address, walletId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientTransferV2ControllerApi - object-oriented interface
 * @export
 * @class ClientTransferV2ControllerApi
 * @extends {BaseAPI}
 */
export class ClientTransferV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} transferId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientTransferV2ControllerApi
     */
    public getTransfer(transferId: string, options?: any) {
        return ClientTransferV2ControllerApiFp(this.configuration).getTransfer(transferId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [coinIds] 
     * @param {Array<ClientRequestStatus>} [clientRequestStatuses] 
     * @param {Array<CustodyRequestV2Status>} [custodyRequestStatuses] 
     * @param {Array<TransferV2Status>} [transferStatuses] 
     * @param {Array<TransferDetail>} [transferDetails] 
     * @param {string} [hash] 
     * @param {string} [address] 
     * @param {string} [walletId] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientTransferV2ControllerApi
     */
    public getTransfers2(coinIds?: Array<string>, clientRequestStatuses?: Array<ClientRequestStatus>, custodyRequestStatuses?: Array<CustodyRequestV2Status>, transferStatuses?: Array<TransferV2Status>, transferDetails?: Array<TransferDetail>, hash?: string, address?: string, walletId?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ClientTransferV2ControllerApiFp(this.configuration).getTransfers2(coinIds, clientRequestStatuses, custodyRequestStatuses, transferStatuses, transferDetails, hash, address, walletId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientWalletControllerApi - axios parameter creator
 * @export
 */
export const ClientWalletControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWalletAccountAuthority1: async (walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling changeWalletAccountAuthority1.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changeWalletAccountAuthority1.');
            }
            // verify required parameter 'changeWalletAccountRolesRequest' is not null or undefined
            if (changeWalletAccountRolesRequest === null || changeWalletAccountRolesRequest === undefined) {
                throw new RequiredError('changeWalletAccountRolesRequest','Required parameter changeWalletAccountRolesRequest was null or undefined when calling changeWalletAccountAuthority1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/accounts/{accountId}/roles`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changeWalletAccountRolesRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changeWalletAccountRolesRequest !== undefined ? changeWalletAccountRolesRequest : {})
                : (changeWalletAccountRolesRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWalletAccount1: async (walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling deleteWalletAccount1.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling deleteWalletAccount1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteWalletAccount1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/accounts/{accountId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBooks1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getAddressBooks1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/address-books`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimatedFee1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getEstimatedFee1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/estimated-fee`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWallet1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAccounts1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletAccounts1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/accounts`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAuthorities1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletAuthorities1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/authorities`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletBalances2: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletBalances2.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/balances`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/client/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeWallet1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling initializeWallet1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/initialize`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteWalletAccount1: async (walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling inviteWalletAccount1.');
            }
            // verify required parameter 'inviteWalletAccountRequest' is not null or undefined
            if (inviteWalletAccountRequest === null || inviteWalletAccountRequest === undefined) {
                throw new RequiredError('inviteWalletAccountRequest','Required parameter inviteWalletAccountRequest was null or undefined when calling inviteWalletAccount1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/accounts/invite`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inviteWalletAccountRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inviteWalletAccountRequest !== undefined ? inviteWalletAccountRequest : {})
                : (inviteWalletAccountRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookCreationApprovalPolicy1: async (walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateAddressBookCreationApprovalPolicy1.');
            }
            // verify required parameter 'updateWalletApprovalPolicyRequest' is not null or undefined
            if (updateWalletApprovalPolicyRequest === null || updateWalletApprovalPolicyRequest === undefined) {
                throw new RequiredError('updateWalletApprovalPolicyRequest','Required parameter updateWalletApprovalPolicyRequest was null or undefined when calling updateAddressBookCreationApprovalPolicy1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/address-book-creation-approval-policy`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletApprovalPolicyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletApprovalPolicyRequest !== undefined ? updateWalletApprovalPolicyRequest : {})
                : (updateWalletApprovalPolicyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookDeletionApprovalPolicy1: async (walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateAddressBookDeletionApprovalPolicy1.');
            }
            // verify required parameter 'updateWalletApprovalPolicyRequest' is not null or undefined
            if (updateWalletApprovalPolicyRequest === null || updateWalletApprovalPolicyRequest === undefined) {
                throw new RequiredError('updateWalletApprovalPolicyRequest','Required parameter updateWalletApprovalPolicyRequest was null or undefined when calling updateAddressBookDeletionApprovalPolicy1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/address-book-deletion-approval-policy`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletApprovalPolicyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletApprovalPolicyRequest !== undefined ? updateWalletApprovalPolicyRequest : {})
                : (updateWalletApprovalPolicyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferApprovalPolicy1: async (walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateTransferApprovalPolicy1.');
            }
            // verify required parameter 'updateWalletApprovalPolicyRequest' is not null or undefined
            if (updateWalletApprovalPolicyRequest === null || updateWalletApprovalPolicyRequest === undefined) {
                throw new RequiredError('updateWalletApprovalPolicyRequest','Required parameter updateWalletApprovalPolicyRequest was null or undefined when calling updateTransferApprovalPolicy1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/transfer-approval-policy`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletApprovalPolicyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletApprovalPolicyRequest !== undefined ? updateWalletApprovalPolicyRequest : {})
                : (updateWalletApprovalPolicyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletNameRequest} updateWalletNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWalletName1: async (walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateWalletName1.');
            }
            // verify required parameter 'updateWalletNameRequest' is not null or undefined
            if (updateWalletNameRequest === null || updateWalletNameRequest === undefined) {
                throw new RequiredError('updateWalletNameRequest','Required parameter updateWalletNameRequest was null or undefined when calling updateWalletName1.');
            }
            const localVarPath = `/api/v1/client/wallets/{walletId}/name`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletNameRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletNameRequest !== undefined ? updateWalletNameRequest : {})
                : (updateWalletNameRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateAddressRequest} validateAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAddress1: async (validateAddressRequest: ValidateAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateAddressRequest' is not null or undefined
            if (validateAddressRequest === null || validateAddressRequest === undefined) {
                throw new RequiredError('validateAddressRequest','Required parameter validateAddressRequest was null or undefined when calling validateAddress1.');
            }
            const localVarPath = `/api/v1/client/wallets/validate-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof validateAddressRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(validateAddressRequest !== undefined ? validateAddressRequest : {})
                : (validateAddressRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientWalletControllerApi - functional programming interface
 * @export
 */
export const ClientWalletControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeWalletAccountAuthority1(walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).changeWalletAccountAuthority1(walletId, accountId, changeWalletAccountRolesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWalletAccount1(walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).deleteWalletAccount1(walletId, accountId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressBooks1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressBookDto>>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getAddressBooks1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimatedFee1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimatedFeeDto>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getEstimatedFee1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallet1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletWithRequestDto>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getWallet1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletAccounts1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountWalletAuthorityDto>>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getWalletAccounts1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletAuthorities1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorityDto>>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getWalletAuthorities1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletBalances2(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceDto>>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getWalletBalances2(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallets1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletWithRequestDto>>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).getWallets1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeWallet1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).initializeWallet1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteWalletAccount1(walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).inviteWalletAccount1(walletId, inviteWalletAccountRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressBookCreationApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).updateAddressBookCreationApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressBookDeletionApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).updateAddressBookDeletionApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransferApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).updateTransferApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletNameRequest} updateWalletNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWalletName1(walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).updateWalletName1(walletId, updateWalletNameRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ValidateAddressRequest} validateAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAddress1(validateAddressRequest: ValidateAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletControllerApiAxiosParamCreator(configuration).validateAddress1(validateAddressRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientWalletControllerApi - factory interface
 * @export
 */
export const ClientWalletControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWalletAccountAuthority1(walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).changeWalletAccountAuthority1(walletId, accountId, changeWalletAccountRolesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWalletAccount1(walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).deleteWalletAccount1(walletId, accountId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBooks1(walletId: string, options?: any): AxiosPromise<Array<AddressBookDto>> {
            return ClientWalletControllerApiFp(configuration).getAddressBooks1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimatedFee1(walletId: string, options?: any): AxiosPromise<EstimatedFeeDto> {
            return ClientWalletControllerApiFp(configuration).getEstimatedFee1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet1(walletId: string, options?: any): AxiosPromise<WalletWithRequestDto> {
            return ClientWalletControllerApiFp(configuration).getWallet1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAccounts1(walletId: string, options?: any): AxiosPromise<Array<AccountWalletAuthorityDto>> {
            return ClientWalletControllerApiFp(configuration).getWalletAccounts1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAuthorities1(walletId: string, options?: any): AxiosPromise<Array<AuthorityDto>> {
            return ClientWalletControllerApiFp(configuration).getWalletAuthorities1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletBalances2(walletId: string, options?: any): AxiosPromise<Array<BalanceDto>> {
            return ClientWalletControllerApiFp(configuration).getWalletBalances2(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets1(options?: any): AxiosPromise<Array<WalletWithRequestDto>> {
            return ClientWalletControllerApiFp(configuration).getWallets1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeWallet1(walletId: string, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).initializeWallet1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteWalletAccount1(walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).inviteWalletAccount1(walletId, inviteWalletAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookCreationApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).updateAddressBookCreationApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookDeletionApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).updateAddressBookDeletionApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).updateTransferApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletNameRequest} updateWalletNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWalletName1(walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).updateWalletName1(walletId, updateWalletNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateAddressRequest} validateAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAddress1(validateAddressRequest: ValidateAddressRequest, options?: any): AxiosPromise<void> {
            return ClientWalletControllerApiFp(configuration).validateAddress1(validateAddressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientWalletControllerApi - object-oriented interface
 * @export
 * @class ClientWalletControllerApi
 * @extends {BaseAPI}
 */
export class ClientWalletControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} walletId 
     * @param {string} accountId 
     * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public changeWalletAccountAuthority1(walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).changeWalletAccountAuthority1(walletId, accountId, changeWalletAccountRolesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {string} accountId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public deleteWalletAccount1(walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).deleteWalletAccount1(walletId, accountId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getAddressBooks1(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getAddressBooks1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getEstimatedFee1(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getEstimatedFee1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getWallet1(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getWallet1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getWalletAccounts1(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getWalletAccounts1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getWalletAuthorities1(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getWalletAuthorities1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getWalletBalances2(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getWalletBalances2(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public getWallets1(options?: any) {
        return ClientWalletControllerApiFp(this.configuration).getWallets1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public initializeWallet1(walletId: string, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).initializeWallet1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public inviteWalletAccount1(walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).inviteWalletAccount1(walletId, inviteWalletAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public updateAddressBookCreationApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).updateAddressBookCreationApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public updateAddressBookDeletionApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).updateAddressBookDeletionApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public updateTransferApprovalPolicy1(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).updateTransferApprovalPolicy1(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletNameRequest} updateWalletNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public updateWalletName1(walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).updateWalletName1(walletId, updateWalletNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateAddressRequest} validateAddressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletControllerApi
     */
    public validateAddress1(validateAddressRequest: ValidateAddressRequest, options?: any) {
        return ClientWalletControllerApiFp(this.configuration).validateAddress1(validateAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientWalletV2ControllerApi - axios parameter creator
 * @export
 */
export const ClientWalletV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWalletAccountAuthority: async (walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling changeWalletAccountAuthority.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changeWalletAccountAuthority.');
            }
            // verify required parameter 'changeWalletAccountRolesRequest' is not null or undefined
            if (changeWalletAccountRolesRequest === null || changeWalletAccountRolesRequest === undefined) {
                throw new RequiredError('changeWalletAccountRolesRequest','Required parameter changeWalletAccountRolesRequest was null or undefined when calling changeWalletAccountAuthority.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/accounts/{accountId}/roles`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changeWalletAccountRolesRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changeWalletAccountRolesRequest !== undefined ? changeWalletAccountRolesRequest : {})
                : (changeWalletAccountRolesRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckAddressIsExistsRequest} checkAddressIsExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAddressIsExists: async (checkAddressIsExistsRequest: CheckAddressIsExistsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkAddressIsExistsRequest' is not null or undefined
            if (checkAddressIsExistsRequest === null || checkAddressIsExistsRequest === undefined) {
                throw new RequiredError('checkAddressIsExistsRequest','Required parameter checkAddressIsExistsRequest was null or undefined when calling checkAddressIsExists.');
            }
            const localVarPath = `/api/v2/client/wallets/check-address-is-exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof checkAddressIsExistsRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(checkAddressIsExistsRequest !== undefined ? checkAddressIsExistsRequest : {})
                : (checkAddressIsExistsRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWalletAccount: async (walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling deleteWalletAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling deleteWalletAccount.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling deleteWalletAccount.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/accounts/{accountId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBooks: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getAddressBooks.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/address-books`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimatedFee: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getEstimatedFee.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/estimated-fee`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWallet.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAccounts: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletAccounts.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/accounts`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAuthorities: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletAuthorities.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/authorities`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletBalances1: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletBalances1.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/balances`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/client/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeWallet: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling initializeWallet.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/initialize`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteWalletAccount: async (walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling inviteWalletAccount.');
            }
            // verify required parameter 'inviteWalletAccountRequest' is not null or undefined
            if (inviteWalletAccountRequest === null || inviteWalletAccountRequest === undefined) {
                throw new RequiredError('inviteWalletAccountRequest','Required parameter inviteWalletAccountRequest was null or undefined when calling inviteWalletAccount.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/accounts/invite`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inviteWalletAccountRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inviteWalletAccountRequest !== undefined ? inviteWalletAccountRequest : {})
                : (inviteWalletAccountRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookCreationApprovalPolicy: async (walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateAddressBookCreationApprovalPolicy.');
            }
            // verify required parameter 'updateWalletApprovalPolicyRequest' is not null or undefined
            if (updateWalletApprovalPolicyRequest === null || updateWalletApprovalPolicyRequest === undefined) {
                throw new RequiredError('updateWalletApprovalPolicyRequest','Required parameter updateWalletApprovalPolicyRequest was null or undefined when calling updateAddressBookCreationApprovalPolicy.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/address-book-creation-approval-policy`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletApprovalPolicyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletApprovalPolicyRequest !== undefined ? updateWalletApprovalPolicyRequest : {})
                : (updateWalletApprovalPolicyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookDeletionApprovalPolicy: async (walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateAddressBookDeletionApprovalPolicy.');
            }
            // verify required parameter 'updateWalletApprovalPolicyRequest' is not null or undefined
            if (updateWalletApprovalPolicyRequest === null || updateWalletApprovalPolicyRequest === undefined) {
                throw new RequiredError('updateWalletApprovalPolicyRequest','Required parameter updateWalletApprovalPolicyRequest was null or undefined when calling updateAddressBookDeletionApprovalPolicy.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/address-book-deletion-approval-policy`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletApprovalPolicyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletApprovalPolicyRequest !== undefined ? updateWalletApprovalPolicyRequest : {})
                : (updateWalletApprovalPolicyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferApprovalPolicy: async (walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateTransferApprovalPolicy.');
            }
            // verify required parameter 'updateWalletApprovalPolicyRequest' is not null or undefined
            if (updateWalletApprovalPolicyRequest === null || updateWalletApprovalPolicyRequest === undefined) {
                throw new RequiredError('updateWalletApprovalPolicyRequest','Required parameter updateWalletApprovalPolicyRequest was null or undefined when calling updateTransferApprovalPolicy.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/transfer-approval-policy`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletApprovalPolicyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletApprovalPolicyRequest !== undefined ? updateWalletApprovalPolicyRequest : {})
                : (updateWalletApprovalPolicyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletNameRequest} updateWalletNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWalletName: async (walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling updateWalletName.');
            }
            // verify required parameter 'updateWalletNameRequest' is not null or undefined
            if (updateWalletNameRequest === null || updateWalletNameRequest === undefined) {
                throw new RequiredError('updateWalletNameRequest','Required parameter updateWalletNameRequest was null or undefined when calling updateWalletName.');
            }
            const localVarPath = `/api/v2/client/wallets/{walletId}/name`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateWalletNameRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateWalletNameRequest !== undefined ? updateWalletNameRequest : {})
                : (updateWalletNameRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateAddressRequest} validateAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAddress: async (validateAddressRequest: ValidateAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateAddressRequest' is not null or undefined
            if (validateAddressRequest === null || validateAddressRequest === undefined) {
                throw new RequiredError('validateAddressRequest','Required parameter validateAddressRequest was null or undefined when calling validateAddress.');
            }
            const localVarPath = `/api/v2/client/wallets/validate-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof validateAddressRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(validateAddressRequest !== undefined ? validateAddressRequest : {})
                : (validateAddressRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientWalletV2ControllerApi - functional programming interface
 * @export
 */
export const ClientWalletV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeWalletAccountAuthority(walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).changeWalletAccountAuthority(walletId, accountId, changeWalletAccountRolesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CheckAddressIsExistsRequest} checkAddressIsExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAddressIsExists(checkAddressIsExistsRequest: CheckAddressIsExistsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).checkAddressIsExists(checkAddressIsExistsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWalletAccount(walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).deleteWalletAccount(walletId, accountId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressBooks(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressBookDto>>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getAddressBooks(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstimatedFee(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimatedFeeDto>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getEstimatedFee(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallet(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletV2WithRequestDto>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getWallet(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletAccounts(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountWalletAuthorityDto>>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getWalletAccounts(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletAuthorities(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorityDto>>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getWalletAuthorities(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletBalances1(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceDto>>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getWalletBalances1(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWallets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletV2WithRequestDto>>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).getWallets(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeWallet(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).initializeWallet(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteWalletAccount(walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).inviteWalletAccount(walletId, inviteWalletAccountRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressBookCreationApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).updateAddressBookCreationApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressBookDeletionApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).updateAddressBookDeletionApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransferApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).updateTransferApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletNameRequest} updateWalletNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWalletName(walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).updateWalletName(walletId, updateWalletNameRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ValidateAddressRequest} validateAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAddress(validateAddressRequest: ValidateAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientWalletV2ControllerApiAxiosParamCreator(configuration).validateAddress(validateAddressRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientWalletV2ControllerApi - factory interface
 * @export
 */
export const ClientWalletV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWalletAccountAuthority(walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).changeWalletAccountAuthority(walletId, accountId, changeWalletAccountRolesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckAddressIsExistsRequest} checkAddressIsExistsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAddressIsExists(checkAddressIsExistsRequest: CheckAddressIsExistsRequest, options?: any): AxiosPromise<boolean> {
            return ClientWalletV2ControllerApiFp(configuration).checkAddressIsExists(checkAddressIsExistsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} accountId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWalletAccount(walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).deleteWalletAccount(walletId, accountId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressBooks(walletId: string, options?: any): AxiosPromise<Array<AddressBookDto>> {
            return ClientWalletV2ControllerApiFp(configuration).getAddressBooks(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstimatedFee(walletId: string, options?: any): AxiosPromise<EstimatedFeeDto> {
            return ClientWalletV2ControllerApiFp(configuration).getEstimatedFee(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallet(walletId: string, options?: any): AxiosPromise<WalletV2WithRequestDto> {
            return ClientWalletV2ControllerApiFp(configuration).getWallet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAccounts(walletId: string, options?: any): AxiosPromise<Array<AccountWalletAuthorityDto>> {
            return ClientWalletV2ControllerApiFp(configuration).getWalletAccounts(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletAuthorities(walletId: string, options?: any): AxiosPromise<Array<AuthorityDto>> {
            return ClientWalletV2ControllerApiFp(configuration).getWalletAuthorities(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletBalances1(walletId: string, options?: any): AxiosPromise<Array<BalanceDto>> {
            return ClientWalletV2ControllerApiFp(configuration).getWalletBalances1(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWallets(options?: any): AxiosPromise<Array<WalletV2WithRequestDto>> {
            return ClientWalletV2ControllerApiFp(configuration).getWallets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeWallet(walletId: string, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).initializeWallet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteWalletAccount(walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).inviteWalletAccount(walletId, inviteWalletAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookCreationApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).updateAddressBookCreationApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressBookDeletionApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).updateAddressBookDeletionApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).updateTransferApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {UpdateWalletNameRequest} updateWalletNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWalletName(walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).updateWalletName(walletId, updateWalletNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateAddressRequest} validateAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAddress(validateAddressRequest: ValidateAddressRequest, options?: any): AxiosPromise<void> {
            return ClientWalletV2ControllerApiFp(configuration).validateAddress(validateAddressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientWalletV2ControllerApi - object-oriented interface
 * @export
 * @class ClientWalletV2ControllerApi
 * @extends {BaseAPI}
 */
export class ClientWalletV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} walletId 
     * @param {string} accountId 
     * @param {ChangeWalletAccountRolesRequest} changeWalletAccountRolesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public changeWalletAccountAuthority(walletId: string, accountId: string, changeWalletAccountRolesRequest: ChangeWalletAccountRolesRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).changeWalletAccountAuthority(walletId, accountId, changeWalletAccountRolesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckAddressIsExistsRequest} checkAddressIsExistsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public checkAddressIsExists(checkAddressIsExistsRequest: CheckAddressIsExistsRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).checkAddressIsExists(checkAddressIsExistsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {string} accountId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public deleteWalletAccount(walletId: string, accountId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).deleteWalletAccount(walletId, accountId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getAddressBooks(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getAddressBooks(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getEstimatedFee(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getEstimatedFee(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getWallet(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getWallet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getWalletAccounts(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getWalletAccounts(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getWalletAuthorities(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getWalletAuthorities(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getWalletBalances1(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getWalletBalances1(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public getWallets(options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).getWallets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public initializeWallet(walletId: string, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).initializeWallet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {InviteWalletAccountRequest} inviteWalletAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public inviteWalletAccount(walletId: string, inviteWalletAccountRequest: InviteWalletAccountRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).inviteWalletAccount(walletId, inviteWalletAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public updateAddressBookCreationApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).updateAddressBookCreationApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public updateAddressBookDeletionApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).updateAddressBookDeletionApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletApprovalPolicyRequest} updateWalletApprovalPolicyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public updateTransferApprovalPolicy(walletId: string, updateWalletApprovalPolicyRequest: UpdateWalletApprovalPolicyRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).updateTransferApprovalPolicy(walletId, updateWalletApprovalPolicyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {UpdateWalletNameRequest} updateWalletNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public updateWalletName(walletId: string, updateWalletNameRequest: UpdateWalletNameRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).updateWalletName(walletId, updateWalletNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateAddressRequest} validateAddressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientWalletV2ControllerApi
     */
    public validateAddress(validateAddressRequest: ValidateAddressRequest, options?: any) {
        return ClientWalletV2ControllerApiFp(this.configuration).validateAddress(validateAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoinControllerApi - axios parameter creator
 * @export
 */
export const CoinControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCoinWithBlockchainAndAddressRegistered1: async (blockchain: string, address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling checkCoinWithBlockchainAndAddressRegistered1.');
            }
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling checkCoinWithBlockchainAndAddressRegistered1.');
            }
            const localVarPath = `/api/v1/coins/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (blockchain !== undefined) {
                localVarQueryParameter['blockchain'] = blockchain;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} coinId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoin1: async (coinId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coinId' is not null or undefined
            if (coinId === null || coinId === undefined) {
                throw new RequiredError('coinId','Required parameter coinId was null or undefined when calling getCoin1.');
            }
            const localVarPath = `/api/v1/coins/{coinId}`
                .replace(`{${"coinId"}}`, encodeURIComponent(String(coinId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isRegistered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoins1: async (isRegistered?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/coins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isRegistered !== undefined) {
                localVarQueryParameter['isRegistered'] = isRegistered;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoinControllerApi - functional programming interface
 * @export
 */
export const CoinControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCoinWithBlockchainAndAddressRegistered1(blockchain: string, address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await CoinControllerApiAxiosParamCreator(configuration).checkCoinWithBlockchainAndAddressRegistered1(blockchain, address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} coinId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoin1(coinId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinDto>> {
            const localVarAxiosArgs = await CoinControllerApiAxiosParamCreator(configuration).getCoin1(coinId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [isRegistered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoins1(isRegistered?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoinDto>>> {
            const localVarAxiosArgs = await CoinControllerApiAxiosParamCreator(configuration).getCoins1(isRegistered, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CoinControllerApi - factory interface
 * @export
 */
export const CoinControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCoinWithBlockchainAndAddressRegistered1(blockchain: string, address: string, options?: any): AxiosPromise<boolean> {
            return CoinControllerApiFp(configuration).checkCoinWithBlockchainAndAddressRegistered1(blockchain, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} coinId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoin1(coinId: string, options?: any): AxiosPromise<CoinDto> {
            return CoinControllerApiFp(configuration).getCoin1(coinId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isRegistered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoins1(isRegistered?: boolean, options?: any): AxiosPromise<Array<CoinDto>> {
            return CoinControllerApiFp(configuration).getCoins1(isRegistered, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoinControllerApi - object-oriented interface
 * @export
 * @class CoinControllerApi
 * @extends {BaseAPI}
 */
export class CoinControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} blockchain 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinControllerApi
     */
    public checkCoinWithBlockchainAndAddressRegistered1(blockchain: string, address: string, options?: any) {
        return CoinControllerApiFp(this.configuration).checkCoinWithBlockchainAndAddressRegistered1(blockchain, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} coinId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinControllerApi
     */
    public getCoin1(coinId: string, options?: any) {
        return CoinControllerApiFp(this.configuration).getCoin1(coinId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isRegistered] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinControllerApi
     */
    public getCoins1(isRegistered?: boolean, options?: any) {
        return CoinControllerApiFp(this.configuration).getCoins1(isRegistered, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoinRegistrationControllerApi - axios parameter creator
 * @export
 */
export const CoinRegistrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCoinRegistration: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveCoinRegistration.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling approveCoinRegistration.');
            }
            const localVarPath = `/api/v1/custody/coin-registration/{requestId}/approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCoinRegristrationRequest} createCoinRegristrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoinRegistration: async (createCoinRegristrationRequest: CreateCoinRegristrationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCoinRegristrationRequest' is not null or undefined
            if (createCoinRegristrationRequest === null || createCoinRegristrationRequest === undefined) {
                throw new RequiredError('createCoinRegristrationRequest','Required parameter createCoinRegristrationRequest was null or undefined when calling createCoinRegistration.');
            }
            const localVarPath = `/api/v1/custody/coin-registration/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createCoinRegristrationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createCoinRegristrationRequest !== undefined ? createCoinRegristrationRequest : {})
                : (createCoinRegristrationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveCoinRegistration: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling finalApproveCoinRegistration.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling finalApproveCoinRegistration.');
            }
            const localVarPath = `/api/v1/custody/coin-registration/{requestId}/final-approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCoinRegistrationRequestsRequestBody} getCoinRegistrationRequestsRequestBody 
         * @param {string} [status] 
         * @param {Array<string>} [statuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoinRegistrationRequests: async (getCoinRegistrationRequestsRequestBody: GetCoinRegistrationRequestsRequestBody, status?: string, statuses?: Array<string>, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCoinRegistrationRequestsRequestBody' is not null or undefined
            if (getCoinRegistrationRequestsRequestBody === null || getCoinRegistrationRequestsRequestBody === undefined) {
                throw new RequiredError('getCoinRegistrationRequestsRequestBody','Required parameter getCoinRegistrationRequestsRequestBody was null or undefined when calling getCoinRegistrationRequests.');
            }
            const localVarPath = `/api/v1/custody/coin-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getCoinRegistrationRequestsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getCoinRegistrationRequestsRequestBody !== undefined ? getCoinRegistrationRequestsRequestBody : {})
                : (getCoinRegistrationRequestsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoinRegistrationControllerApi - functional programming interface
 * @export
 */
export const CoinRegistrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveCoinRegistration(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinRegistrationRequestDto>> {
            const localVarAxiosArgs = await CoinRegistrationControllerApiAxiosParamCreator(configuration).approveCoinRegistration(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateCoinRegristrationRequest} createCoinRegristrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCoinRegistration(createCoinRegristrationRequest: CreateCoinRegristrationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinRegistrationRequestDto>> {
            const localVarAxiosArgs = await CoinRegistrationControllerApiAxiosParamCreator(configuration).createCoinRegistration(createCoinRegristrationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalApproveCoinRegistration(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinRegistrationRequestDto>> {
            const localVarAxiosArgs = await CoinRegistrationControllerApiAxiosParamCreator(configuration).finalApproveCoinRegistration(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetCoinRegistrationRequestsRequestBody} getCoinRegistrationRequestsRequestBody 
         * @param {string} [status] 
         * @param {Array<string>} [statuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoinRegistrationRequests(getCoinRegistrationRequestsRequestBody: GetCoinRegistrationRequestsRequestBody, status?: string, statuses?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationCoinRegistrationRequestDto>> {
            const localVarAxiosArgs = await CoinRegistrationControllerApiAxiosParamCreator(configuration).getCoinRegistrationRequests(getCoinRegistrationRequestsRequestBody, status, statuses, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CoinRegistrationControllerApi - factory interface
 * @export
 */
export const CoinRegistrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCoinRegistration(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<CoinRegistrationRequestDto> {
            return CoinRegistrationControllerApiFp(configuration).approveCoinRegistration(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCoinRegristrationRequest} createCoinRegristrationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCoinRegistration(createCoinRegristrationRequest: CreateCoinRegristrationRequest, options?: any): AxiosPromise<CoinRegistrationRequestDto> {
            return CoinRegistrationControllerApiFp(configuration).createCoinRegistration(createCoinRegristrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveCoinRegistration(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<CoinRegistrationRequestDto> {
            return CoinRegistrationControllerApiFp(configuration).finalApproveCoinRegistration(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCoinRegistrationRequestsRequestBody} getCoinRegistrationRequestsRequestBody 
         * @param {string} [status] 
         * @param {Array<string>} [statuses] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoinRegistrationRequests(getCoinRegistrationRequestsRequestBody: GetCoinRegistrationRequestsRequestBody, status?: string, statuses?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationCoinRegistrationRequestDto> {
            return CoinRegistrationControllerApiFp(configuration).getCoinRegistrationRequests(getCoinRegistrationRequestsRequestBody, status, statuses, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoinRegistrationControllerApi - object-oriented interface
 * @export
 * @class CoinRegistrationControllerApi
 * @extends {BaseAPI}
 */
export class CoinRegistrationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinRegistrationControllerApi
     */
    public approveCoinRegistration(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CoinRegistrationControllerApiFp(this.configuration).approveCoinRegistration(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCoinRegristrationRequest} createCoinRegristrationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinRegistrationControllerApi
     */
    public createCoinRegistration(createCoinRegristrationRequest: CreateCoinRegristrationRequest, options?: any) {
        return CoinRegistrationControllerApiFp(this.configuration).createCoinRegistration(createCoinRegristrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinRegistrationControllerApi
     */
    public finalApproveCoinRegistration(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CoinRegistrationControllerApiFp(this.configuration).finalApproveCoinRegistration(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCoinRegistrationRequestsRequestBody} getCoinRegistrationRequestsRequestBody 
     * @param {string} [status] 
     * @param {Array<string>} [statuses] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinRegistrationControllerApi
     */
    public getCoinRegistrationRequests(getCoinRegistrationRequestsRequestBody: GetCoinRegistrationRequestsRequestBody, status?: string, statuses?: Array<string>, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CoinRegistrationControllerApiFp(this.configuration).getCoinRegistrationRequests(getCoinRegistrationRequestsRequestBody, status, statuses, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoinV2ControllerApi - axios parameter creator
 * @export
 */
export const CoinV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCoinWithBlockchainAndAddressRegistered: async (blockchain: string, address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling checkCoinWithBlockchainAndAddressRegistered.');
            }
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling checkCoinWithBlockchainAndAddressRegistered.');
            }
            const localVarPath = `/api/v2/coins/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (blockchain !== undefined) {
                localVarQueryParameter['blockchain'] = blockchain;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} coinId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoin: async (coinId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'coinId' is not null or undefined
            if (coinId === null || coinId === undefined) {
                throw new RequiredError('coinId','Required parameter coinId was null or undefined when calling getCoin.');
            }
            const localVarPath = `/api/v2/coins/{coinId}`
                .replace(`{${"coinId"}}`, encodeURIComponent(String(coinId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isRegistered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoins: async (isRegistered?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v2/coins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isRegistered !== undefined) {
                localVarQueryParameter['isRegistered'] = isRegistered;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoinV2ControllerApi - functional programming interface
 * @export
 */
export const CoinV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCoinWithBlockchainAndAddressRegistered(blockchain: string, address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await CoinV2ControllerApiAxiosParamCreator(configuration).checkCoinWithBlockchainAndAddressRegistered(blockchain, address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} coinId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoin(coinId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinDto>> {
            const localVarAxiosArgs = await CoinV2ControllerApiAxiosParamCreator(configuration).getCoin(coinId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [isRegistered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoins(isRegistered?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoinDto>>> {
            const localVarAxiosArgs = await CoinV2ControllerApiAxiosParamCreator(configuration).getCoins(isRegistered, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CoinV2ControllerApi - factory interface
 * @export
 */
export const CoinV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCoinWithBlockchainAndAddressRegistered(blockchain: string, address: string, options?: any): AxiosPromise<boolean> {
            return CoinV2ControllerApiFp(configuration).checkCoinWithBlockchainAndAddressRegistered(blockchain, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} coinId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoin(coinId: string, options?: any): AxiosPromise<CoinDto> {
            return CoinV2ControllerApiFp(configuration).getCoin(coinId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isRegistered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoins(isRegistered?: boolean, options?: any): AxiosPromise<Array<CoinDto>> {
            return CoinV2ControllerApiFp(configuration).getCoins(isRegistered, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoinV2ControllerApi - object-oriented interface
 * @export
 * @class CoinV2ControllerApi
 * @extends {BaseAPI}
 */
export class CoinV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} blockchain 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinV2ControllerApi
     */
    public checkCoinWithBlockchainAndAddressRegistered(blockchain: string, address: string, options?: any) {
        return CoinV2ControllerApiFp(this.configuration).checkCoinWithBlockchainAndAddressRegistered(blockchain, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} coinId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinV2ControllerApi
     */
    public getCoin(coinId: string, options?: any) {
        return CoinV2ControllerApiFp(this.configuration).getCoin(coinId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isRegistered] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoinV2ControllerApi
     */
    public getCoins(isRegistered?: boolean, options?: any) {
        return CoinV2ControllerApiFp(this.configuration).getCoins(isRegistered, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustodyRequestControllerApi - axios parameter creator
 * @export
 */
export const CustodyRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrganizationManagementRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveOrganizationManagementRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling approveOrganizationManagementRequest.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/{requestId}/approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWallet1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveWallet1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling approveWallet1.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}/approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWithdrawal1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveWithdrawal1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling approveWithdrawal1.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}/approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWalletSigning: async (requestId: string, qrCodeDto: QrCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling confirmWalletSigning.');
            }
            // verify required parameter 'qrCodeDto' is not null or undefined
            if (qrCodeDto === null || qrCodeDto === undefined) {
                throw new RequiredError('qrCodeDto','Required parameter qrCodeDto was null or undefined when calling confirmWalletSigning.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}/signing/confirm`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof qrCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(qrCodeDto !== undefined ? qrCodeDto : {})
                : (qrCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalSigning: async (requestId: string, qrCodeDto: QrCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling confirmWithdrawalSigning.');
            }
            // verify required parameter 'qrCodeDto' is not null or undefined
            if (qrCodeDto === null || qrCodeDto === undefined) {
                throw new RequiredError('qrCodeDto','Required parameter qrCodeDto was null or undefined when calling confirmWithdrawalSigning.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}/signing/confirm`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof qrCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(qrCodeDto !== undefined ? qrCodeDto : {})
                : (qrCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling createWalletSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling createWalletSigning.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}/signing/create`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling createWithdrawalSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling createWithdrawalSigning.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}/signing/create`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveOrganizationManagementRequest: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling finalApproveOrganizationManagementRequest.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling finalApproveOrganizationManagementRequest.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/{requestId}/final-approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWallet1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling finalApproveWallet1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling finalApproveWallet1.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}/final-approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWithdrawal1: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling finalApproveWithdrawal1.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling finalApproveWithdrawal1.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}/final-approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/custody/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalances1: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getCustodyBalances1.');
            }
            const localVarPath = `/api/v1/custody/balances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalancesCsv1: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getCustodyBalancesCsv1.');
            }
            const localVarPath = `/api/v1/custody/balances/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedOrganizationManagementRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getDetailedOrganizationManagementRequest.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrganizationManagementRequestsRequestBody} getOrganizationManagementRequestsRequestBody 
         * @param {CustodyNonSigningRequestStatus} [status] 
         * @param {Array<CustodyNonSigningRequestStatus>} [statuses] 
         * @param {string} [orgId] 
         * @param {Array<Detail>} [details] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationManagementRequests: async (getOrganizationManagementRequestsRequestBody: GetOrganizationManagementRequestsRequestBody, status?: CustodyNonSigningRequestStatus, statuses?: Array<CustodyNonSigningRequestStatus>, orgId?: string, details?: Array<Detail>, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOrganizationManagementRequestsRequestBody' is not null or undefined
            if (getOrganizationManagementRequestsRequestBody === null || getOrganizationManagementRequestsRequestBody === undefined) {
                throw new RequiredError('getOrganizationManagementRequestsRequestBody','Required parameter getOrganizationManagementRequestsRequestBody was null or undefined when calling getOrganizationManagementRequests.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (details) {
                localVarQueryParameter['details'] = details;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getOrganizationManagementRequestsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getOrganizationManagementRequestsRequestBody !== undefined ? getOrganizationManagementRequestsRequestBody : {})
                : (getOrganizationManagementRequestsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequest1: async (requestId: string, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getWalletCustodyRequest1.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetWalletCustodyRequestsRequestBody} getWalletCustodyRequestsRequestBody 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequests1: async (getWalletCustodyRequestsRequestBody: GetWalletCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getWalletCustodyRequestsRequestBody' is not null or undefined
            if (getWalletCustodyRequestsRequestBody === null || getWalletCustodyRequestsRequestBody === undefined) {
                throw new RequiredError('getWalletCustodyRequestsRequestBody','Required parameter getWalletCustodyRequestsRequestBody was null or undefined when calling getWalletCustodyRequests1.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getWalletCustodyRequestsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getWalletCustodyRequestsRequestBody !== undefined ? getWalletCustodyRequestsRequestBody : {})
                : (getWalletCustodyRequestsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequest1: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getWithdrawalCustodyRequest1.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetWithdrawalCustodyRequestsRequestBody} getWithdrawalCustodyRequestsRequestBody 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequests1: async (getWithdrawalCustodyRequestsRequestBody: GetWithdrawalCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getWithdrawalCustodyRequestsRequestBody' is not null or undefined
            if (getWithdrawalCustodyRequestsRequestBody === null || getWithdrawalCustodyRequestsRequestBody === undefined) {
                throw new RequiredError('getWithdrawalCustodyRequestsRequestBody','Required parameter getWithdrawalCustodyRequestsRequestBody was null or undefined when calling getWithdrawalCustodyRequests1.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getWithdrawalCustodyRequestsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getWithdrawalCustodyRequestsRequestBody !== undefined ? getWithdrawalCustodyRequestsRequestBody : {})
                : (getWithdrawalCustodyRequestsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWalletSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling recreateWalletSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling recreateWalletSigning.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}/signing/recreate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWithdrawalSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling recreateWithdrawalSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling recreateWithdrawalSigning.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}/signing/recreate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteOrganizationRequest} deleteOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestOrganizationDeletion: async (deleteOrganizationRequest: DeleteOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteOrganizationRequest' is not null or undefined
            if (deleteOrganizationRequest === null || deleteOrganizationRequest === undefined) {
                throw new RequiredError('deleteOrganizationRequest','Required parameter deleteOrganizationRequest was null or undefined when calling requestOrganizationDeletion.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteOrganizationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteOrganizationRequest !== undefined ? deleteOrganizationRequest : {})
                : (deleteOrganizationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterOrganizationRequest} registerOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestOrganizationRegistration: async (registerOrganizationRequest: RegisterOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerOrganizationRequest' is not null or undefined
            if (registerOrganizationRequest === null || registerOrganizationRequest === undefined) {
                throw new RequiredError('registerOrganizationRequest','Required parameter registerOrganizationRequest was null or undefined when calling requestOrganizationRegistration.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof registerOrganizationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(registerOrganizationRequest !== undefined ? registerOrganizationRequest : {})
                : (registerOrganizationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationAmlStatusRequest} updateOrganizationAmlStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateOrganizationAmlStatus: async (updateOrganizationAmlStatusRequest: UpdateOrganizationAmlStatusRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationAmlStatusRequest' is not null or undefined
            if (updateOrganizationAmlStatusRequest === null || updateOrganizationAmlStatusRequest === undefined) {
                throw new RequiredError('updateOrganizationAmlStatusRequest','Required parameter updateOrganizationAmlStatusRequest was null or undefined when calling requestUpdateOrganizationAmlStatus.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/update-aml-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateOrganizationAmlStatusRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateOrganizationAmlStatusRequest !== undefined ? updateOrganizationAmlStatusRequest : {})
                : (updateOrganizationAmlStatusRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationCustodyFeeRequest} updateOrganizationCustodyFeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateOrganizationCustodyFee: async (updateOrganizationCustodyFeeRequest: UpdateOrganizationCustodyFeeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationCustodyFeeRequest' is not null or undefined
            if (updateOrganizationCustodyFeeRequest === null || updateOrganizationCustodyFeeRequest === undefined) {
                throw new RequiredError('updateOrganizationCustodyFeeRequest','Required parameter updateOrganizationCustodyFeeRequest was null or undefined when calling requestUpdateOrganizationCustodyFee.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/update-custody-fee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateOrganizationCustodyFeeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateOrganizationCustodyFeeRequest !== undefined ? updateOrganizationCustodyFeeRequest : {})
                : (updateOrganizationCustodyFeeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationDetailRequest} updateOrganizationDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateOrganizationDetail: async (updateOrganizationDetailRequest: UpdateOrganizationDetailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationDetailRequest' is not null or undefined
            if (updateOrganizationDetailRequest === null || updateOrganizationDetailRequest === undefined) {
                throw new RequiredError('updateOrganizationDetailRequest','Required parameter updateOrganizationDetailRequest was null or undefined when calling requestUpdateOrganizationDetail.');
            }
            const localVarPath = `/api/v1/custody/organization-management-requests/update-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateOrganizationDetailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateOrganizationDetailRequest !== undefined ? updateOrganizationDetailRequest : {})
                : (updateOrganizationDetailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWalletRequestSigning1: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling validateWalletRequestSigning1.');
            }
            const localVarPath = `/api/v1/custody/wallet-requests/{requestId}/signing/validate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWithdrawalRequestSigning1: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling validateWithdrawalRequestSigning1.');
            }
            const localVarPath = `/api/v1/custody/withdrawal-requests/{requestId}/signing/validate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustodyRequestControllerApi - functional programming interface
 * @export
 */
export const CustodyRequestControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveOrganizationManagementRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).approveOrganizationManagementRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveWallet1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWalletCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).approveWallet1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveWithdrawal1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWithdrawalCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).approveWithdrawal1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWalletSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).confirmWalletSigning(requestId, qrCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWithdrawalSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).confirmWithdrawalSigning(requestId, qrCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWalletSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).createWalletSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdrawalSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).createWithdrawalSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalApproveOrganizationManagementRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).finalApproveOrganizationManagementRequest(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalApproveWallet1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWalletCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).finalApproveWallet1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalApproveWithdrawal1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWithdrawalCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).finalApproveWithdrawal1(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getCountries(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustodyBalances1(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleBalanceDto>>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getCustodyBalances1(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustodyBalancesCsv1(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getCustodyBalancesCsv1(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetailedOrganizationManagementRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedOrganizationManagementCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getDetailedOrganizationManagementRequest(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetOrganizationManagementRequestsRequestBody} getOrganizationManagementRequestsRequestBody 
         * @param {CustodyNonSigningRequestStatus} [status] 
         * @param {Array<CustodyNonSigningRequestStatus>} [statuses] 
         * @param {string} [orgId] 
         * @param {Array<Detail>} [details] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationManagementRequests(getOrganizationManagementRequestsRequestBody: GetOrganizationManagementRequestsRequestBody, status?: CustodyNonSigningRequestStatus, statuses?: Array<CustodyNonSigningRequestStatus>, orgId?: string, details?: Array<Detail>, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationOrganizationManagementCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getOrganizationManagementRequests(getOrganizationManagementRequestsRequestBody, status, statuses, orgId, details, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCustodyRequest1(requestId: string, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWalletCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getWalletCustodyRequest1(requestId, status, statuses, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetWalletCustodyRequestsRequestBody} getWalletCustodyRequestsRequestBody 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCustodyRequests1(getWalletCustodyRequestsRequestBody: GetWalletCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationWalletCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getWalletCustodyRequests1(getWalletCustodyRequestsRequestBody, status, statuses, search, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawalCustodyRequest1(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWithdrawalCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getWithdrawalCustodyRequest1(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetWithdrawalCustodyRequestsRequestBody} getWithdrawalCustodyRequestsRequestBody 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawalCustodyRequests1(getWithdrawalCustodyRequestsRequestBody: GetWithdrawalCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationWithdrawalCustodyRequestDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).getWithdrawalCustodyRequests1(getWithdrawalCustodyRequestsRequestBody, status, statuses, search, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateWalletSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).recreateWalletSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateWithdrawalSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).recreateWithdrawalSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DeleteOrganizationRequest} deleteOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestOrganizationDeletion(deleteOrganizationRequest: DeleteOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).requestOrganizationDeletion(deleteOrganizationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterOrganizationRequest} registerOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestOrganizationRegistration(registerOrganizationRequest: RegisterOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).requestOrganizationRegistration(registerOrganizationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateOrganizationAmlStatusRequest} updateOrganizationAmlStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestUpdateOrganizationAmlStatus(updateOrganizationAmlStatusRequest: UpdateOrganizationAmlStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).requestUpdateOrganizationAmlStatus(updateOrganizationAmlStatusRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateOrganizationCustodyFeeRequest} updateOrganizationCustodyFeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestUpdateOrganizationCustodyFee(updateOrganizationCustodyFeeRequest: UpdateOrganizationCustodyFeeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).requestUpdateOrganizationCustodyFee(updateOrganizationCustodyFeeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateOrganizationDetailRequest} updateOrganizationDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestUpdateOrganizationDetail(updateOrganizationDetailRequest: UpdateOrganizationDetailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).requestUpdateOrganizationDetail(updateOrganizationDetailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWalletRequestSigning1(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).validateWalletRequestSigning1(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWithdrawalRequestSigning1(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestControllerApiAxiosParamCreator(configuration).validateWithdrawalRequestSigning1(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustodyRequestControllerApi - factory interface
 * @export
 */
export const CustodyRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrganizationManagementRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).approveOrganizationManagementRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWallet1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWalletCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).approveWallet1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWithdrawal1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWithdrawalCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).approveWithdrawal1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWalletSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).confirmWalletSigning(requestId, qrCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).confirmWithdrawalSigning(requestId, qrCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestControllerApiFp(configuration).createWalletSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestControllerApiFp(configuration).createWithdrawalSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveOrganizationManagementRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).finalApproveOrganizationManagementRequest(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWallet1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWalletCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).finalApproveWallet1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWithdrawal1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWithdrawalCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).finalApproveWithdrawal1(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<Array<Country>> {
            return CustodyRequestControllerApiFp(configuration).getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalances1(date: string, options?: any): AxiosPromise<Array<SimpleBalanceDto>> {
            return CustodyRequestControllerApiFp(configuration).getCustodyBalances1(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalancesCsv1(date: string, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).getCustodyBalancesCsv1(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetailedOrganizationManagementRequest(requestId: string, options?: any): AxiosPromise<DetailedOrganizationManagementCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).getDetailedOrganizationManagementRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOrganizationManagementRequestsRequestBody} getOrganizationManagementRequestsRequestBody 
         * @param {CustodyNonSigningRequestStatus} [status] 
         * @param {Array<CustodyNonSigningRequestStatus>} [statuses] 
         * @param {string} [orgId] 
         * @param {Array<Detail>} [details] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationManagementRequests(getOrganizationManagementRequestsRequestBody: GetOrganizationManagementRequestsRequestBody, status?: CustodyNonSigningRequestStatus, statuses?: Array<CustodyNonSigningRequestStatus>, orgId?: string, details?: Array<Detail>, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationOrganizationManagementCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).getOrganizationManagementRequests(getOrganizationManagementRequestsRequestBody, status, statuses, orgId, details, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequest1(requestId: string, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, options?: any): AxiosPromise<DetailedWalletCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).getWalletCustodyRequest1(requestId, status, statuses, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetWalletCustodyRequestsRequestBody} getWalletCustodyRequestsRequestBody 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequests1(getWalletCustodyRequestsRequestBody: GetWalletCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationWalletCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).getWalletCustodyRequests1(getWalletCustodyRequestsRequestBody, status, statuses, search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequest1(requestId: string, options?: any): AxiosPromise<DetailedWithdrawalCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).getWithdrawalCustodyRequest1(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetWithdrawalCustodyRequestsRequestBody} getWithdrawalCustodyRequestsRequestBody 
         * @param {CustodyRequestStatus} [status] 
         * @param {Array<CustodyRequestStatus>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequests1(getWithdrawalCustodyRequestsRequestBody: GetWithdrawalCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationWithdrawalCustodyRequestDto> {
            return CustodyRequestControllerApiFp(configuration).getWithdrawalCustodyRequests1(getWithdrawalCustodyRequestsRequestBody, status, statuses, search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWalletSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestControllerApiFp(configuration).recreateWalletSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWithdrawalSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestControllerApiFp(configuration).recreateWithdrawalSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteOrganizationRequest} deleteOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestOrganizationDeletion(deleteOrganizationRequest: DeleteOrganizationRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).requestOrganizationDeletion(deleteOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterOrganizationRequest} registerOrganizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestOrganizationRegistration(registerOrganizationRequest: RegisterOrganizationRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).requestOrganizationRegistration(registerOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationAmlStatusRequest} updateOrganizationAmlStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateOrganizationAmlStatus(updateOrganizationAmlStatusRequest: UpdateOrganizationAmlStatusRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).requestUpdateOrganizationAmlStatus(updateOrganizationAmlStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationCustodyFeeRequest} updateOrganizationCustodyFeeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateOrganizationCustodyFee(updateOrganizationCustodyFeeRequest: UpdateOrganizationCustodyFeeRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).requestUpdateOrganizationCustodyFee(updateOrganizationCustodyFeeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationDetailRequest} updateOrganizationDetailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestUpdateOrganizationDetail(updateOrganizationDetailRequest: UpdateOrganizationDetailRequest, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).requestUpdateOrganizationDetail(updateOrganizationDetailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWalletRequestSigning1(requestId: string, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).validateWalletRequestSigning1(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWithdrawalRequestSigning1(requestId: string, options?: any): AxiosPromise<void> {
            return CustodyRequestControllerApiFp(configuration).validateWithdrawalRequestSigning1(requestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustodyRequestControllerApi - object-oriented interface
 * @export
 * @class CustodyRequestControllerApi
 * @extends {BaseAPI}
 */
export class CustodyRequestControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public approveOrganizationManagementRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).approveOrganizationManagementRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public approveWallet1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).approveWallet1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public approveWithdrawal1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).approveWithdrawal1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {QrCodeDto} qrCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public confirmWalletSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).confirmWalletSigning(requestId, qrCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {QrCodeDto} qrCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public confirmWithdrawalSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).confirmWithdrawalSigning(requestId, qrCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public createWalletSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).createWalletSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public createWithdrawalSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).createWithdrawalSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public finalApproveOrganizationManagementRequest(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).finalApproveOrganizationManagementRequest(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public finalApproveWallet1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).finalApproveWallet1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public finalApproveWithdrawal1(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).finalApproveWithdrawal1(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getCountries(options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getCustodyBalances1(date: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getCustodyBalances1(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getCustodyBalancesCsv1(date: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getCustodyBalancesCsv1(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getDetailedOrganizationManagementRequest(requestId: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getDetailedOrganizationManagementRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOrganizationManagementRequestsRequestBody} getOrganizationManagementRequestsRequestBody 
     * @param {CustodyNonSigningRequestStatus} [status] 
     * @param {Array<CustodyNonSigningRequestStatus>} [statuses] 
     * @param {string} [orgId] 
     * @param {Array<Detail>} [details] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getOrganizationManagementRequests(getOrganizationManagementRequestsRequestBody: GetOrganizationManagementRequestsRequestBody, status?: CustodyNonSigningRequestStatus, statuses?: Array<CustodyNonSigningRequestStatus>, orgId?: string, details?: Array<Detail>, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getOrganizationManagementRequests(getOrganizationManagementRequestsRequestBody, status, statuses, orgId, details, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {CustodyRequestStatus} [status] 
     * @param {Array<CustodyRequestStatus>} [statuses] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getWalletCustodyRequest1(requestId: string, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getWalletCustodyRequest1(requestId, status, statuses, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetWalletCustodyRequestsRequestBody} getWalletCustodyRequestsRequestBody 
     * @param {CustodyRequestStatus} [status] 
     * @param {Array<CustodyRequestStatus>} [statuses] 
     * @param {string} [search] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getWalletCustodyRequests1(getWalletCustodyRequestsRequestBody: GetWalletCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getWalletCustodyRequests1(getWalletCustodyRequestsRequestBody, status, statuses, search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getWithdrawalCustodyRequest1(requestId: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getWithdrawalCustodyRequest1(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetWithdrawalCustodyRequestsRequestBody} getWithdrawalCustodyRequestsRequestBody 
     * @param {CustodyRequestStatus} [status] 
     * @param {Array<CustodyRequestStatus>} [statuses] 
     * @param {string} [search] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public getWithdrawalCustodyRequests1(getWithdrawalCustodyRequestsRequestBody: GetWithdrawalCustodyRequestsRequestBody, status?: CustodyRequestStatus, statuses?: Array<CustodyRequestStatus>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).getWithdrawalCustodyRequests1(getWithdrawalCustodyRequestsRequestBody, status, statuses, search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public recreateWalletSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).recreateWalletSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public recreateWithdrawalSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).recreateWithdrawalSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteOrganizationRequest} deleteOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public requestOrganizationDeletion(deleteOrganizationRequest: DeleteOrganizationRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).requestOrganizationDeletion(deleteOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterOrganizationRequest} registerOrganizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public requestOrganizationRegistration(registerOrganizationRequest: RegisterOrganizationRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).requestOrganizationRegistration(registerOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationAmlStatusRequest} updateOrganizationAmlStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public requestUpdateOrganizationAmlStatus(updateOrganizationAmlStatusRequest: UpdateOrganizationAmlStatusRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).requestUpdateOrganizationAmlStatus(updateOrganizationAmlStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationCustodyFeeRequest} updateOrganizationCustodyFeeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public requestUpdateOrganizationCustodyFee(updateOrganizationCustodyFeeRequest: UpdateOrganizationCustodyFeeRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).requestUpdateOrganizationCustodyFee(updateOrganizationCustodyFeeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationDetailRequest} updateOrganizationDetailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public requestUpdateOrganizationDetail(updateOrganizationDetailRequest: UpdateOrganizationDetailRequest, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).requestUpdateOrganizationDetail(updateOrganizationDetailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public validateWalletRequestSigning1(requestId: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).validateWalletRequestSigning1(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestControllerApi
     */
    public validateWithdrawalRequestSigning1(requestId: string, options?: any) {
        return CustodyRequestControllerApiFp(this.configuration).validateWithdrawalRequestSigning1(requestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustodyRequestV2ControllerApi - axios parameter creator
 * @export
 */
export const CustodyRequestV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWallet: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveWallet.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling approveWallet.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWithdrawal: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling approveWithdrawal.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling approveWithdrawal.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWalletCommitSigning: async (requestId: string, qrCodeDto: QrCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling confirmWalletCommitSigning.');
            }
            // verify required parameter 'qrCodeDto' is not null or undefined
            if (qrCodeDto === null || qrCodeDto === undefined) {
                throw new RequiredError('qrCodeDto','Required parameter qrCodeDto was null or undefined when calling confirmWalletCommitSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/commit-signing/confirm`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof qrCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(qrCodeDto !== undefined ? qrCodeDto : {})
                : (qrCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWalletConfirmSigning: async (requestId: string, qrCodeDto: QrCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling confirmWalletConfirmSigning.');
            }
            // verify required parameter 'qrCodeDto' is not null or undefined
            if (qrCodeDto === null || qrCodeDto === undefined) {
                throw new RequiredError('qrCodeDto','Required parameter qrCodeDto was null or undefined when calling confirmWalletConfirmSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/confirm-signing/confirm`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof qrCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(qrCodeDto !== undefined ? qrCodeDto : {})
                : (qrCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalCommitSigning: async (requestId: string, qrCodeDto: QrCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling confirmWithdrawalCommitSigning.');
            }
            // verify required parameter 'qrCodeDto' is not null or undefined
            if (qrCodeDto === null || qrCodeDto === undefined) {
                throw new RequiredError('qrCodeDto','Required parameter qrCodeDto was null or undefined when calling confirmWithdrawalCommitSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/commit-signing/confirm`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof qrCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(qrCodeDto !== undefined ? qrCodeDto : {})
                : (qrCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalConfirmSigning: async (requestId: string, qrCodeDto: QrCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling confirmWithdrawalConfirmSigning.');
            }
            // verify required parameter 'qrCodeDto' is not null or undefined
            if (qrCodeDto === null || qrCodeDto === undefined) {
                throw new RequiredError('qrCodeDto','Required parameter qrCodeDto was null or undefined when calling confirmWithdrawalConfirmSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/confirm-signing/confirm`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof qrCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(qrCodeDto !== undefined ? qrCodeDto : {})
                : (qrCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletCommitSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling createWalletCommitSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling createWalletCommitSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/commit-signing/create`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletConfirmSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling createWalletConfirmSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling createWalletConfirmSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/confirm-signing/create`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalCommitSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling createWithdrawalCommitSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling createWithdrawalCommitSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/commit-signing/create`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalConfirmSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling createWithdrawalConfirmSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling createWithdrawalConfirmSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/confirm-signing/create`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWallet: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling finalApproveWallet.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling finalApproveWallet.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/final-approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWithdrawal: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling finalApproveWithdrawal.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling finalApproveWithdrawal.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/final-approval/approve`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalances: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getCustodyBalances.');
            }
            const localVarPath = `/api/v2/custody/balances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalancesCsv: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getCustodyBalancesCsv.');
            }
            const localVarPath = `/api/v2/custody/balances/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getWalletCustodyRequest.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetWalletCustodyRequestsV2RequestBody} getWalletCustodyRequestsV2RequestBody 
         * @param {CustodyRequestV2Status} [status] 
         * @param {Array<CustodyRequestV2Status>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequests: async (getWalletCustodyRequestsV2RequestBody: GetWalletCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getWalletCustodyRequestsV2RequestBody' is not null or undefined
            if (getWalletCustodyRequestsV2RequestBody === null || getWalletCustodyRequestsV2RequestBody === undefined) {
                throw new RequiredError('getWalletCustodyRequestsV2RequestBody','Required parameter getWalletCustodyRequestsV2RequestBody was null or undefined when calling getWalletCustodyRequests.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getWalletCustodyRequestsV2RequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getWalletCustodyRequestsV2RequestBody !== undefined ? getWalletCustodyRequestsV2RequestBody : {})
                : (getWalletCustodyRequestsV2RequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequest: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling getWithdrawalCustodyRequest.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetWithdrawalCustodyRequestsV2RequestBody} getWithdrawalCustodyRequestsV2RequestBody 
         * @param {CustodyRequestV2Status} [status] 
         * @param {Array<CustodyRequestV2Status>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequests: async (getWithdrawalCustodyRequestsV2RequestBody: GetWithdrawalCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getWithdrawalCustodyRequestsV2RequestBody' is not null or undefined
            if (getWithdrawalCustodyRequestsV2RequestBody === null || getWithdrawalCustodyRequestsV2RequestBody === undefined) {
                throw new RequiredError('getWithdrawalCustodyRequestsV2RequestBody','Required parameter getWithdrawalCustodyRequestsV2RequestBody was null or undefined when calling getWithdrawalCustodyRequests.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getWithdrawalCustodyRequestsV2RequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getWithdrawalCustodyRequestsV2RequestBody !== undefined ? getWithdrawalCustodyRequestsV2RequestBody : {})
                : (getWithdrawalCustodyRequestsV2RequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWalletCommitSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling recreateWalletCommitSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling recreateWalletCommitSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/commit-signing/recreate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWalletConfirmSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling recreateWalletConfirmSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling recreateWalletConfirmSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/confirm-signing/recreate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWithdrawalCommitSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling recreateWithdrawalCommitSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling recreateWithdrawalCommitSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/commit-signing/recreate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWithdrawalConfirmSigning: async (requestId: string, authenticateRequest: AuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling recreateWithdrawalConfirmSigning.');
            }
            // verify required parameter 'authenticateRequest' is not null or undefined
            if (authenticateRequest === null || authenticateRequest === undefined) {
                throw new RequiredError('authenticateRequest','Required parameter authenticateRequest was null or undefined when calling recreateWithdrawalConfirmSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/confirm-signing/recreate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof authenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(authenticateRequest !== undefined ? authenticateRequest : {})
                : (authenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWalletRequestSigning: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling validateWalletRequestSigning.');
            }
            const localVarPath = `/api/v2/custody/wallet-requests/{requestId}/signing/validate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWithdrawalRequestSigning: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new RequiredError('requestId','Required parameter requestId was null or undefined when calling validateWithdrawalRequestSigning.');
            }
            const localVarPath = `/api/v2/custody/withdrawal-requests/{requestId}/signing/validate`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustodyRequestV2ControllerApi - functional programming interface
 * @export
 */
export const CustodyRequestV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveWallet(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWalletCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).approveWallet(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveWithdrawal(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWithdrawalCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).approveWithdrawal(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWalletCommitSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).confirmWalletCommitSigning(requestId, qrCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWalletConfirmSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).confirmWalletConfirmSigning(requestId, qrCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWithdrawalCommitSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).confirmWithdrawalCommitSigning(requestId, qrCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmWithdrawalConfirmSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).confirmWithdrawalConfirmSigning(requestId, qrCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWalletCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).createWalletCommitSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWalletConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).createWalletConfirmSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdrawalCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).createWithdrawalCommitSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdrawalConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).createWithdrawalConfirmSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalApproveWallet(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWalletCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).finalApproveWallet(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalApproveWithdrawal(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWithdrawalCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).finalApproveWithdrawal(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustodyBalances(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleBalanceDto>>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).getCustodyBalances(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustodyBalancesCsv(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).getCustodyBalancesCsv(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCustodyRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWalletCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).getWalletCustodyRequest(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetWalletCustodyRequestsV2RequestBody} getWalletCustodyRequestsV2RequestBody 
         * @param {CustodyRequestV2Status} [status] 
         * @param {Array<CustodyRequestV2Status>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletCustodyRequests(getWalletCustodyRequestsV2RequestBody: GetWalletCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationWalletCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).getWalletCustodyRequests(getWalletCustodyRequestsV2RequestBody, status, statuses, search, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawalCustodyRequest(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedWithdrawalCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).getWithdrawalCustodyRequest(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetWithdrawalCustodyRequestsV2RequestBody} getWithdrawalCustodyRequestsV2RequestBody 
         * @param {CustodyRequestV2Status} [status] 
         * @param {Array<CustodyRequestV2Status>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawalCustodyRequests(getWithdrawalCustodyRequestsV2RequestBody: GetWithdrawalCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationWithdrawalCustodyRequestV2Dto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).getWithdrawalCustodyRequests(getWithdrawalCustodyRequestsV2RequestBody, status, statuses, search, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateWalletCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).recreateWalletCommitSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateWalletConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).recreateWalletConfirmSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateWithdrawalCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).recreateWithdrawalCommitSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateWithdrawalConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QrCodeDto>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).recreateWithdrawalConfirmSigning(requestId, authenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWalletRequestSigning(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).validateWalletRequestSigning(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWithdrawalRequestSigning(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustodyRequestV2ControllerApiAxiosParamCreator(configuration).validateWithdrawalRequestSigning(requestId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustodyRequestV2ControllerApi - factory interface
 * @export
 */
export const CustodyRequestV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWallet(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWalletCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).approveWallet(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveWithdrawal(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWithdrawalCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).approveWithdrawal(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWalletCommitSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).confirmWalletCommitSigning(requestId, qrCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWalletConfirmSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).confirmWalletConfirmSigning(requestId, qrCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalCommitSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).confirmWithdrawalCommitSigning(requestId, qrCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {QrCodeDto} qrCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmWithdrawalConfirmSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).confirmWithdrawalConfirmSigning(requestId, qrCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).createWalletCommitSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).createWalletConfirmSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).createWithdrawalCommitSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).createWithdrawalConfirmSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWallet(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWalletCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).finalApproveWallet(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalApproveWithdrawal(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<DetailedWithdrawalCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).finalApproveWithdrawal(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalances(date: string, options?: any): AxiosPromise<Array<SimpleBalanceDto>> {
            return CustodyRequestV2ControllerApiFp(configuration).getCustodyBalances(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustodyBalancesCsv(date: string, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).getCustodyBalancesCsv(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequest(requestId: string, options?: any): AxiosPromise<DetailedWalletCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).getWalletCustodyRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetWalletCustodyRequestsV2RequestBody} getWalletCustodyRequestsV2RequestBody 
         * @param {CustodyRequestV2Status} [status] 
         * @param {Array<CustodyRequestV2Status>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletCustodyRequests(getWalletCustodyRequestsV2RequestBody: GetWalletCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationWalletCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).getWalletCustodyRequests(getWalletCustodyRequestsV2RequestBody, status, statuses, search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequest(requestId: string, options?: any): AxiosPromise<DetailedWithdrawalCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).getWithdrawalCustodyRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetWithdrawalCustodyRequestsV2RequestBody} getWithdrawalCustodyRequestsV2RequestBody 
         * @param {CustodyRequestV2Status} [status] 
         * @param {Array<CustodyRequestV2Status>} [statuses] 
         * @param {string} [search] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawalCustodyRequests(getWithdrawalCustodyRequestsV2RequestBody: GetWithdrawalCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationWithdrawalCustodyRequestV2Dto> {
            return CustodyRequestV2ControllerApiFp(configuration).getWithdrawalCustodyRequests(getWithdrawalCustodyRequestsV2RequestBody, status, statuses, search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWalletCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).recreateWalletCommitSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWalletConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).recreateWalletConfirmSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWithdrawalCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).recreateWithdrawalCommitSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {AuthenticateRequest} authenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateWithdrawalConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any): AxiosPromise<QrCodeDto> {
            return CustodyRequestV2ControllerApiFp(configuration).recreateWithdrawalConfirmSigning(requestId, authenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWalletRequestSigning(requestId: string, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).validateWalletRequestSigning(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWithdrawalRequestSigning(requestId: string, options?: any): AxiosPromise<void> {
            return CustodyRequestV2ControllerApiFp(configuration).validateWithdrawalRequestSigning(requestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustodyRequestV2ControllerApi - object-oriented interface
 * @export
 * @class CustodyRequestV2ControllerApi
 * @extends {BaseAPI}
 */
export class CustodyRequestV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public approveWallet(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).approveWallet(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public approveWithdrawal(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).approveWithdrawal(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {QrCodeDto} qrCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public confirmWalletCommitSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).confirmWalletCommitSigning(requestId, qrCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {QrCodeDto} qrCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public confirmWalletConfirmSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).confirmWalletConfirmSigning(requestId, qrCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {QrCodeDto} qrCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public confirmWithdrawalCommitSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).confirmWithdrawalCommitSigning(requestId, qrCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {QrCodeDto} qrCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public confirmWithdrawalConfirmSigning(requestId: string, qrCodeDto: QrCodeDto, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).confirmWithdrawalConfirmSigning(requestId, qrCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public createWalletCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).createWalletCommitSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public createWalletConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).createWalletConfirmSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public createWithdrawalCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).createWithdrawalCommitSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public createWithdrawalConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).createWithdrawalConfirmSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public finalApproveWallet(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).finalApproveWallet(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public finalApproveWithdrawal(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).finalApproveWithdrawal(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public getCustodyBalances(date: string, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).getCustodyBalances(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public getCustodyBalancesCsv(date: string, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).getCustodyBalancesCsv(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public getWalletCustodyRequest(requestId: string, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).getWalletCustodyRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetWalletCustodyRequestsV2RequestBody} getWalletCustodyRequestsV2RequestBody 
     * @param {CustodyRequestV2Status} [status] 
     * @param {Array<CustodyRequestV2Status>} [statuses] 
     * @param {string} [search] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public getWalletCustodyRequests(getWalletCustodyRequestsV2RequestBody: GetWalletCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).getWalletCustodyRequests(getWalletCustodyRequestsV2RequestBody, status, statuses, search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public getWithdrawalCustodyRequest(requestId: string, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).getWithdrawalCustodyRequest(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetWithdrawalCustodyRequestsV2RequestBody} getWithdrawalCustodyRequestsV2RequestBody 
     * @param {CustodyRequestV2Status} [status] 
     * @param {Array<CustodyRequestV2Status>} [statuses] 
     * @param {string} [search] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public getWithdrawalCustodyRequests(getWithdrawalCustodyRequestsV2RequestBody: GetWithdrawalCustodyRequestsV2RequestBody, status?: CustodyRequestV2Status, statuses?: Array<CustodyRequestV2Status>, search?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).getWithdrawalCustodyRequests(getWithdrawalCustodyRequestsV2RequestBody, status, statuses, search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public recreateWalletCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).recreateWalletCommitSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public recreateWalletConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).recreateWalletConfirmSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public recreateWithdrawalCommitSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).recreateWithdrawalCommitSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {AuthenticateRequest} authenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public recreateWithdrawalConfirmSigning(requestId: string, authenticateRequest: AuthenticateRequest, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).recreateWithdrawalConfirmSigning(requestId, authenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public validateWalletRequestSigning(requestId: string, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).validateWalletRequestSigning(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustodyRequestV2ControllerApi
     */
    public validateWithdrawalRequestSigning(requestId: string, options?: any) {
        return CustodyRequestV2ControllerApiFp(this.configuration).validateWithdrawalRequestSigning(requestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalV2ControllerApi - axios parameter creator
 * @export
 */
export const InternalV2ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTransferConfirmed: async (transferId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferId' is not null or undefined
            if (transferId === null || transferId === undefined) {
                throw new RequiredError('transferId','Required parameter transferId was null or undefined when calling checkTransferConfirmed.');
            }
            const localVarPath = `/internal/api/v2/transfers/{transferId}`
                .replace(`{${"transferId"}}`, encodeURIComponent(String(transferId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (orgName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgName' is not null or undefined
            if (orgName === null || orgName === undefined) {
                throw new RequiredError('orgName','Required parameter orgName was null or undefined when calling createOrganization.');
            }
            const localVarPath = `/internal/api/v2/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgName !== undefined) {
                localVarQueryParameter['orgName'] = orgName;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {string} orgId 
         * @param {string} walletName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWallet: async (blockchain: string, orgId: string, walletName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling createWallet.');
            }
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling createWallet.');
            }
            // verify required parameter 'walletName' is not null or undefined
            if (walletName === null || walletName === undefined) {
                throw new RequiredError('walletName','Required parameter walletName was null or undefined when calling createWallet.');
            }
            const localVarPath = `/internal/api/v2/wallets/{blockchain}`
                .replace(`{${"blockchain"}}`, encodeURIComponent(String(blockchain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (walletName !== undefined) {
                localVarQueryParameter['walletName'] = walletName;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} symbol 
         * @param {string} to 
         * @param {string} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawal: async (walletId: string, symbol: string, to: string, amount: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling createWithdrawal.');
            }
            // verify required parameter 'symbol' is not null or undefined
            if (symbol === null || symbol === undefined) {
                throw new RequiredError('symbol','Required parameter symbol was null or undefined when calling createWithdrawal.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createWithdrawal.');
            }
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling createWithdrawal.');
            }
            const localVarPath = `/internal/api/v2/wallets/{walletId}/withdraw`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWallets: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getAllWallets.');
            }
            const localVarPath = `/internal/api/v2/organizations/{orgId}/wallets`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxWithdrawalAMount: async (walletId: string, symbol: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getMaxWithdrawalAMount.');
            }
            // verify required parameter 'symbol' is not null or undefined
            if (symbol === null || symbol === undefined) {
                throw new RequiredError('symbol','Required parameter symbol was null or undefined when calling getMaxWithdrawalAMount.');
            }
            const localVarPath = `/internal/api/v2/wallets/{walletId}/max-withdrawal-amount`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletBalances: async (walletId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            if (walletId === null || walletId === undefined) {
                throw new RequiredError('walletId','Required parameter walletId was null or undefined when calling getWalletBalances.');
            }
            const localVarPath = `/internal/api/v2/wallets/{walletId}/balances`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalV2ControllerApi - functional programming interface
 * @export
 */
export const InternalV2ControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkTransferConfirmed(transferId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).checkTransferConfirmed(transferId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(orgName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).createOrganization(orgName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {string} orgId 
         * @param {string} walletName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWallet(blockchain: string, orgId: string, walletName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).createWallet(blockchain, orgId, walletName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} symbol 
         * @param {string} to 
         * @param {string} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWithdrawal(walletId: string, symbol: string, to: string, amount: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).createWithdrawal(walletId, symbol, to, amount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWallets(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseWalletV2Dto>>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).getAllWallets(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaxWithdrawalAMount(walletId: string, symbol: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).getMaxWithdrawalAMount(walletId, symbol, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletBalances(walletId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BalanceDto>>> {
            const localVarAxiosArgs = await InternalV2ControllerApiAxiosParamCreator(configuration).getWalletBalances(walletId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InternalV2ControllerApi - factory interface
 * @export
 */
export const InternalV2ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} transferId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkTransferConfirmed(transferId: string, options?: any): AxiosPromise<boolean> {
            return InternalV2ControllerApiFp(configuration).checkTransferConfirmed(transferId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(orgName: string, options?: any): AxiosPromise<string> {
            return InternalV2ControllerApiFp(configuration).createOrganization(orgName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {string} orgId 
         * @param {string} walletName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWallet(blockchain: string, orgId: string, walletName: string, options?: any): AxiosPromise<string> {
            return InternalV2ControllerApiFp(configuration).createWallet(blockchain, orgId, walletName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} symbol 
         * @param {string} to 
         * @param {string} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawal(walletId: string, symbol: string, to: string, amount: string, options?: any): AxiosPromise<string> {
            return InternalV2ControllerApiFp(configuration).createWithdrawal(walletId, symbol, to, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWallets(orgId: string, options?: any): AxiosPromise<Array<BaseWalletV2Dto>> {
            return InternalV2ControllerApiFp(configuration).getAllWallets(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxWithdrawalAMount(walletId: string, symbol: string, options?: any): AxiosPromise<string> {
            return InternalV2ControllerApiFp(configuration).getMaxWithdrawalAMount(walletId, symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletBalances(walletId: string, options?: any): AxiosPromise<Array<BalanceDto>> {
            return InternalV2ControllerApiFp(configuration).getWalletBalances(walletId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalV2ControllerApi - object-oriented interface
 * @export
 * @class InternalV2ControllerApi
 * @extends {BaseAPI}
 */
export class InternalV2ControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} transferId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public checkTransferConfirmed(transferId: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).checkTransferConfirmed(transferId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public createOrganization(orgName: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).createOrganization(orgName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} blockchain 
     * @param {string} orgId 
     * @param {string} walletName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public createWallet(blockchain: string, orgId: string, walletName: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).createWallet(blockchain, orgId, walletName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {string} symbol 
     * @param {string} to 
     * @param {string} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public createWithdrawal(walletId: string, symbol: string, to: string, amount: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).createWithdrawal(walletId, symbol, to, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public getAllWallets(orgId: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).getAllWallets(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {string} symbol 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public getMaxWithdrawalAMount(walletId: string, symbol: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).getMaxWithdrawalAMount(walletId, symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalV2ControllerApi
     */
    public getWalletBalances(walletId: string, options?: any) {
        return InternalV2ControllerApiFp(this.configuration).getWalletBalances(walletId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnchainBalanceSnapshotControllerApi - axios parameter creator
 * @export
 */
export const OnchainBalanceSnapshotControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {boolean} isNotSyncedOnly 
         * @param {string} [orgId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnchainBalanceSnapshots: async (date: string, isNotSyncedOnly: boolean, orgId?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getOnchainBalanceSnapshots.');
            }
            // verify required parameter 'isNotSyncedOnly' is not null or undefined
            if (isNotSyncedOnly === null || isNotSyncedOnly === undefined) {
                throw new RequiredError('isNotSyncedOnly','Required parameter isNotSyncedOnly was null or undefined when calling getOnchainBalanceSnapshots.');
            }
            const localVarPath = `/api/v2/custody/balances/onchain-snapshots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (isNotSyncedOnly !== undefined) {
                localVarQueryParameter['isNotSyncedOnly'] = isNotSyncedOnly;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {boolean} isNotSyncedOnly 
         * @param {string} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnchainBalanceSnapshotsCsv: async (date: string, isNotSyncedOnly: boolean, orgId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getOnchainBalanceSnapshotsCsv.');
            }
            // verify required parameter 'isNotSyncedOnly' is not null or undefined
            if (isNotSyncedOnly === null || isNotSyncedOnly === undefined) {
                throw new RequiredError('isNotSyncedOnly','Required parameter isNotSyncedOnly was null or undefined when calling getOnchainBalanceSnapshotsCsv.');
            }
            const localVarPath = `/api/v2/custody/balances/onchain-snapshots/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }

            if (isNotSyncedOnly !== undefined) {
                localVarQueryParameter['isNotSyncedOnly'] = isNotSyncedOnly;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnchainBalanceSnapshotControllerApi - functional programming interface
 * @export
 */
export const OnchainBalanceSnapshotControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {boolean} isNotSyncedOnly 
         * @param {string} [orgId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnchainBalanceSnapshots(date: string, isNotSyncedOnly: boolean, orgId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationOnchainBalanceSnapshotDto>> {
            const localVarAxiosArgs = await OnchainBalanceSnapshotControllerApiAxiosParamCreator(configuration).getOnchainBalanceSnapshots(date, isNotSyncedOnly, orgId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {boolean} isNotSyncedOnly 
         * @param {string} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOnchainBalanceSnapshotsCsv(date: string, isNotSyncedOnly: boolean, orgId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OnchainBalanceSnapshotControllerApiAxiosParamCreator(configuration).getOnchainBalanceSnapshotsCsv(date, isNotSyncedOnly, orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OnchainBalanceSnapshotControllerApi - factory interface
 * @export
 */
export const OnchainBalanceSnapshotControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {boolean} isNotSyncedOnly 
         * @param {string} [orgId] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnchainBalanceSnapshots(date: string, isNotSyncedOnly: boolean, orgId?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationOnchainBalanceSnapshotDto> {
            return OnchainBalanceSnapshotControllerApiFp(configuration).getOnchainBalanceSnapshots(date, isNotSyncedOnly, orgId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {boolean} isNotSyncedOnly 
         * @param {string} [orgId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnchainBalanceSnapshotsCsv(date: string, isNotSyncedOnly: boolean, orgId?: string, options?: any): AxiosPromise<void> {
            return OnchainBalanceSnapshotControllerApiFp(configuration).getOnchainBalanceSnapshotsCsv(date, isNotSyncedOnly, orgId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnchainBalanceSnapshotControllerApi - object-oriented interface
 * @export
 * @class OnchainBalanceSnapshotControllerApi
 * @extends {BaseAPI}
 */
export class OnchainBalanceSnapshotControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} date 
     * @param {boolean} isNotSyncedOnly 
     * @param {string} [orgId] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnchainBalanceSnapshotControllerApi
     */
    public getOnchainBalanceSnapshots(date: string, isNotSyncedOnly: boolean, orgId?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OnchainBalanceSnapshotControllerApiFp(this.configuration).getOnchainBalanceSnapshots(date, isNotSyncedOnly, orgId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {boolean} isNotSyncedOnly 
     * @param {string} [orgId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnchainBalanceSnapshotControllerApi
     */
    public getOnchainBalanceSnapshotsCsv(date: string, isNotSyncedOnly: boolean, orgId?: string, options?: any) {
        return OnchainBalanceSnapshotControllerApiFp(this.configuration).getOnchainBalanceSnapshotsCsv(date, isNotSyncedOnly, orgId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnchainControllerApi - axios parameter creator
 * @export
 */
export const OnchainControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnknownCoinInfo: async (blockchain: string, address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling getUnknownCoinInfo.');
            }
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling getUnknownCoinInfo.');
            }
            const localVarPath = `/api/v2/onchain/coin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (blockchain !== undefined) {
                localVarQueryParameter['blockchain'] = blockchain;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnchainControllerApi - functional programming interface
 * @export
 */
export const OnchainControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnknownCoinInfo(blockchain: string, address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenInfoDto>> {
            const localVarAxiosArgs = await OnchainControllerApiAxiosParamCreator(configuration).getUnknownCoinInfo(blockchain, address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OnchainControllerApi - factory interface
 * @export
 */
export const OnchainControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnknownCoinInfo(blockchain: string, address: string, options?: any): AxiosPromise<TokenInfoDto> {
            return OnchainControllerApiFp(configuration).getUnknownCoinInfo(blockchain, address, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnchainControllerApi - object-oriented interface
 * @export
 * @class OnchainControllerApi
 * @extends {BaseAPI}
 */
export class OnchainControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} blockchain 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnchainControllerApi
     */
    public getUnknownCoinInfo(blockchain: string, address: string, options?: any) {
        return OnchainControllerApiFp(this.configuration).getUnknownCoinInfo(blockchain, address, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OperationControllerApi - axios parameter creator
 * @export
 */
export const OperationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateIndexer: async (blockchain: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling activateIndexer.');
            }
            const localVarPath = `/api/v1/operations/indexer/activate/{blockchain}`
                .replace(`{${"blockchain"}}`, encodeURIComponent(String(blockchain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateIndexer: async (blockchain: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling inactivateIndexer.');
            }
            const localVarPath = `/api/v1/operations/indexer/inactivate/{blockchain}`
                .replace(`{${"blockchain"}}`, encodeURIComponent(String(blockchain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {UpsertIndexerRequest} upsertIndexerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertIndexer: async (blockchain: string, upsertIndexerRequest: UpsertIndexerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockchain' is not null or undefined
            if (blockchain === null || blockchain === undefined) {
                throw new RequiredError('blockchain','Required parameter blockchain was null or undefined when calling upsertIndexer.');
            }
            // verify required parameter 'upsertIndexerRequest' is not null or undefined
            if (upsertIndexerRequest === null || upsertIndexerRequest === undefined) {
                throw new RequiredError('upsertIndexerRequest','Required parameter upsertIndexerRequest was null or undefined when calling upsertIndexer.');
            }
            const localVarPath = `/api/v1/operations/indexer/upsert/{blockchain}`
                .replace(`{${"blockchain"}}`, encodeURIComponent(String(blockchain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof upsertIndexerRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(upsertIndexerRequest !== undefined ? upsertIndexerRequest : {})
                : (upsertIndexerRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationControllerApi - functional programming interface
 * @export
 */
export const OperationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateIndexer(blockchain: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OperationControllerApiAxiosParamCreator(configuration).activateIndexer(blockchain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inactivateIndexer(blockchain: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OperationControllerApiAxiosParamCreator(configuration).inactivateIndexer(blockchain, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {UpsertIndexerRequest} upsertIndexerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertIndexer(blockchain: string, upsertIndexerRequest: UpsertIndexerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OperationControllerApiAxiosParamCreator(configuration).upsertIndexer(blockchain, upsertIndexerRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OperationControllerApi - factory interface
 * @export
 */
export const OperationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} blockchain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateIndexer(blockchain: string, options?: any): AxiosPromise<void> {
            return OperationControllerApiFp(configuration).activateIndexer(blockchain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateIndexer(blockchain: string, options?: any): AxiosPromise<void> {
            return OperationControllerApiFp(configuration).inactivateIndexer(blockchain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} blockchain 
         * @param {UpsertIndexerRequest} upsertIndexerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertIndexer(blockchain: string, upsertIndexerRequest: UpsertIndexerRequest, options?: any): AxiosPromise<void> {
            return OperationControllerApiFp(configuration).upsertIndexer(blockchain, upsertIndexerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperationControllerApi - object-oriented interface
 * @export
 * @class OperationControllerApi
 * @extends {BaseAPI}
 */
export class OperationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} blockchain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationControllerApi
     */
    public activateIndexer(blockchain: string, options?: any) {
        return OperationControllerApiFp(this.configuration).activateIndexer(blockchain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} blockchain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationControllerApi
     */
    public inactivateIndexer(blockchain: string, options?: any) {
        return OperationControllerApiFp(this.configuration).inactivateIndexer(blockchain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} blockchain 
     * @param {UpsertIndexerRequest} upsertIndexerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationControllerApi
     */
    public upsertIndexer(blockchain: string, upsertIndexerRequest: UpsertIndexerRequest, options?: any) {
        return OperationControllerApiFp(this.configuration).upsertIndexer(blockchain, upsertIndexerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationControllerApi - axios parameter creator
 * @export
 */
export const OrganizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orgId 
         * @param {AddAccountRequest} addAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountWithoutInvitation: async (orgId: string, addAccountRequest: AddAccountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling addAccountWithoutInvitation.');
            }
            // verify required parameter 'addAccountRequest' is not null or undefined
            if (addAccountRequest === null || addAccountRequest === undefined) {
                throw new RequiredError('addAccountRequest','Required parameter addAccountRequest was null or undefined when calling addAccountWithoutInvitation.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof addAccountRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(addAccountRequest !== undefined ? addAccountRequest : {})
                : (addAccountRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInviteAccount: async (orgId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling cancelInviteAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling cancelInviteAccount.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/{accountId}/cancel-invite`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {ChangeOrganizationAccountRolesRequest} changeOrganizationAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles: async (orgId: string, accountId: string, changeOrganizationAccountRolesRequest: ChangeOrganizationAccountRolesRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling changeAccountRoles.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling changeAccountRoles.');
            }
            // verify required parameter 'changeOrganizationAccountRolesRequest' is not null or undefined
            if (changeOrganizationAccountRolesRequest === null || changeOrganizationAccountRolesRequest === undefined) {
                throw new RequiredError('changeOrganizationAccountRolesRequest','Required parameter changeOrganizationAccountRolesRequest was null or undefined when calling changeAccountRoles.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/{accountId}/roles`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changeOrganizationAccountRolesRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changeOrganizationAccountRolesRequest !== undefined ? changeOrganizationAccountRolesRequest : {})
                : (changeOrganizationAccountRolesRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {ChangeOrganizationNameRequest} changeOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrganizationName: async (orgId: string, changeOrganizationNameRequest: ChangeOrganizationNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling changeOrganizationName.');
            }
            // verify required parameter 'changeOrganizationNameRequest' is not null or undefined
            if (changeOrganizationNameRequest === null || changeOrganizationNameRequest === undefined) {
                throw new RequiredError('changeOrganizationNameRequest','Required parameter changeOrganizationNameRequest was null or undefined when calling changeOrganizationName.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/name`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changeOrganizationNameRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changeOrganizationNameRequest !== undefined ? changeOrganizationNameRequest : {})
                : (changeOrganizationNameRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} coinListingId 
         * @param {DeleteCoinListingRequest} deleteCoinListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListedCoin: async (orgId: string, coinListingId: string, deleteCoinListingRequest: DeleteCoinListingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteListedCoin.');
            }
            // verify required parameter 'coinListingId' is not null or undefined
            if (coinListingId === null || coinListingId === undefined) {
                throw new RequiredError('coinListingId','Required parameter coinListingId was null or undefined when calling deleteListedCoin.');
            }
            // verify required parameter 'deleteCoinListingRequest' is not null or undefined
            if (deleteCoinListingRequest === null || deleteCoinListingRequest === undefined) {
                throw new RequiredError('deleteCoinListingRequest','Required parameter deleteCoinListingRequest was null or undefined when calling deleteListedCoin.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/coin-listings/{coinListingId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"coinListingId"}}`, encodeURIComponent(String(coinListingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof deleteCoinListingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(deleteCoinListingRequest !== undefined ? deleteCoinListingRequest : {})
                : (deleteCoinListingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationAccount: async (orgId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteOrganizationAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling deleteOrganizationAccount.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/{accountId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationAccounts: async (orgId: string, status?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getAllOrganizationAccounts.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationBalancesCsv: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getAllOrganizationBalancesCsv.');
            }
            const localVarPath = `/api/v1/organizations/balances/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoinBalances: async (orgId: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getCoinBalances.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/balances`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} coinListingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListedCoin: async (orgId: string, coinListingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getListedCoin.');
            }
            // verify required parameter 'coinListingId' is not null or undefined
            if (coinListingId === null || coinListingId === undefined) {
                throw new RequiredError('coinListingId','Required parameter coinListingId was null or undefined when calling getListedCoin.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/coin-listings/{coinListingId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"coinListingId"}}`, encodeURIComponent(String(coinListingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListedCoins: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getListedCoins.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/coin-listings`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/organizations/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAccount: async (orgId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrganizationAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getOrganizationAccount.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/{accountId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAuthorities: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrganizationAuthorities.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/authorities`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBalances: async (orgId: string, date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrganizationBalances.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getOrganizationBalances.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/balances/list`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBalancesCsv: async (orgId: string, date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrganizationBalancesCsv.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getOrganizationBalancesCsv.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/balances/csv`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {HandOverRoleRequest} handOverRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handOverRoles: async (orgId: string, accountId: string, handOverRoleRequest: HandOverRoleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling handOverRoles.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling handOverRoles.');
            }
            // verify required parameter 'handOverRoleRequest' is not null or undefined
            if (handOverRoleRequest === null || handOverRoleRequest === undefined) {
                throw new RequiredError('handOverRoleRequest','Required parameter handOverRoleRequest was null or undefined when calling handOverRoles.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/{accountId}/roles/hand-over`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof handOverRoleRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(handOverRoleRequest !== undefined ? handOverRoleRequest : {})
                : (handOverRoleRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialize: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling initialize.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/initialize`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {InviteAccountRequest} inviteAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteAccount: async (orgId: string, inviteAccountRequest: InviteAccountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling inviteAccount.');
            }
            // verify required parameter 'inviteAccountRequest' is not null or undefined
            if (inviteAccountRequest === null || inviteAccountRequest === undefined) {
                throw new RequiredError('inviteAccountRequest','Required parameter inviteAccountRequest was null or undefined when calling inviteAccount.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/invite`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inviteAccountRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inviteAccountRequest !== undefined ? inviteAccountRequest : {})
                : (inviteAccountRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {ListCoinRequest} listCoinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCoin: async (orgId: string, listCoinRequest: ListCoinRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling listCoin.');
            }
            // verify required parameter 'listCoinRequest' is not null or undefined
            if (listCoinRequest === null || listCoinRequest === undefined) {
                throw new RequiredError('listCoinRequest','Required parameter listCoinRequest was null or undefined when calling listCoin.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/coin-listings`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof listCoinRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(listCoinRequest !== undefined ? listCoinRequest : {})
                : (listCoinRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reInviteAccount: async (orgId: string, accountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling reInviteAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling reInviteAccount.');
            }
            const localVarPath = `/api/v1/organizations/{orgId}/accounts/{accountId}/re-invite`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOrganizationName: async (verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOrganizationNameRequest' is not null or undefined
            if (verifyOrganizationNameRequest === null || verifyOrganizationNameRequest === undefined) {
                throw new RequiredError('verifyOrganizationNameRequest','Required parameter verifyOrganizationNameRequest was null or undefined when calling verifyOrganizationName.');
            }
            const localVarPath = `/api/v1/organizations/verify-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyOrganizationNameRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyOrganizationNameRequest !== undefined ? verifyOrganizationNameRequest : {})
                : (verifyOrganizationNameRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationControllerApi - functional programming interface
 * @export
 */
export const OrganizationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orgId 
         * @param {AddAccountRequest} addAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAccountWithoutInvitation(orgId: string, addAccountRequest: AddAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).addAccountWithoutInvitation(orgId, addAccountRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInviteAccount(orgId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).cancelInviteAccount(orgId, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {ChangeOrganizationAccountRolesRequest} changeOrganizationAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccountRoles(orgId: string, accountId: string, changeOrganizationAccountRolesRequest: ChangeOrganizationAccountRolesRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).changeAccountRoles(orgId, accountId, changeOrganizationAccountRolesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {ChangeOrganizationNameRequest} changeOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeOrganizationName(orgId: string, changeOrganizationNameRequest: ChangeOrganizationNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).changeOrganizationName(orgId, changeOrganizationNameRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} coinListingId 
         * @param {DeleteCoinListingRequest} deleteCoinListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteListedCoin(orgId: string, coinListingId: string, deleteCoinListingRequest: DeleteCoinListingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).deleteListedCoin(orgId, coinListingId, deleteCoinListingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationAccount(orgId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).deleteOrganizationAccount(orgId, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrganizationAccounts(orgId: string, status?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationAccountDto>>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getAllOrganizationAccounts(orgId, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrganizationBalancesCsv(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getAllOrganizationBalancesCsv(date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoinBalances(orgId: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationWalletTotalBalance>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getCoinBalances(orgId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} coinListingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListedCoin(orgId: string, coinListingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListedCoinProjection>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getListedCoin(orgId, coinListingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListedCoins(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListedCoinProjection>>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getListedCoins(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getMe(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationAccount(orgId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAccountDto>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getOrganizationAccount(orgId, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationAuthorities(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorityDto>>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getOrganizationAuthorities(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationBalances(orgId: string, date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletTotalBalance>>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getOrganizationBalances(orgId, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationBalancesCsv(orgId: string, date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).getOrganizationBalancesCsv(orgId, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {HandOverRoleRequest} handOverRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handOverRoles(orgId: string, accountId: string, handOverRoleRequest: HandOverRoleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).handOverRoles(orgId, accountId, handOverRoleRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initialize(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).initialize(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {InviteAccountRequest} inviteAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteAccount(orgId: string, inviteAccountRequest: InviteAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).inviteAccount(orgId, inviteAccountRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {ListCoinRequest} listCoinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCoin(orgId: string, listCoinRequest: ListCoinRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinListingDTO>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).listCoin(orgId, listCoinRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reInviteAccount(orgId: string, accountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).reInviteAccount(orgId, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyOrganizationName(verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationControllerApiAxiosParamCreator(configuration).verifyOrganizationName(verifyOrganizationNameRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganizationControllerApi - factory interface
 * @export
 */
export const OrganizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} orgId 
         * @param {AddAccountRequest} addAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountWithoutInvitation(orgId: string, addAccountRequest: AddAccountRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).addAccountWithoutInvitation(orgId, addAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInviteAccount(orgId: string, accountId: string, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).cancelInviteAccount(orgId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {ChangeOrganizationAccountRolesRequest} changeOrganizationAccountRolesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRoles(orgId: string, accountId: string, changeOrganizationAccountRolesRequest: ChangeOrganizationAccountRolesRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).changeAccountRoles(orgId, accountId, changeOrganizationAccountRolesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {ChangeOrganizationNameRequest} changeOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOrganizationName(orgId: string, changeOrganizationNameRequest: ChangeOrganizationNameRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).changeOrganizationName(orgId, changeOrganizationNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} coinListingId 
         * @param {DeleteCoinListingRequest} deleteCoinListingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListedCoin(orgId: string, coinListingId: string, deleteCoinListingRequest: DeleteCoinListingRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).deleteListedCoin(orgId, coinListingId, deleteCoinListingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationAccount(orgId: string, accountId: string, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).deleteOrganizationAccount(orgId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationAccounts(orgId: string, status?: string, options?: any): AxiosPromise<Array<OrganizationAccountDto>> {
            return OrganizationControllerApiFp(configuration).getAllOrganizationAccounts(orgId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationBalancesCsv(date: string, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).getAllOrganizationBalancesCsv(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoinBalances(orgId: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PaginationWalletTotalBalance> {
            return OrganizationControllerApiFp(configuration).getCoinBalances(orgId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} coinListingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListedCoin(orgId: string, coinListingId: string, options?: any): AxiosPromise<ListedCoinProjection> {
            return OrganizationControllerApiFp(configuration).getListedCoin(orgId, coinListingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListedCoins(orgId: string, options?: any): AxiosPromise<Array<ListedCoinProjection>> {
            return OrganizationControllerApiFp(configuration).getListedCoins(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<OrganizationDto> {
            return OrganizationControllerApiFp(configuration).getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAccount(orgId: string, accountId: string, options?: any): AxiosPromise<OrganizationAccountDto> {
            return OrganizationControllerApiFp(configuration).getOrganizationAccount(orgId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAuthorities(orgId: string, options?: any): AxiosPromise<Array<AuthorityDto>> {
            return OrganizationControllerApiFp(configuration).getOrganizationAuthorities(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBalances(orgId: string, date: string, options?: any): AxiosPromise<Array<WalletTotalBalance>> {
            return OrganizationControllerApiFp(configuration).getOrganizationBalances(orgId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBalancesCsv(orgId: string, date: string, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).getOrganizationBalancesCsv(orgId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {HandOverRoleRequest} handOverRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handOverRoles(orgId: string, accountId: string, handOverRoleRequest: HandOverRoleRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).handOverRoles(orgId, accountId, handOverRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialize(orgId: string, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).initialize(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {InviteAccountRequest} inviteAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteAccount(orgId: string, inviteAccountRequest: InviteAccountRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).inviteAccount(orgId, inviteAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {ListCoinRequest} listCoinRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCoin(orgId: string, listCoinRequest: ListCoinRequest, options?: any): AxiosPromise<CoinListingDTO> {
            return OrganizationControllerApiFp(configuration).listCoin(orgId, listCoinRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orgId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reInviteAccount(orgId: string, accountId: string, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).reInviteAccount(orgId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOrganizationName(verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options?: any): AxiosPromise<void> {
            return OrganizationControllerApiFp(configuration).verifyOrganizationName(verifyOrganizationNameRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationControllerApi - object-oriented interface
 * @export
 * @class OrganizationControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} orgId 
     * @param {AddAccountRequest} addAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public addAccountWithoutInvitation(orgId: string, addAccountRequest: AddAccountRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).addAccountWithoutInvitation(orgId, addAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public cancelInviteAccount(orgId: string, accountId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).cancelInviteAccount(orgId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} accountId 
     * @param {ChangeOrganizationAccountRolesRequest} changeOrganizationAccountRolesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public changeAccountRoles(orgId: string, accountId: string, changeOrganizationAccountRolesRequest: ChangeOrganizationAccountRolesRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).changeAccountRoles(orgId, accountId, changeOrganizationAccountRolesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {ChangeOrganizationNameRequest} changeOrganizationNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public changeOrganizationName(orgId: string, changeOrganizationNameRequest: ChangeOrganizationNameRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).changeOrganizationName(orgId, changeOrganizationNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} coinListingId 
     * @param {DeleteCoinListingRequest} deleteCoinListingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public deleteListedCoin(orgId: string, coinListingId: string, deleteCoinListingRequest: DeleteCoinListingRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).deleteListedCoin(orgId, coinListingId, deleteCoinListingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public deleteOrganizationAccount(orgId: string, accountId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).deleteOrganizationAccount(orgId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getAllOrganizationAccounts(orgId: string, status?: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getAllOrganizationAccounts(orgId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getAllOrganizationBalancesCsv(date: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getAllOrganizationBalancesCsv(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getCoinBalances(orgId: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getCoinBalances(orgId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} coinListingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getListedCoin(orgId: string, coinListingId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getListedCoin(orgId, coinListingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getListedCoins(orgId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getListedCoins(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getMe(options?: any) {
        return OrganizationControllerApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getOrganizationAccount(orgId: string, accountId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getOrganizationAccount(orgId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getOrganizationAuthorities(orgId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getOrganizationAuthorities(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getOrganizationBalances(orgId: string, date: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getOrganizationBalances(orgId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public getOrganizationBalancesCsv(orgId: string, date: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).getOrganizationBalancesCsv(orgId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} accountId 
     * @param {HandOverRoleRequest} handOverRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public handOverRoles(orgId: string, accountId: string, handOverRoleRequest: HandOverRoleRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).handOverRoles(orgId, accountId, handOverRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public initialize(orgId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).initialize(orgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {InviteAccountRequest} inviteAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public inviteAccount(orgId: string, inviteAccountRequest: InviteAccountRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).inviteAccount(orgId, inviteAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {ListCoinRequest} listCoinRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public listCoin(orgId: string, listCoinRequest: ListCoinRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).listCoin(orgId, listCoinRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orgId 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public reInviteAccount(orgId: string, accountId: string, options?: any) {
        return OrganizationControllerApiFp(this.configuration).reInviteAccount(orgId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationControllerApi
     */
    public verifyOrganizationName(verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options?: any) {
        return OrganizationControllerApiFp(this.configuration).verifyOrganizationName(verifyOrganizationNameRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationInvitationControllerApi - axios parameter creator
 * @export
 */
export const OrganizationInvitationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inviteId 
         * @param {ProcessOrganizationInviteRequest} processOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitation: async (inviteId: string, processOrganizationInviteRequest: ProcessOrganizationInviteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling acceptInvitation.');
            }
            // verify required parameter 'processOrganizationInviteRequest' is not null or undefined
            if (processOrganizationInviteRequest === null || processOrganizationInviteRequest === undefined) {
                throw new RequiredError('processOrganizationInviteRequest','Required parameter processOrganizationInviteRequest was null or undefined when calling acceptInvitation.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}/process`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processOrganizationInviteRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processOrganizationInviteRequest !== undefined ? processOrganizationInviteRequest : {})
                : (processOrganizationInviteRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvitation: async (inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling approveInvitation.');
            }
            // verify required parameter 'otpOnlyAuthenticateRequest' is not null or undefined
            if (otpOnlyAuthenticateRequest === null || otpOnlyAuthenticateRequest === undefined) {
                throw new RequiredError('otpOnlyAuthenticateRequest','Required parameter otpOnlyAuthenticateRequest was null or undefined when calling approveInvitation.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}/approve`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof otpOnlyAuthenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(otpOnlyAuthenticateRequest !== undefined ? otpOnlyAuthenticateRequest : {})
                : (otpOnlyAuthenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation: async (inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling cancelInvitation.');
            }
            // verify required parameter 'otpOnlyAuthenticateRequest' is not null or undefined
            if (otpOnlyAuthenticateRequest === null || otpOnlyAuthenticateRequest === undefined) {
                throw new RequiredError('otpOnlyAuthenticateRequest','Required parameter otpOnlyAuthenticateRequest was null or undefined when calling cancelInvitation.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}/cancel`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof otpOnlyAuthenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(otpOnlyAuthenticateRequest !== undefined ? otpOnlyAuthenticateRequest : {})
                : (otpOnlyAuthenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrganizationInviteRequest} createOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation: async (createOrganizationInviteRequest: CreateOrganizationInviteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationInviteRequest' is not null or undefined
            if (createOrganizationInviteRequest === null || createOrganizationInviteRequest === undefined) {
                throw new RequiredError('createOrganizationInviteRequest','Required parameter createOrganizationInviteRequest was null or undefined when calling createInvitation.');
            }
            const localVarPath = `/api/v1/organization-invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createOrganizationInviteRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createOrganizationInviteRequest !== undefined ? createOrganizationInviteRequest : {})
                : (createOrganizationInviteRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitationDetail: async (inviteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling getOrganizationInvitationDetail.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrganizationInvitationsRequestBody} getOrganizationInvitationsRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [organizationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitations: async (getOrganizationInvitationsRequestBody: GetOrganizationInvitationsRequestBody, page?: number, size?: number, sort?: Array<string>, organizationName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOrganizationInvitationsRequestBody' is not null or undefined
            if (getOrganizationInvitationsRequestBody === null || getOrganizationInvitationsRequestBody === undefined) {
                throw new RequiredError('getOrganizationInvitationsRequestBody','Required parameter getOrganizationInvitationsRequestBody was null or undefined when calling getOrganizationInvitations.');
            }
            const localVarPath = `/api/v1/organization-invitations/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getOrganizationInvitationsRequestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getOrganizationInvitationsRequestBody !== undefined ? getOrganizationInvitationsRequestBody : {})
                : (getOrganizationInvitationsRequestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInvitataion: async (inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling rejectInvitataion.');
            }
            // verify required parameter 'otpOnlyAuthenticateRequest' is not null or undefined
            if (otpOnlyAuthenticateRequest === null || otpOnlyAuthenticateRequest === undefined) {
                throw new RequiredError('otpOnlyAuthenticateRequest','Required parameter otpOnlyAuthenticateRequest was null or undefined when calling rejectInvitataion.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}/reject`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof otpOnlyAuthenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(otpOnlyAuthenticateRequest !== undefined ? otpOnlyAuthenticateRequest : {})
                : (otpOnlyAuthenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitation: async (inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling resendInvitation.');
            }
            // verify required parameter 'otpOnlyAuthenticateRequest' is not null or undefined
            if (otpOnlyAuthenticateRequest === null || otpOnlyAuthenticateRequest === undefined) {
                throw new RequiredError('otpOnlyAuthenticateRequest','Required parameter otpOnlyAuthenticateRequest was null or undefined when calling resendInvitation.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}/resend-invite-mail`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof otpOnlyAuthenticateRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(otpOnlyAuthenticateRequest !== undefined ? otpOnlyAuthenticateRequest : {})
                : (otpOnlyAuthenticateRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {VerifyOrganizationInviteRequest} verifyOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyInvitation: async (inviteId: string, verifyOrganizationInviteRequest: VerifyOrganizationInviteRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling verifyInvitation.');
            }
            // verify required parameter 'verifyOrganizationInviteRequest' is not null or undefined
            if (verifyOrganizationInviteRequest === null || verifyOrganizationInviteRequest === undefined) {
                throw new RequiredError('verifyOrganizationInviteRequest','Required parameter verifyOrganizationInviteRequest was null or undefined when calling verifyInvitation.');
            }
            const localVarPath = `/api/v1/organization-invitations/{inviteId}/verify`
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyOrganizationInviteRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyOrganizationInviteRequest !== undefined ? verifyOrganizationInviteRequest : {})
                : (verifyOrganizationInviteRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOrganizationName1: async (verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyOrganizationNameRequest' is not null or undefined
            if (verifyOrganizationNameRequest === null || verifyOrganizationNameRequest === undefined) {
                throw new RequiredError('verifyOrganizationNameRequest','Required parameter verifyOrganizationNameRequest was null or undefined when calling verifyOrganizationName1.');
            }
            const localVarPath = `/api/v1/organization-invitations/verify-name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyOrganizationNameRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyOrganizationNameRequest !== undefined ? verifyOrganizationNameRequest : {})
                : (verifyOrganizationNameRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySuperAmdinEmail: async (verifyEmailRequest: VerifyEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailRequest' is not null or undefined
            if (verifyEmailRequest === null || verifyEmailRequest === undefined) {
                throw new RequiredError('verifyEmailRequest','Required parameter verifyEmailRequest was null or undefined when calling verifySuperAmdinEmail.');
            }
            const localVarPath = `/api/v1/organization-invitations/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyEmailRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyEmailRequest !== undefined ? verifyEmailRequest : {})
                : (verifyEmailRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationInvitationControllerApi - functional programming interface
 * @export
 */
export const OrganizationInvitationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inviteId 
         * @param {ProcessOrganizationInviteRequest} processOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptInvitation(inviteId: string, processOrganizationInviteRequest: ProcessOrganizationInviteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).acceptInvitation(inviteId, processOrganizationInviteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).approveInvitation(inviteId, otpOnlyAuthenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).cancelInvitation(inviteId, otpOnlyAuthenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateOrganizationInviteRequest} createOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInvitation(createOrganizationInviteRequest: CreateOrganizationInviteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleOrganizationInvitationDTO>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).createInvitation(createOrganizationInviteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationInvitationDetail(inviteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailOrganizationInviteProjection>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).getOrganizationInvitationDetail(inviteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetOrganizationInvitationsRequestBody} getOrganizationInvitationsRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [organizationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationInvitations(getOrganizationInvitationsRequestBody: GetOrganizationInvitationsRequestBody, page?: number, size?: number, sort?: Array<string>, organizationName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationSimpleOrganizationInvitationProjection>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).getOrganizationInvitations(getOrganizationInvitationsRequestBody, page, size, sort, organizationName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectInvitataion(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).rejectInvitataion(inviteId, otpOnlyAuthenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).resendInvitation(inviteId, otpOnlyAuthenticateRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {VerifyOrganizationInviteRequest} verifyOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyInvitation(inviteId: string, verifyOrganizationInviteRequest: VerifyOrganizationInviteRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicOrganizationInvitationInfoProjection>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).verifyInvitation(inviteId, verifyOrganizationInviteRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyOrganizationName1(verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).verifyOrganizationName1(verifyOrganizationNameRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifySuperAmdinEmail(verifyEmailRequest: VerifyEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationInvitationControllerApiAxiosParamCreator(configuration).verifySuperAmdinEmail(verifyEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganizationInvitationControllerApi - factory interface
 * @export
 */
export const OrganizationInvitationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} inviteId 
         * @param {ProcessOrganizationInviteRequest} processOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitation(inviteId: string, processOrganizationInviteRequest: ProcessOrganizationInviteRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).acceptInvitation(inviteId, processOrganizationInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).approveInvitation(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).cancelInvitation(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrganizationInviteRequest} createOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvitation(createOrganizationInviteRequest: CreateOrganizationInviteRequest, options?: any): AxiosPromise<SimpleOrganizationInvitationDTO> {
            return OrganizationInvitationControllerApiFp(configuration).createInvitation(createOrganizationInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitationDetail(inviteId: string, options?: any): AxiosPromise<DetailOrganizationInviteProjection> {
            return OrganizationInvitationControllerApiFp(configuration).getOrganizationInvitationDetail(inviteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetOrganizationInvitationsRequestBody} getOrganizationInvitationsRequestBody 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [organizationName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationInvitations(getOrganizationInvitationsRequestBody: GetOrganizationInvitationsRequestBody, page?: number, size?: number, sort?: Array<string>, organizationName?: string, options?: any): AxiosPromise<PaginationSimpleOrganizationInvitationProjection> {
            return OrganizationInvitationControllerApiFp(configuration).getOrganizationInvitations(getOrganizationInvitationsRequestBody, page, size, sort, organizationName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInvitataion(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).rejectInvitataion(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).resendInvitation(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inviteId 
         * @param {VerifyOrganizationInviteRequest} verifyOrganizationInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyInvitation(inviteId: string, verifyOrganizationInviteRequest: VerifyOrganizationInviteRequest, options?: any): AxiosPromise<PublicOrganizationInvitationInfoProjection> {
            return OrganizationInvitationControllerApiFp(configuration).verifyInvitation(inviteId, verifyOrganizationInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyOrganizationName1(verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).verifyOrganizationName1(verifyOrganizationNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailRequest} verifyEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifySuperAmdinEmail(verifyEmailRequest: VerifyEmailRequest, options?: any): AxiosPromise<void> {
            return OrganizationInvitationControllerApiFp(configuration).verifySuperAmdinEmail(verifyEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationInvitationControllerApi - object-oriented interface
 * @export
 * @class OrganizationInvitationControllerApi
 * @extends {BaseAPI}
 */
export class OrganizationInvitationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} inviteId 
     * @param {ProcessOrganizationInviteRequest} processOrganizationInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public acceptInvitation(inviteId: string, processOrganizationInviteRequest: ProcessOrganizationInviteRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).acceptInvitation(inviteId, processOrganizationInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteId 
     * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public approveInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).approveInvitation(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteId 
     * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public cancelInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).cancelInvitation(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrganizationInviteRequest} createOrganizationInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public createInvitation(createOrganizationInviteRequest: CreateOrganizationInviteRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).createInvitation(createOrganizationInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public getOrganizationInvitationDetail(inviteId: string, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).getOrganizationInvitationDetail(inviteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetOrganizationInvitationsRequestBody} getOrganizationInvitationsRequestBody 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {string} [organizationName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public getOrganizationInvitations(getOrganizationInvitationsRequestBody: GetOrganizationInvitationsRequestBody, page?: number, size?: number, sort?: Array<string>, organizationName?: string, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).getOrganizationInvitations(getOrganizationInvitationsRequestBody, page, size, sort, organizationName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteId 
     * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public rejectInvitataion(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).rejectInvitataion(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteId 
     * @param {OtpOnlyAuthenticateRequest} otpOnlyAuthenticateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public resendInvitation(inviteId: string, otpOnlyAuthenticateRequest: OtpOnlyAuthenticateRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).resendInvitation(inviteId, otpOnlyAuthenticateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inviteId 
     * @param {VerifyOrganizationInviteRequest} verifyOrganizationInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public verifyInvitation(inviteId: string, verifyOrganizationInviteRequest: VerifyOrganizationInviteRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).verifyInvitation(inviteId, verifyOrganizationInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyOrganizationNameRequest} verifyOrganizationNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public verifyOrganizationName1(verifyOrganizationNameRequest: VerifyOrganizationNameRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).verifyOrganizationName1(verifyOrganizationNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailRequest} verifyEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationInvitationControllerApi
     */
    public verifySuperAmdinEmail(verifyEmailRequest: VerifyEmailRequest, options?: any) {
        return OrganizationInvitationControllerApiFp(this.configuration).verifySuperAmdinEmail(verifyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestControllerApi - axios parameter creator
 * @export
 */
export const TestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/emails/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/emails/first`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/emails/last`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popFirstEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/emails/pop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestControllerApi - functional programming interface
 * @export
 */
export const TestControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmailTestEnvelopDto>>> {
            const localVarAxiosArgs = await TestControllerApiAxiosParamCreator(configuration).getAllEmails(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTestEnvelopDto>> {
            const localVarAxiosArgs = await TestControllerApiAxiosParamCreator(configuration).getFirstEmail(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTestEnvelopDto>> {
            const localVarAxiosArgs = await TestControllerApiAxiosParamCreator(configuration).getLastEmail(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async popFirstEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTestEnvelopDto>> {
            const localVarAxiosArgs = await TestControllerApiAxiosParamCreator(configuration).popFirstEmail(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TestControllerApi - factory interface
 * @export
 */
export const TestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmails(options?: any): AxiosPromise<Array<EmailTestEnvelopDto>> {
            return TestControllerApiFp(configuration).getAllEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstEmail(options?: any): AxiosPromise<EmailTestEnvelopDto> {
            return TestControllerApiFp(configuration).getFirstEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastEmail(options?: any): AxiosPromise<EmailTestEnvelopDto> {
            return TestControllerApiFp(configuration).getLastEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        popFirstEmail(options?: any): AxiosPromise<EmailTestEnvelopDto> {
            return TestControllerApiFp(configuration).popFirstEmail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestControllerApi - object-oriented interface
 * @export
 * @class TestControllerApi
 * @extends {BaseAPI}
 */
export class TestControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getAllEmails(options?: any) {
        return TestControllerApiFp(this.configuration).getAllEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getFirstEmail(options?: any) {
        return TestControllerApiFp(this.configuration).getFirstEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public getLastEmail(options?: any) {
        return TestControllerApiFp(this.configuration).getLastEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public popFirstEmail(options?: any) {
        return TestControllerApiFp(this.configuration).popFirstEmail(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VaspControllerApi - axios parameter creator
 * @export
 */
export const VaspControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {string} vaspEntityId 
         * @param {ApproveTransferRequest} approveTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTransfer: async (base64RemotePublicKey: string, vaspEntityId: string, approveTransferRequest: ApproveTransferRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'base64RemotePublicKey' is not null or undefined
            if (base64RemotePublicKey === null || base64RemotePublicKey === undefined) {
                throw new RequiredError('base64RemotePublicKey','Required parameter base64RemotePublicKey was null or undefined when calling approveTransfer.');
            }
            // verify required parameter 'vaspEntityId' is not null or undefined
            if (vaspEntityId === null || vaspEntityId === undefined) {
                throw new RequiredError('vaspEntityId','Required parameter vaspEntityId was null or undefined when calling approveTransfer.');
            }
            // verify required parameter 'approveTransferRequest' is not null or undefined
            if (approveTransferRequest === null || approveTransferRequest === undefined) {
                throw new RequiredError('approveTransferRequest','Required parameter approveTransferRequest was null or undefined when calling approveTransfer.');
            }
            const localVarPath = `/vasp/api/v1/beneficiary/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (base64RemotePublicKey !== undefined) {
                localVarQueryParameter['base64RemotePublicKey'] = base64RemotePublicKey;
            }

            if (vaspEntityId !== undefined) {
                localVarQueryParameter['vaspEntityId'] = vaspEntityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof approveTransferRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(approveTransferRequest !== undefined ? approveTransferRequest : {})
                : (approveTransferRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CancelTransferRequest} cancelTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransfer: async (cancelTransferRequest: CancelTransferRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelTransferRequest' is not null or undefined
            if (cancelTransferRequest === null || cancelTransferRequest === undefined) {
                throw new RequiredError('cancelTransferRequest','Required parameter cancelTransferRequest was null or undefined when calling cancelTransfer.');
            }
            const localVarPath = `/vasp/api/v1/vasp/transfer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof cancelTransferRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(cancelTransferRequest !== undefined ? cancelTransferRequest : {})
                : (cancelTransferRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTxStatusRequest} getTxStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxStatus: async (getTxStatusRequest: GetTxStatusRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTxStatusRequest' is not null or undefined
            if (getTxStatusRequest === null || getTxStatusRequest === undefined) {
                throw new RequiredError('getTxStatusRequest','Required parameter getTxStatusRequest was null or undefined when calling getTxStatus.');
            }
            const localVarPath = `/vasp/api/v1/vasp/transfer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getTxStatusRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getTxStatusRequest !== undefined ? getTxStatusRequest : {})
                : (getTxStatusRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vasp/api/v1/vasp/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {string} vaspEntityId 
         * @param {RetrieveVaspTransferDataRequest} retrieveVaspTransferDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVaspTransferData: async (base64RemotePublicKey: string, vaspEntityId: string, retrieveVaspTransferDataRequest: RetrieveVaspTransferDataRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'base64RemotePublicKey' is not null or undefined
            if (base64RemotePublicKey === null || base64RemotePublicKey === undefined) {
                throw new RequiredError('base64RemotePublicKey','Required parameter base64RemotePublicKey was null or undefined when calling retrieveVaspTransferData.');
            }
            // verify required parameter 'vaspEntityId' is not null or undefined
            if (vaspEntityId === null || vaspEntityId === undefined) {
                throw new RequiredError('vaspEntityId','Required parameter vaspEntityId was null or undefined when calling retrieveVaspTransferData.');
            }
            // verify required parameter 'retrieveVaspTransferDataRequest' is not null or undefined
            if (retrieveVaspTransferDataRequest === null || retrieveVaspTransferDataRequest === undefined) {
                throw new RequiredError('retrieveVaspTransferDataRequest','Required parameter retrieveVaspTransferDataRequest was null or undefined when calling retrieveVaspTransferData.');
            }
            const localVarPath = `/vasp/api/v1/verification/tx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (base64RemotePublicKey !== undefined) {
                localVarQueryParameter['base64RemotePublicKey'] = base64RemotePublicKey;
            }

            if (vaspEntityId !== undefined) {
                localVarQueryParameter['vaspEntityId'] = vaspEntityId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof retrieveVaspTransferDataRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(retrieveVaspTransferDataRequest !== undefined ? retrieveVaspTransferDataRequest : {})
                : (retrieveVaspTransferDataRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitTransferRequest} submitTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTransfer: async (submitTransferRequest: SubmitTransferRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitTransferRequest' is not null or undefined
            if (submitTransferRequest === null || submitTransferRequest === undefined) {
                throw new RequiredError('submitTransferRequest','Required parameter submitTransferRequest was null or undefined when calling submitTransfer.');
            }
            const localVarPath = `/vasp/api/v1/beneficiary/transfer/txid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof submitTransferRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(submitTransferRequest !== undefined ? submitTransferRequest : {})
                : (submitTransferRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {VerifyAddressRequest} verifyAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAddress: async (base64RemotePublicKey: string, verifyAddressRequest: VerifyAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'base64RemotePublicKey' is not null or undefined
            if (base64RemotePublicKey === null || base64RemotePublicKey === undefined) {
                throw new RequiredError('base64RemotePublicKey','Required parameter base64RemotePublicKey was null or undefined when calling verifyAddress.');
            }
            // verify required parameter 'verifyAddressRequest' is not null or undefined
            if (verifyAddressRequest === null || verifyAddressRequest === undefined) {
                throw new RequiredError('verifyAddressRequest','Required parameter verifyAddressRequest was null or undefined when calling verifyAddress.');
            }
            const localVarPath = `/vasp/api/v1/beneficiary/VerifyAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (base64RemotePublicKey !== undefined) {
                localVarQueryParameter['base64RemotePublicKey'] = base64RemotePublicKey;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyAddressRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyAddressRequest !== undefined ? verifyAddressRequest : {})
                : (verifyAddressRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTxRequest} verifyTxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTx: async (verifyTxRequest: VerifyTxRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTxRequest' is not null or undefined
            if (verifyTxRequest === null || verifyTxRequest === undefined) {
                throw new RequiredError('verifyTxRequest','Required parameter verifyTxRequest was null or undefined when calling verifyTx.');
            }
            const localVarPath = `/vasp/api/v1/verification/tx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifyTxRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifyTxRequest !== undefined ? verifyTxRequest : {})
                : (verifyTxRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VaspControllerApi - functional programming interface
 * @export
 */
export const VaspControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {string} vaspEntityId 
         * @param {ApproveTransferRequest} approveTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveTransfer(base64RemotePublicKey: string, vaspEntityId: string, approveTransferRequest: ApproveTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApproveTransferResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).approveTransfer(base64RemotePublicKey, vaspEntityId, approveTransferRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CancelTransferRequest} cancelTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTransfer(cancelTransferRequest: CancelTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelTransferResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).cancelTransfer(cancelTransferRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GetTxStatusRequest} getTxStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTxStatus(getTxStatusRequest: GetTxStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTxStatusResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).getTxStatus(getTxStatusRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {string} vaspEntityId 
         * @param {RetrieveVaspTransferDataRequest} retrieveVaspTransferDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveVaspTransferData(base64RemotePublicKey: string, vaspEntityId: string, retrieveVaspTransferDataRequest: RetrieveVaspTransferDataRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveVaspTransferDataResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).retrieveVaspTransferData(base64RemotePublicKey, vaspEntityId, retrieveVaspTransferDataRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SubmitTransferRequest} submitTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTransfer(submitTransferRequest: SubmitTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitTransferResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).submitTransfer(submitTransferRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {VerifyAddressRequest} verifyAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyAddress(base64RemotePublicKey: string, verifyAddressRequest: VerifyAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyAddressResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).verifyAddress(base64RemotePublicKey, verifyAddressRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifyTxRequest} verifyTxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyTx(verifyTxRequest: VerifyTxRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyTxResponse>> {
            const localVarAxiosArgs = await VaspControllerApiAxiosParamCreator(configuration).verifyTx(verifyTxRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VaspControllerApi - factory interface
 * @export
 */
export const VaspControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {string} vaspEntityId 
         * @param {ApproveTransferRequest} approveTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveTransfer(base64RemotePublicKey: string, vaspEntityId: string, approveTransferRequest: ApproveTransferRequest, options?: any): AxiosPromise<ApproveTransferResponse> {
            return VaspControllerApiFp(configuration).approveTransfer(base64RemotePublicKey, vaspEntityId, approveTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CancelTransferRequest} cancelTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransfer(cancelTransferRequest: CancelTransferRequest, options?: any): AxiosPromise<CancelTransferResponse> {
            return VaspControllerApiFp(configuration).cancelTransfer(cancelTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTxStatusRequest} getTxStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxStatus(getTxStatusRequest: GetTxStatusRequest, options?: any): AxiosPromise<GetTxStatusResponse> {
            return VaspControllerApiFp(configuration).getTxStatus(getTxStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): AxiosPromise<void> {
            return VaspControllerApiFp(configuration).healthCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {string} vaspEntityId 
         * @param {RetrieveVaspTransferDataRequest} retrieveVaspTransferDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVaspTransferData(base64RemotePublicKey: string, vaspEntityId: string, retrieveVaspTransferDataRequest: RetrieveVaspTransferDataRequest, options?: any): AxiosPromise<RetrieveVaspTransferDataResponse> {
            return VaspControllerApiFp(configuration).retrieveVaspTransferData(base64RemotePublicKey, vaspEntityId, retrieveVaspTransferDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitTransferRequest} submitTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTransfer(submitTransferRequest: SubmitTransferRequest, options?: any): AxiosPromise<SubmitTransferResponse> {
            return VaspControllerApiFp(configuration).submitTransfer(submitTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} base64RemotePublicKey 
         * @param {VerifyAddressRequest} verifyAddressRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAddress(base64RemotePublicKey: string, verifyAddressRequest: VerifyAddressRequest, options?: any): AxiosPromise<VerifyAddressResponse> {
            return VaspControllerApiFp(configuration).verifyAddress(base64RemotePublicKey, verifyAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTxRequest} verifyTxRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTx(verifyTxRequest: VerifyTxRequest, options?: any): AxiosPromise<VerifyTxResponse> {
            return VaspControllerApiFp(configuration).verifyTx(verifyTxRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VaspControllerApi - object-oriented interface
 * @export
 * @class VaspControllerApi
 * @extends {BaseAPI}
 */
export class VaspControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} base64RemotePublicKey 
     * @param {string} vaspEntityId 
     * @param {ApproveTransferRequest} approveTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public approveTransfer(base64RemotePublicKey: string, vaspEntityId: string, approveTransferRequest: ApproveTransferRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).approveTransfer(base64RemotePublicKey, vaspEntityId, approveTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CancelTransferRequest} cancelTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public cancelTransfer(cancelTransferRequest: CancelTransferRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).cancelTransfer(cancelTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetTxStatusRequest} getTxStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public getTxStatus(getTxStatusRequest: GetTxStatusRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).getTxStatus(getTxStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public healthCheck(options?: any) {
        return VaspControllerApiFp(this.configuration).healthCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} base64RemotePublicKey 
     * @param {string} vaspEntityId 
     * @param {RetrieveVaspTransferDataRequest} retrieveVaspTransferDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public retrieveVaspTransferData(base64RemotePublicKey: string, vaspEntityId: string, retrieveVaspTransferDataRequest: RetrieveVaspTransferDataRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).retrieveVaspTransferData(base64RemotePublicKey, vaspEntityId, retrieveVaspTransferDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitTransferRequest} submitTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public submitTransfer(submitTransferRequest: SubmitTransferRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).submitTransfer(submitTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} base64RemotePublicKey 
     * @param {VerifyAddressRequest} verifyAddressRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public verifyAddress(base64RemotePublicKey: string, verifyAddressRequest: VerifyAddressRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).verifyAddress(base64RemotePublicKey, verifyAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTxRequest} verifyTxRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VaspControllerApi
     */
    public verifyTx(verifyTxRequest: VerifyTxRequest, options?: any) {
        return VaspControllerApiFp(this.configuration).verifyTx(verifyTxRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


