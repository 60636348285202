import Btc from "src/images/icon/coin/btc";
import BtcBox from "src/images/icon/coin/btc_box";
import BtcDeprecated from "src/images/icon/coin/btc_deprecated";
import BtcDeprecatedBox from "src/images/icon/coin/btc_deprecated_box";
import Eth from "src/images/icon/coin/eth";
import EthBox from "src/images/icon/coin/eth_box";
import EthDeprecated from "src/images/icon/coin/eth_deprecated";
import EthDeprecatedBox from "src/images/icon/coin/eth_deprecated_box";
import Kaia from "src/images/icon/coin/kaia";
import KaiaBox from "src/images/icon/coin/kaia_box";
import KaiaDeprecated from "src/images/icon/coin/kaia_deprecated";
import KaiaDeprecatedBox from "src/images/icon/coin/kaia_deprecated_box";
import Polygon from "src/images/icon/coin/polygon";
import PolygonBox from "src/images/icon/coin/polygon_box";
import PolygonDeprecated from "src/images/icon/coin/polygon_deprecated";
import PolygonDeprecatedBox from "src/images/icon/coin/polygon_deprecated_box";
import Sol from "src/images/icon/coin/sol";
import SolBox from "src/images/icon/coin/sol_box";
import Over from "src/images/icon/coin/over";
import OverBox from "src/images/icon/coin/over_box";
import Bsc from "src/images/icon/coin/bsc";
import BscBox from "src/images/icon/coin/bsc_box";
import Wemix from "src/images/icon/coin/wemix";
import WemixBox from "src/images/icon/coin/wemix_box";
import Xpla from "src/images/icon/coin/xpla";
import XplaBox from "src/images/icon/coin/xpla_box";
import Bizauto from "src/images/icon/coin/bizauto";
import BizautoBox from "src/images/icon/coin/bizauto_box";
import ArbitrumOne from "src/images/icon/coin/arbitrum_one";
import ArbitrumOneBox from "src/images/icon/coin/arbitrum_one_box";
import FilterListSelected from "src/images/icon/filter_list_selected";
import GasStation from "src/images/icon/gas_station";
import PaginationFirst from "src/images/icon/pagination_first";
import PaginationLast from "src/images/icon/pagination_last";
import User from "src/images/icon/user";
import AndroidCustom from "src/images/icon/android_custom";
import IOS from "src/images/icon/ios";
import SubMenu from "src/images/icon/sub_menu";
import LogoDark from "src/images/logo_dark";
import LogoAdminDark from "src/images/logo_admin_dark";
import LogoDarkTest from "src/images/logo_dark_test";
import WalletLogo from "src/images/wallet_logo_dark";
import WalletLogoTest from "src/images/wallet_logo_dark_test";
import OtpIcon from "src/images/otp";
import KeyfilePreview from "src/images/keyfile_preview";
import CoinAddIcon from "src/images/icon/coin_add";
import SwapVertIcon from "src/images/icon/swap_vert";

const images = {
  icons: {
    btc: Btc,
    btc_box: BtcBox,
    btc_deprecated: BtcDeprecated,
    btc_deprecated_box: BtcDeprecatedBox,
    eth: Eth,
    eth_box: EthBox,
    eth_deprecated: EthDeprecated,
    eth_deprecated_box: EthDeprecatedBox,
    kaia: Kaia,
    kaia_box: KaiaBox,
    kaia_deprecated: KaiaDeprecated,
    kaia_deprecated_box: KaiaDeprecatedBox,
    sol: Sol,
    sol_box: SolBox,
    polygon: Polygon,
    polygon_box: PolygonBox,
    polygon_deprecated: PolygonDeprecated,
    polygon_deprecated_box: PolygonDeprecatedBox,
    over: Over,
    over_box: OverBox,
    bsc: Bsc,
    bsc_box: BscBox,
    wemix: Wemix,
    wemix_box: WemixBox,
    xpla: Xpla,
    xpla_box: XplaBox,
    bizauto: Bizauto,
    bizauto_box: BizautoBox,
    arbitrum_one: ArbitrumOne,
    arbitrum_one_box: ArbitrumOneBox,
    filterListSelected: FilterListSelected,
    gasStation: GasStation,
    paginationFirst: PaginationFirst,
    paginationLast: PaginationLast,
    user: User,
    android_custom: AndroidCustom,
    ios: IOS,
    subMenu: SubMenu,
    coin_add: CoinAddIcon,
    swap_vert: SwapVertIcon,
  },
  walletLogo: WalletLogo,
  walletLogoTest: WalletLogoTest,
  logoDark: LogoDark,
  logoDarkTest: LogoDarkTest,
  logoAdminDark: LogoAdminDark,
  keyfilePreview: KeyfilePreview,
  otpIcon: OtpIcon,
  btcWalletPolicyInfo: require("./btc_wallet_policy_info.png"),
  ethMasterwalletPolicyInfo: require("./eth_masterwallet_policy_info.png"),
  ethUserwalletPolicyInfo: require("./eth_userwallet_policy_info.png"),
  klayMasterwalletPolicyInfo: require("./klay_masterwallet_policy_info.png"),
  klayUserwalletPolicyInfo: require("./klay_userwallet_policy_info.png"),
  icSubmenu: require("./ic_submenu.png"),
  tooltipPolygon: require("./tooltip_polygon.png"),
  balanceReportPrint: require("./balance_report_print.png"),
  balanceReportContentPrint: require("./balance_report_content_print.png"),
};

export default images;
