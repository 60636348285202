import _ from "lodash";
import React from "react";
import juice from "juice";
import { renderToString } from "react-dom/server";

import { Blockchain } from "src/__generate__/api";
import { i18n } from "next-i18next";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "src/themes";
import { css } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ServerStyleSheets, StylesProvider } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const BTC_ADDRESS_LENGTH = 35;
const PREFIX_ADDRESS = "0x";
const ADDRESS_LENGTH = 42;
const BIZAUTO_ADDRESS_LENGTH = 12;
const PREFIX_TRANSACTION_HASH = "0x";
const TRANSACTION_HASH_LENGTH = 66;
const BTC_TRANSACTION_HASH_LENGTH = 64;
const EOS_TRANSACTION_HASH_LENGTH = 64;
export const USER_ADDRESS_MAX_LENGTH = 255;

export const isAddress = (text: string, blockchain: Blockchain) => {
  const byBlockchain: Record<Blockchain, boolean> = {
    [Blockchain.Bitcoin]: text.length === BTC_ADDRESS_LENGTH,
    [Blockchain.BitcoinTestnetV2]: text.length === BTC_ADDRESS_LENGTH,
    [Blockchain.BitcoinV2]: text.length === BTC_ADDRESS_LENGTH,
    [Blockchain.Ethereum]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.EthereumV2]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Klaytn]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.KlaytnV2]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Baobab]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Polygon]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Amoy]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.PolygonV2]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.OverTestnet]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Over]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Bsc]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.WemixTestnet]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.XplaTestnet]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Xpla]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
    [Blockchain.Bizauto]: text.length === BIZAUTO_ADDRESS_LENGTH,
    [Blockchain.ArbitrumOne]:
      text.startsWith(PREFIX_ADDRESS) && text.length === ADDRESS_LENGTH,
  };
  return byBlockchain[blockchain];
};

export const isTransactionHashByBlockchainAndText = (
  text: string,
  blockchain: Blockchain,
) => {
  const byBlockchain: Record<Blockchain, boolean> = {
    [Blockchain.Bitcoin]: text.length === BTC_TRANSACTION_HASH_LENGTH,
    [Blockchain.BitcoinTestnetV2]: text.length === BTC_TRANSACTION_HASH_LENGTH,
    [Blockchain.BitcoinV2]: text.length === BTC_TRANSACTION_HASH_LENGTH,
    [Blockchain.Ethereum]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.EthereumV2]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Klaytn]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.KlaytnV2]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Baobab]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Polygon]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Amoy]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.PolygonV2]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.OverTestnet]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Over]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Bsc]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.WemixTestnet]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.XplaTestnet]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Xpla]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
    [Blockchain.Bizauto]: text.length === EOS_TRANSACTION_HASH_LENGTH,
    [Blockchain.ArbitrumOne]:
      text.startsWith(PREFIX_TRANSACTION_HASH) &&
      text.length === TRANSACTION_HASH_LENGTH,
  };
  return byBlockchain[blockchain];
};

export const remove0xPrefix = (txHash: string) => {
  if (txHash.startsWith("0x")) {
    return txHash.substr(2);
  }
  return txHash;
};

export const traverseObjectKeys = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  o: Record<string, any>,
  predicate: (key: string) => boolean,
) => {
  const keys = Object.keys(o);
  for (const key of keys) {
    let keyPredicateResult;
    if (typeof o[key] === "object" && o[key] !== null) {
      keyPredicateResult = traverseObjectKeys(o[key], predicate);
    } else {
      keyPredicateResult = predicate(key);
    }

    if (keyPredicateResult === false) {
      return false;
    }
  }

  return true;
};

export const traverseObjectSliceStr = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  o: Record<string, any>,
  maxLength: number,
) => {
  const keys = Object.keys(o);
  for (const key of keys) {
    if (typeof o[key] === "object" && o[key] !== null) {
      o[key] = traverseObjectSliceStr(o[key], maxLength);
    } else if (typeof o[key] === "string") {
      o[key] = o[key].substr(0, maxLength);
    }
  }
  return o;
};

export const styleToString = (style?: Record<string, unknown>) => {
  if (_.isEmpty(style) || !style) {
    return "";
  }
  return (
    Object.entries(style)
      .map(
        ([k, v]) =>
          `${k.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)}:${v}`,
      )
      .join(";") + ";"
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const styleToCSS = (style?: Record<string, any>) => css`
  ${styleToString(style)}
`;

export const format = (str: string, data: Record<string, string>) => {
  const output = str.replace(/%[^%]+%/g, function (match: string) {
    const matchKey = `${match}`.slice(1, `${match}`.length - 1);
    if (matchKey in data) {
      return `${data[matchKey]}`;
    } else {
      return "";
    }
  });
  return output;
};

export const capitalize = (value: string) => {
  return `${value.charAt(0).toLocaleUpperCase()}${value
    .slice(1)
    .toLocaleLowerCase()}`;
};

type HtmlTemplateProps = {
  body: string;
  styles: string;
  title: string;
};

export const includesForSearch = (target: string, search: string) => {
  return target.toUpperCase().includes(search.toUpperCase());
};

export const matchingForSearch = (target: string, search: string) => {
  return target.toUpperCase() === search.toUpperCase();
};

const HtmlTemplate = ({ body, styles, title }: HtmlTemplateProps) => `
<!DOCTYPE html>
  <html>
    <head>
      <title>${title}</title>
      ${styles}
    </head>
    <body style="margin:0">
      <div id="app">${body}</div>
    </body>
</html>
`;

export const prefixTestnet = (value: string, isTestnet: boolean) => {
  if (!isTestnet) return value;
  const isKo = i18n?.language === "ko";
  return `${isKo ? "테스트넷" : "Testnet"} ${value}`;
};

export const suffixDeprecated = (blockchain: string) => {
  const value = _.capitalize(blockchain.toString());
  return `${value}_deprecated`;
};

export const handleBlockchainName = (blockchain: Blockchain) => {
  switch (blockchain) {
    case Blockchain.ArbitrumOne:
      return "arbitrum one";
    case Blockchain.Baobab:
      return "kairos";
    case Blockchain.Klaytn:
      return "kaia";
    case Blockchain.KlaytnV2:
      return "kaia";
    default:
      return blockchain.toString();
  }
};

export const handleBlockchainSuffixAndName = (
  blockchain: Blockchain,
  isV2Chain: boolean,
) => {
  const value = handleBlockchainName(blockchain);
  if (isV2Chain) {
    return _.startCase(_.camelCase(value.replace("_V2", "")));
  }
  return suffixDeprecated(value);
};

export const emailify =
  <T extends Record<string, unknown>>(Component: React.ComponentType<T>) =>
  (props: T, title: string) => {
    const sheet = new ServerStyleSheets();

    const body = renderToString(
      sheet.collect(
        <React.Fragment>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Component {...props} />
              </ThemeProvider>
            </StylesProvider>
          </LocalizationProvider>
        </React.Fragment>,
      ),
    );
    const styles = sheet.toString();
    return juice(HtmlTemplate({ body, styles, title }));
  };
