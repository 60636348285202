// Utility functions for color conversion
// (e.g. "03F") to full form (e.g. "0033FF")
const hexShorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const hexFullRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

function expandHexShorthand(hex: string): string {
  return hex.replace(hexShorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
}

function hex2Rgb(hex: string) {
  hex = expandHexShorthand(hex);
  const result = hexFullRegex.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgb2Hex(rgb: { r: number; g: number; b: number }) {
  return (
    "#" +
    [rgb.r, rgb.g, rgb.b]
      .map((value) => value.toString(16).padStart(2, "0"))
      .join("")
  );
}

// Conversion between RGB and Chroma (L*a*b*) color spaces
function rgb2Chroma(rgb: { r: number; g: number; b: number }) {
  const normalize = (v: number) =>
    v > 0.04045 ? Math.pow((v + 0.055) / 1.055, 2.4) : v / 12.92;
  const r = normalize(rgb.r / 255);
  const g = normalize(rgb.g / 255);
  const b = normalize(rgb.b / 255);

  let x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  let y = r * 0.2126 + g * 0.7152 + b * 0.0722;
  let z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

  return {
    L: 116 * y - 16,
    a: 500 * (x - y),
    b: 200 * (y - z),
  };
}

function chroma2Rgb(chroma: { L: number; a: number; b: number }) {
  const y = (chroma.L + 16) / 116;
  const x = chroma.a / 500 + y;
  const z = y - chroma.b / 200;

  const toLinearRgb = (v: number) =>
    v > 0.008856 ? Math.pow(v, 3) : (v - 16 / 116) / 7.787;

  const xr = 0.95047 * toLinearRgb(x);
  const yr = toLinearRgb(y);
  const zr = 1.08883 * toLinearRgb(z);

  const toRgb = (value: number) =>
    value > 0.0031308
      ? 1.055 * Math.pow(value, 1 / 2.4) - 0.055
      : 12.92 * value;

  return {
    r: Math.round(
      Math.max(
        0,
        Math.min(1, toRgb(xr * 3.2406 + yr * -1.5372 + zr * -0.4986)),
      ) * 255,
    ),
    g: Math.round(
      Math.max(
        0,
        Math.min(1, toRgb(xr * -0.9689 + yr * 1.8758 + zr * 0.0415)),
      ) * 255,
    ),
    b: Math.round(
      Math.max(0, Math.min(1, toRgb(xr * 0.0557 + yr * -0.204 + zr * 1.057))) *
        255,
    ),
  };
}

// Chroma class for managing color transformations
export default class Chroma {
  private L: number;
  private a: number;
  private b: number;

  constructor(hex: string) {
    const chroma = hex2Chroma(hex);
    this.L = chroma?.L ?? 0;
    this.a = chroma?.a ?? 0;
    this.b = chroma?.b ?? 0;
  }

  public darken(value: number = 1) {
    this.L = Math.max(0, this.L - 18 * value);
    return this;
  }

  public toHex() {
    return rgb2Hex(
      chroma2Rgb({
        L: this.L,
        a: this.a,
        b: this.b,
      }),
    );
  }
}

// Convert hex to Chroma
function hex2Chroma(hex: string) {
  const rgb = hex2Rgb(hex);
  return rgb ? rgb2Chroma(rgb) : null;
}
