import { Provider } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import {
  WithStyles,
  withStyles,
  createStyles,
  StylesProvider,
} from "@mui/styles";
import Router, { withRouter } from "next/router";
import { compose } from "recompose";
import { SnackbarProvider } from "notistack";
import nextCookie from "next-cookies";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { appWithTranslation } from "next-i18next";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import * as Sentry from "@sentry/node";
import nextI18NextConfig from "next-i18next.config.js";
import { getRootStore, IStore } from "src/stores/Store";
import theme from "src/themes";
import {
  env,
  isKodaAdmin,
  isNodeProduction,
  isProduction,
  isProductionAndKodaAdmin,
} from "src/libs/env";
import "src/themes/style.css";
import "src/themes/font.css";
import { gTagPageView } from "src/libs/analytics";
import withErrorSnackbar from "src/hocs/withErrorSnackbar";
import { setupReactotron } from "src/ReactotronConfig";
import { isBrowser } from "src/utils/browser";
import withMessageSnackbar from "src/hocs/withMessageSnackbar";
import { palette } from "src/themes/palette";
import { getAccountExpiredPasswordPath } from "./account/expired-password";
import { ErrorCode } from "src/libs/error";
import {
  isAdminDashboardPath,
  isCustomerDashboardPath,
  isDashboardPath,
} from "src/utils/navigator";
import { getXForwardedForIps } from "src/utils/request";
import { getAccountLoginPath } from "src/utils/common/pathHandlers";
import useStore from "src/hooks/useStore";

type Props = WithStyles<typeof styles> & {
  initialState: IStore;
  isServer: boolean;
  pageProps: any;
} & AppProps;

const styles = createStyles({
  root: {
    padding: "0px",
    margin: "0px",
  },
});

if (!isProductionAndKodaAdmin) {
  Sentry.init({
    enabled: isNodeProduction,
    environment: env.REACT_ENV,
    dsn: env.SENTRY_DSN,
    release: `koda-dashboard@${env.VERSION}`,
    // ...(isBrowser
    //   ? {
    //       integrations: [new ApmIntegrations.Tracing()],
    //       tracesSampleRate: 1.0, // Be sure to lower this in production
    //     }
    //   : {}),
  });
}

const MyApp = (props: Props) => {
  const { classes, pageProps } = props;
  const metaTitle = isKodaAdmin ? "KODA ADMIN" : "KODA";
  const metaDescription = `Welcome to ${metaTitle}! (${env.VERSION})`;
  const store: IStore = useStore(props.isServer, props.initialState);

  const TargetComponent = compose(
    withRouter,
    withErrorSnackbar,
    withMessageSnackbar,
  )(props.Component as any);

  const routeChangeComplete = (url: string) => {
    gTagPageView(url);
  };

  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    jssStyles?.parentNode?.removeChild?.(jssStyles);
    setupReactotron(store);
    Router.events.on("routeChangeComplete", routeChangeComplete);

    return () => {
      Router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Head>
        <title>{isKodaAdmin ? "KODA ADMIN" : "KODA"}</title>
        <meta name="theme-color" content={palette.primary.main} />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, user-scalable=no"
        />
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:site_name" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="/static/koda_dashboard_og.png" />
        <meta name="keywords" content="blockchain,koda" />
      </Head>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StylesProvider injectFirst>
          <Provider store={store}>
            <SnackbarProvider
              classes={classes}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              autoHideDuration={3000}
              content={(key, message) => <div key={key}>{message}</div>}
              maxSnack={3}
            >
              <ThemeProvider theme={theme}>
                <TargetComponent {...pageProps} />
              </ThemeProvider>
            </SnackbarProvider>
          </Provider>
        </StylesProvider>
      </LocalizationProvider>
    </React.Fragment>
  );
};

MyApp.getInitialProps = async ({ Component, router, ctx, locale }: any) => {
  const isServer = !isBrowser;
  const { accessToken = "", secret = "" } = nextCookie(ctx);
  const store = getRootStore(isServer);
  store.authStore.setServerTokenAndIp({
    serverAccessToken: accessToken,
    serverSecret: secret,
    ips: getXForwardedForIps(ctx.req),
  });

  const redirectUrl = (url: string) => {
    if (!isServer) {
      Router.push(url);
      return;
    }
    ctx?.res?.writeHead?.(302, {
      Location: url,
    });
    ctx?.res?.end?.();
  };

  if (
    (!isKodaAdmin && isAdminDashboardPath(router.asPath)) ||
    (isKodaAdmin && isCustomerDashboardPath(router.asPath))
  ) {
    redirectUrl("/");
  }

  const redirectAccountLoginOnError = (error: any) => {
    redirectUrl(getAccountLoginPath({ err: error.status }));
  };
  const redirectAccountExpiredPasswordOnError = () => {
    redirectUrl(getAccountExpiredPasswordPath());
  };

  if (isServer && (isDashboardPath(router.asPath) || router.pathname === "/")) {
    try {
      await store.initialize();
    } catch (error: any) {
      console.log(error);
      if (error.status === ErrorCode.EXPIRED_PASSWORD) {
        redirectAccountExpiredPasswordOnError();
        return { pageProps: {} };
      }
      redirectAccountLoginOnError(error);
      return { pageProps: {} };
    }
  }
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({
      ...ctx,
      store,
    });
  }
  return {
    initialState: getSnapshot(store),
    isServer,
    pageProps,
  };
};

export default withStyles(styles)(appWithTranslation(MyApp, nextI18NextConfig));
