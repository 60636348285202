import {
  Country,
  CustodyNonSigningRequestStatus,
  CustodyRequestControllerApiFactory,
  Detail,
  DetailedOrganizationManagementCustodyRequestDto,
  GetOrganizationManagementRequestsSortRequestParams,
  PaginationOrganizationManagementCustodyRequestDto,
} from "src/__generate__";
import { requestAPI } from "src/apis/requestApi";
import { Pageable } from "src/apis/interface";

const ALL_CUSTODY_UPGRADE_INFO_STATUSES = [
  CustodyNonSigningRequestStatus.PendingApproval,
  CustodyNonSigningRequestStatus.PendingFinalApproval,
];
const clientRequestControllerApiFactory = () =>
  CustodyRequestControllerApiFactory(undefined, "", requestAPI());
const getOrganizationUpgradeInfoCountByStatuses = async (
  statuses: CustodyNonSigningRequestStatus[],
): Promise<number> => {
  const response =
    await clientRequestControllerApiFactory().getOrganizationManagementRequests(
      {},
      undefined,
      statuses,
      undefined,
      undefined,
      0,
      999,
    );
  return response.data.pagination.totalCount;
};
export const getOrganizationUpgradeInfoByStatuses = async (
  statuses: CustodyNonSigningRequestStatus[],
) => {
  const response =
    await clientRequestControllerApiFactory().getOrganizationManagementRequests(
      {},
      undefined,
      statuses,
      undefined,
      undefined,
      0,
      999,
    );
  return response.data;
};

export const getUpgradeInfoProcessCounts = async () => {
  const [allCount, pendingApprovalCount, pendingFinalApprovalCount] =
    await Promise.all([
      getOrganizationUpgradeInfoCountByStatuses(
        ALL_CUSTODY_UPGRADE_INFO_STATUSES,
      ),
      getOrganizationUpgradeInfoCountByStatuses([
        CustodyNonSigningRequestStatus.PendingApproval,
      ]),
      getOrganizationUpgradeInfoCountByStatuses([
        CustodyNonSigningRequestStatus.PendingFinalApproval,
      ]),
    ]);

  return {
    allCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  };
};

export const getCustodyUpgradeInfo = async (params: {
  request: {
    status?: CustodyNonSigningRequestStatus;
    statuses?: CustodyNonSigningRequestStatus[];
    details?: Detail[];
    orgId?: string;
    sorts: Array<GetOrganizationManagementRequestsSortRequestParams>;
  };
  pagination: Pageable;
}): Promise<PaginationOrganizationManagementCustodyRequestDto> => {
  const { request, pagination } = params;
  const { status, statuses, details, orgId, sorts } = request;
  const { page, size } = pagination;
  const response =
    await clientRequestControllerApiFactory().getOrganizationManagementRequests(
      {
        sorts,
      },
      status,
      statuses,
      orgId,
      details,
      page,
      size,
    );
  return response.data;
};
export const getCustodyUpgradeInfoById = async (
  requestId: string,
): Promise<DetailedOrganizationManagementCustodyRequestDto> => {
  const response =
    await clientRequestControllerApiFactory().getDetailedOrganizationManagementRequest(
      requestId,
    );
  return response.data;
};

export const approveOrganizationManagementRequest = async (params: {
  requestId: string;
  otpCode: string;
  passphrase: string;
}): Promise<void> => {
  const { requestId, otpCode, passphrase } = params;
  const response =
    await clientRequestControllerApiFactory().approveOrganizationManagementRequest(
      requestId,
      { otpCode, passphrase },
    );
  return response.data;
};
export const finalApproveOrganizationManagementRequest = async (params: {
  requestId: string;
  otpCode: string;
  passphrase: string;
}): Promise<void> => {
  const { requestId, otpCode, passphrase } = params;
  const response =
    await clientRequestControllerApiFactory().finalApproveOrganizationManagementRequest(
      requestId,
      { otpCode, passphrase },
    );
  return response.data;
};

export const getCountries = async (): Promise<Country[]> => {
  const response = await clientRequestControllerApiFactory().getCountries();
  return response.data;
};
