export const isDashboardPath = (asPath: string) => {
  if (asPath.startsWith("/dashboard")) {
    return true;
  }
  return false;
};

export const isAdminDashboardPath = (asPath: string) =>
  asPath.startsWith("/dashboard/custody");

export const isCustomerDashboardPath = (asPath: string) => {
  return [
    "/dashboard/balance-report",
    "/dashboard/request/inbox",
    "/dashboard/request/outbox",
    "/dashboard/request/proceeding",
    "/dashboard/coins",
    "/dashboard/wallet",
  ].some((item) => asPath.startsWith(item));
};
