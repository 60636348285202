import { encryption } from "src/utils/rsa";
import { env } from "src/libs/env";

const encrypt = async (message: string): Promise<string> => {
  if (["local", "development"].includes(env.REACT_ENV)) {
    return message;
  }
  const publicKey = env.RSA_PUBLIC_KEY;
  return await encryption(publicKey, message);
};

const isObject = (value: any): boolean =>
  value && typeof value === "object" && !Array.isArray(value);

export const encryptSensitiveFields = async (
  data: any,
  sensitiveFields: string[] = [
    "passphrase",
    "newPassphrase",
    "accountPassphrase",
  ],
): Promise<any> => {
  if (data instanceof FormData) {
    return data;
  }
  if (typeof data === "string") {
    try {
      data = JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  if (!isObject(data)) {
    return data;
  }

  const encryptedData = { ...data };

  for (const field of sensitiveFields) {
    if (encryptedData[field]) {
      encryptedData[field] = await encrypt(encryptedData[field]);
    }
  }

  for (const key in encryptedData) {
    if (isObject(encryptedData[key])) {
      encryptedData[key] = await encryptSensitiveFields(
        encryptedData[key],
        sensitiveFields,
      );
    }
  }

  return encryptedData;
};
