export async function encryption(publicKey: string, message: string) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    const pemContents = publicKey.replace(pemHeader, "").replace(pemFooter, "").replace(/\n/g, "");
    const binaryDer = Uint8Array.from(atob(pemContents), (char) => char.charCodeAt(0));

    const cryptoKey = await window.crypto.subtle.importKey(
        "spki",
        binaryDer.buffer,
        {
            name: "RSA-OAEP",
            hash: { name: "SHA-256" },
        },
        true,
        ["encrypt"]
    );

    const encodedMessage = new TextEncoder().encode(message);
    const encryptedData = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP",
        },
        cryptoKey,
        encodedMessage
    );

    return btoa(String.fromCharCode(...Array.from(new Uint8Array(encryptedData))));
}