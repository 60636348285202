import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill="#1B4ADD"
      />
      <g clipPath="url(#clip0_7003_1310)">
        <path
          d="M11.9039 3.24152C11.9529 3.24152 11.9981 3.2539 12.0433 3.27817L19.3777 7.54379C19.4639 7.5928 19.5172 7.68322 19.5129 7.78125L19.4843 16.2673C19.4843 16.3658 19.431 16.4557 19.3449 16.5047L11.9857 20.7209C11.9448 20.7456 11.8958 20.7575 11.8463 20.7575C11.7968 20.7575 11.7521 20.7452 11.7068 20.7209L4.37286 16.4557C4.28673 16.4067 4.23343 16.3163 4.23772 16.2183L4.26627 7.73223C4.26627 7.63373 4.31957 7.54379 4.4057 7.49478L11.7687 3.27436C11.8096 3.2539 11.8586 3.24152 11.9039 3.24152ZM11.9081 2C11.6459 2 11.3837 2.06567 11.1501 2.20081L3.79088 6.41695C3.31978 6.68772 3.02855 7.18737 3.02855 7.72843L3 16.2145C3 16.7555 3.28694 17.2552 3.75377 17.5298L11.0882 21.7954C11.3219 21.9305 11.5841 22.0005 11.8463 22.0005C12.1085 22.0005 12.3707 21.9348 12.6043 21.7997L19.9635 17.5835C20.4346 17.3132 20.7259 16.8131 20.7259 16.2725L20.7544 7.78648C20.7544 7.24543 20.4675 6.74577 20.0006 6.4712L12.6662 2.2051C12.4325 2.06995 12.1703 2 11.9081 2Z"
          fill="white"
        />
        <path
          d="M13.3953 6.62585H12.3218C12.2399 6.62585 12.17 6.67487 12.1414 6.75291L8.68334 16.2302C8.65859 16.2916 8.70808 16.3572 8.77327 16.3572H9.84682C9.92867 16.3572 9.99862 16.3082 10.0272 16.2302L13.4853 6.75291C13.51 6.69152 13.4605 6.62585 13.3953 6.62585ZM11.5147 6.62585H10.4412C10.3593 6.62585 10.2894 6.67487 10.2608 6.75291L6.80654 16.2264C6.78179 16.2878 6.83128 16.3534 6.89647 16.3534H7.97002C8.05187 16.3534 8.12182 16.3044 8.15037 16.2264L11.6047 6.75291C11.6294 6.69152 11.5842 6.62585 11.5147 6.62585ZM12.908 10.2971C12.8795 10.211 12.7562 10.211 12.7277 10.2971L12.1705 11.8294C12.1543 11.8703 12.1543 11.9193 12.1705 11.9607L13.7275 16.2264C13.756 16.3001 13.826 16.3534 13.9078 16.3534H14.9814C15.047 16.3534 15.0961 16.2878 15.0713 16.2264L12.908 10.2971ZM16.9481 16.2264L13.8464 7.71986C13.8179 7.63373 13.6946 7.63373 13.6661 7.71986L13.1089 9.25214C13.0927 9.29306 13.0927 9.34207 13.1089 9.38347L15.6043 16.2264C15.6328 16.3001 15.7028 16.3534 15.7846 16.3534H16.8582C16.9276 16.3577 16.9728 16.2878 16.9481 16.2264Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7003_1310">
          <rect
            width="17.7544"
            height="20"
            fill="white"
            transform="translate(3 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
